<template lang="pug">
ul.trainer-attendee-menu
  ul.colors
    li.colorbox(
      v-for="(color, idx) in colorSource",
      :style="`background-color: ${color}`",
      :class="selectedColorClass(color)",
      :key="idx",
      @click="setAnnotateColor(color)",
      :data-name="'setColor-' + color"
    )
  li(@click="onAnnotateClick", data-name="startAnnotation") {{ annotateText }}
  li(v-if="isAnnotating", @click="resetDraw", data-name="resetDrawing") Clear
</template>

<script lang="ts">
import { ClaimAnnotatorStatus, WaiveAnnotatorStatus, SendColor } from "@/types/Annotation";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter";

@Component({
  computed: {
    ...mapState("AnnotationsModule", ["color"]),
    ...mapGetters("AnnotationsModule", ["isAnnotating"]),
  },
  methods: {
    ...mapActions("AnnotationsModule", ["claimAnnotatorStatus", "waiveAnnotatorStatus", "sendColor"]),
  },
})
export default class Annotate extends Vue {
  private waiveAnnotatorStatus!: WaiveAnnotatorStatus;
  private claimAnnotatorStatus!: ClaimAnnotatorStatus;
  private isAnnotating!: boolean;
  private sendColor!: SendColor;
  private color!: string;

  get colorSource(): string[] {
    return ["#000000", `#ffffff`, `#dce11f`, `#d000a8`, `#117da6`];
  }
  get selectedColorClass(): (selectedColor: string) => string {
    return (selectedColor: string) => {
      return this.color === selectedColor ? `selected-colorbox` : ``;
    };
  }

  get annotateText(): string {
    return `${this.isAnnotating ? "Stop" : "Start"} Annotating`;
  }

  onAnnotateClick(): void {
    if (!this.isAnnotating) {
      this.claimAnnotatorStatus();
      GlobalEventEmitter.$emit("initDraw");
    } else {
      this.waiveAnnotatorStatus();
      GlobalEventEmitter.$emit("waiveAnnotator");
      this.$emit("hiding");
    }
  }
  setAnnotateColor(color: string): void {
    this.sendColor(color);
  }
  resetDraw(): void {
    GlobalEventEmitter.$emit("annotationClear");
  }
}
</script>

<style scoped>
.colors {
  align-content: space-around;
  background-color: var(--gray-80);
}
.colorbox {
  height: 50px;
  width: 50px;
  scale: 75%;
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
}
.colorbox:hover {
  border: 3px solid white;
}
.selected-colorbox {
  border: 1.5px solid black;
  outline: 1.5px solid white;
}
img {
  width: auto;
  max-height: 100%;
}
</style>
