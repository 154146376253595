<template lang="pug">
div
  .bar.completed(:style="`width:${completedScaled}%`")
  .bar.waiting(:style="`width:${remainingScaled}%`")
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  @Prop({ required: true, default: "100" }) readonly width!: number;
  @Prop({ required: true, default: 0 }) readonly completed!: number;

  get completedScaled(): number {
    return (this.width * this.completed) / 100;
  }

  get remainingScaled(): number {
    return this.width - this.completedScaled;
  }
}
</script>

<style lang="scss" scoped>
.bar {
  display: inline-block;
  height: 20px;
}
.completed {
  width: var(--width);
  background-color: var(--info-50);
}
.waiting {
  width: var(--width);
  background-color: var(--gray-30);
}
</style>
