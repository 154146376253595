<template lang="pug">
.confirmer-container(@mousedown="onCancel")
  .confirmer-actions(:style="{ top: `${y - 35}px`, left: `${x - 85}px` }")
    .cancel-btn
      span {{ cancelLabel }}
    .confirm-button(@mousedown="onConfirm")
      span {{ confirmLabel }}
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class Confirmer extends Vue {
  @Prop({ required: true }) private readonly elementId!: string;
  @Prop({ required: true }) private readonly cancelLabel!: string;
  @Prop({ required: true }) private readonly confirmLabel!: string;
  private y = 0;
  private x = 0;
  mounted(): void {
    const el = document.querySelector(`#${this.elementId}`);
    if (el) {
      const { x, y } = el.getBoundingClientRect();
      this.y = y;
      this.x = x;
    }
  }
  onCancel(): void {
    this.$emit("cancel");
  }
  onConfirm(): void {
    this.$emit("confirm");
  }
}
</script>
<style scoped lang="scss">
.confirmer-container {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 20;
  left: 0;
  top: 0;
  overflow: hidden;

  .confirmer-actions {
    z-index: 30;
    position: relative;

    display: inline-flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    border-radius: 9px;
    border: 1px solid #fff;
    background: var(--gray-06);

    height: auto;
    width: auto;

    div {
      border-radius: 5px;
      display: flex;
      padding: 8px 26px 10px 26px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      height: auto;
      width: auto;
      cursor: pointer;

      span {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 750;
        line-height: 130%;
        text-transform: capitalize;
      }
    }

    .cancel-btn {
      &:hover {
        background-color: var(--gray-10);
      }

      background-color: var(--gray-06);
      border: black solid 1px;

      span {
        color: black;
      }
    }

    .confirm-button {
      &:hover {
        background-color: var(--info-80);
      }

      background-color: var(--info-50);
    }
  }
}
</style>
