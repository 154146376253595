import VonageUtil from "@/components/Vonage/vonageUtil";
import { RootState } from "@/types";
import { ReportPayload, StatsReport, UserRTCStats, UserReportPayload, VonageState } from "@/types/vonage";
import { Module } from "vuex";
import { genericErrors } from "@/components/Vonage/ErrorHandler";
import log from "@/components/Vonage/DebugLogger";
import CommonUtil from "@/utils/CommonUtil";
import saveAs from "file-saver";
import { removeReportFluff } from "@/utils/vonageReportHelper";
import api from "@/services/api";
const VonageModule: Module<VonageState, RootState> = {
  namespaced: true,
  state(): VonageState {
    return {
      publisher: null,
      subscribers: [],
      session: null,
    };
  },
  mutations: {
    setVonageState(state: VonageState, changes: Partial<VonageState>): void {
      Object.assign(state, changes);
    },
    removeSubscriberIdx(state: VonageState, idx: number) {
      if (idx != -1) {
        state.session?.unsubscribe(state.subscribers[idx]);
        state.subscribers.splice(idx, 1);
      }
    },
    pushSubscriber(state: VonageState, subscriber: OT.Subscriber) {
      state.subscribers.push(subscriber);
    },
  },
  getters: {},
  actions: {
    setPublisher({ commit }, publisher: OT.Publisher | null): void {
      commit("setVonageState", { publisher });
    },
    setSession({ commit }, session: OT.Session | null): void {
      commit("setVonageState", { session });
    },
    clearSubscribers({ commit }): void {
      commit("setVonageState", { subscribers: [] });
    },
    addSubscriber({ commit, dispatch }, subscriber: OT.Subscriber): void {
      dispatch("removeSubscriber", subscriber.stream);
      commit("pushSubscriber", subscriber);
    },
    removeSubscriber({ state, commit }, stream: OT.Stream): void {
      if (stream) {
        const securityId = VonageUtil.getSecurityId(stream);
        if (securityId) {
          const idx = state.subscribers.findIndex((e) => securityId === VonageUtil.getSecurityId(e.stream));
          commit("removeSubscriberIdx", idx);
        }
      }
    },
    muteSelf({ state }): void {
      state.publisher?.publishAudio(false);
    },
    shutterSelf({ state }): void {
      state.publisher?.publishVideo(false);
    },
    applyUserVideoFilter({ state }, publisher: OT.Publisher | null): void {
      if (OT.hasMediaProcessorSupport()) {
        if (publisher) {
          publisher.applyVideoFilter({ type: "backgroundBlur", blurStrength: "high" }).catch(genericErrors);
        } else {
          state.publisher?.applyVideoFilter({ type: "backgroundBlur", blurStrength: "high" }).catch(genericErrors);
        }
      } else {
        log(
          `User with securityId ${VonageUtil.getSecurityId(
            state.publisher?.stream
          )} has a browser that is out of date and does not support the video filter.`,
          state.publisher?.stream
        );
      }
    },
    refreshPublisherSettings({ state, rootState, dispatch }): void {
      state.publisher?.setAudioSource(rootState.selectedAudioId).catch(genericErrors);
      state.publisher?.setVideoSource(rootState.selectedVideoId).catch(genericErrors);
      const isBlurred = rootState.MediaModule?.isBlurred || false;
      if (!isBlurred) {
        state.publisher?.clearVideoFilter().catch(genericErrors);
      } else {
        dispatch("applyUserVideoFilter");
      }
    },
    async getRtcStats({ state }): Promise<StatsReport> {
      const subscribers: UserRTCStats[] = [];
      const base = JSON.parse(state.publisher?.stream?.connection?.data || "");
      const publisher: UserRTCStats = {
        firstName: base.firstName,
        lastName: base.lastname,
        securityId: base.securityId,
        streamId: state.publisher?.stream?.streamId || "",
        reports: [],
      };

      await state.publisher?.getRtcStatsReport().then((reportArr) => {
        reportArr.forEach((reportContainer) => {
          reportContainer.rtcStatsReport?.forEach((report) => {
            publisher.reports.push(report);
          });
        });
      });
      const subPromises = state.subscribers?.map(async (subscriber) => {
        const base = JSON.parse(subscriber.stream?.connection?.data || "");
        return {
          statsReport: await subscriber?.getRtcStatsReport(),
          firstName: base.firstName,
          lastName: base.lastname,
          securityId: base.securityId,
          streamId: subscriber.stream?.streamId,
        };
      });
      const subscriberEntries = await Promise.all(subPromises);
      subscriberEntries.forEach((subscriberReport) => {
        const subscriberDetails: UserRTCStats = {
          firstName: subscriberReport.firstName,
          lastName: subscriberReport.lastName,
          securityId: subscriberReport.securityId,
          streamId: subscriberReport.streamId || "",
          reports: [] as unknown[],
        };
        subscriberReport.statsReport.forEach((report) => subscriberDetails.reports.push(report));
        subscribers.push(subscriberDetails);
      });
      return {
        publisher,
        subscribers,
      };
    },
    async downloadRtcReport({ dispatch }): Promise<void> {
      const report: StatsReport = await dispatch("getRtcStats");
      const desc = (user: UserRTCStats) =>
        `${user.firstName} ${user.lastName} (${user.securityId}) (${user.streamId})\n`;
      const filler = () => "\n" + new Array(50).fill("-").join("") + "\n";
      let reportTxt = `Publisher Details\n`;
      reportTxt += filler();
      reportTxt += `${desc(report.publisher)}\n`;
      report.publisher.reports.forEach((publisherReport) => {
        reportTxt += CommonUtil.jsonpp(publisherReport as object);
        reportTxt += filler();
      });
      reportTxt += `\nSubscriber Details\n`;
      reportTxt += filler();
      report.subscribers.forEach((subscriber) => {
        reportTxt += `${desc(subscriber)}\n`;
        subscriber.reports.forEach((subscriberReport) => {
          reportTxt += CommonUtil.jsonpp(subscriberReport as object);
          reportTxt += filler();
        });
      });
      const blob = new Blob([reportTxt], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      saveAs(url, "candidate_report.txt");
      window.URL.revokeObjectURL(url);
    },
    async postRtcReport({ state, dispatch, rootState, rootGetters }): Promise<void> {
      const { publisher, subscribers }: StatsReport = await dispatch("getRtcStats");
      const event = (rootState as RootState).EventModule?.event;
      const vonageSessionId = state.session?.sessionId;
      const effectiveSessionNumber = rootGetters["EventModule/effectiveSessionNumber"];
      const currentSessionStart = rootGetters["EventModule/currentSessionStart"];

      const toPayload = (userStats: UserRTCStats): UserReportPayload => {
        const data = removeReportFluff(userStats.reports);
        return {
          firstName: userStats.firstName,
          lastName: userStats.lastName,
          securityId: userStats.securityId,
          streamId: userStats.streamId,
          data,
        };
      };
      const payload: ReportPayload = {
        publisher: toPayload(publisher),
        subscribers: subscribers.map((e) => toPayload(e)),
        learningEventId: event?.id || "",
        sessionId: vonageSessionId || "",
        sessionNumber: effectiveSessionNumber,
        sessionTime: currentSessionStart,
        clientId: event?.salesForceId || "",
        clientName: event?.clientName || "",
      };
      api.rtcReport.postRTCReport(payload);
    },
  },
};
export default VonageModule;
