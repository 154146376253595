import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import VirtualClassroom from "@/views/VirtualClassroom.vue";
import SystemCheck from "@/views/SystemCheck.vue";
import Extended from "@/views/Extended.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: VirtualClassroom,
  },
  {
    path: "/system-check",
    component: SystemCheck,
  },
  {
    path: "/extended",
    component: Extended,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
