<template lang="pug">
div
  div(v-if="!isObserver")
    .initials(v-html="initials", :style="styleString")
  .observer-img(v-else)
    img(src="@/assets/images/logos/Crucial-C.svg")
</template>
<script lang="ts">
import { UserModel } from "@/types";
import { avatarStyle } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
@Component({
  computed: {
    ...mapState("UsersModule", ["users"]),
  },
})
export default class Avatar extends Vue {
  @Prop({ required: true, type: String }) firstName!: string;
  @Prop({ required: true, type: String }) lastName!: string;
  @Prop({ required: false, type: String }) securityId!: string;

  private readonly users!: UserModel[];

  private styleString = "";
  get initials(): string {
    this.styleString = this.createStyle(this.firstName, this.lastName);
    return `${this.firstName.substring(0, 1)}${this.lastName.substring(0, 1)}`;
  }
  get isObserver(): boolean {
    if (this.securityId) {
      const user = this.users.find((user) => user.securityId === this.securityId);
      if (user) return user.observer;
    }
    return false;
  }
  createStyle(first: string, last: string): string {
    return avatarStyle(first, last);
  }
}
</script>
<style lang="scss">
.initials {
  display: inline-block;
  font-weight: bold;
  border-radius: 100%;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
