<template lang="pug">
.account
  .profile-image.online
    img(src="https://picsum.photos/id/996/32/32")
  .account-dropdown.presentation-dropdown(:class="{ active: accountDropdown === 'account' }")
    nav(:class="{ hidden: statusDropdown === true }")
      ul.list-unstyled
        li 
          i.icon-smile
          | Set Profile Pic
          i.icon-chevron-right.arrow
        li
          i.icon-zap
          | Set Status
          i.icon-chevron-right.arrow
        li 
          i.icon-settings
          | Settings
          i.icon-chevron-right.arrow
        li 
          i.icon-log-out
          | Leave Training
          i.icon-chevron-right.arrow
    ul.list-unstyled.status-picker(:class="{ active: statusDropdown === true }")
      li
        .status-icon.here
        | Here
        .status-selected
          i.icon-check
      li
        .status-icon.busy
        | Busy
      li
        .status-icon.away
        | Away
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Account extends Vue {
  private accountDropdown = "";
  private statusDropdown = false;
}
</script>
