<template lang="pug">
.leave-button-container 
  .leave-button(v-if="!isLeavingSession", @click="setRootState({ isLeavingSession: true })")
    .leave-button-text
      span Leave
  template(v-else)
    .leave-overlay
      .leave-overlay-actions
        .leave-cancel(@click="setRootState({ isLeavingSession: false })")
          span Cancel
        .leave-options
          .leave-confirmed-button-container(v-if="showLeave")
            div(:class="leaveSessionClass", @click="onLeaveSession")
              span Leave Session
          .end-session-button-container(v-if="showEnd", @click="onEndSession")
            .leave-primary-button
              span End Session for All
    .leave-overlay-outter-close(@click="setRootState({ isLeavingSession: false })")
  slot
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
@Component({
  computed: {
    ...mapState(["isLeavingSession"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
  },
})
export default class LeaveButton extends Vue {
  @Prop({ required: true }) showLeave!: boolean;
  @Prop({ required: true }) showEnd!: boolean;
  @Prop({ required: true }) isAdmin!: boolean;

  get leaveSessionClass(): string {
    if (!this.isAdmin) return "leave-primary-button";
    return "leave-secondary-button";
  }

  onLeaveSession(): void {
    this.$emit("leaveSession");
  }
  onEndSession(): void {
    this.$emit("endSession");
  }
}
</script>
<style scope lang="scss">
.leave-button-container {
  height: 36px;
}
.leave-button {
  background-color: var(--error-50);
  border-radius: 5px;
  min-height: 100%;
}
.leave-button:hover {
  background-color: var(--error-20);
  cursor: pointer;
}
.leave-button-text {
  vertical-align: sub;
  display: inline-block;
  margin: 0 0.75rem;
}
.leave-button-text span {
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.leave-overlay {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
.leave-overlay span {
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.leave-overlay:hover span {
  cursor: pointer;
}
.leave-overlay-actions {
  margin: 0.8rem;
}
// cancel button
.leave-cancel {
  text-align: right;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.leave-cancel:hover span {
  color: var(--gray-20);
}
// leave options panel - leave or close
.leave-options {
  border: 1px solid white;
  border-radius: 9px;
  background-color: var(--gray-80);
  margin: 2px;
}
.leave-options > div {
  text-align: center;
  padding: 0.4rem;
}
.leave-options > div:not(:first-child) {
  padding-top: 0;
}
.leave-options > div > div {
  border-radius: 5px;
  padding: 0.5rem;
  display: grid;
}
.leave-secondary-button {
  border: 1px solid white;
  background-color: var(--gray-95);
}
.leave-secondary-button:hover {
  background-color: var(--gray-80);
}
.leave-primary-button {
  background-color: var(--error-50);
}
.leave-primary-button:hover {
  background-color: var(--error-20);
}
.leave-overlay-outter-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
}
</style>
