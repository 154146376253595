import { ActionContext, Module } from "vuex";
import config from "@/config";
import { BrowserState, BrowserWindow, MessageType } from "@/types/browser";

const BrowserModule: Module<BrowserState, unknown> = {
  namespaced: true,
  state(): BrowserState {
    return {
      learnerWindow: undefined,
    };
  },
  mutations: {
    setBrowserState(state: BrowserState, options: Partial<BrowserState>): void {
      Object.assign(state, options);
    },
  },
  getters: {
    isCasting(state: BrowserState): boolean {
      return !!state.learnerWindow;
    },
  },
  actions: {
    openExtendedView({ commit, state }): boolean {
      if (!state.learnerWindow) {
        const options =
          "location=no," +
          "menubar=yes," +
          "resizable=yes," +
          "scrollbars=no," +
          "status=no," +
          "titlebar=no," +
          "toolbar=no,";
        const learnerWindow = window.open(config.frostUrl + "/extended", "CLP Presentation", options);
        if (learnerWindow) {
          commit("setBrowserState", { learnerWindow });
        }
      }
      return !!state.learnerWindow;
    },
    closeExtendedView({ commit, state }): void {
      if (state.learnerWindow) {
        state.learnerWindow.close();
      }
      commit("setBrowserState", { learnerWindow: undefined });
    },
    messageSecondDisplay(
      { state }: ActionContext<BrowserState, unknown>,
      data: { type: MessageType; content: string }
    ): void {
      console.log("Sending " + MessageType[data.type]);
      if (state.learnerWindow) {
        state.learnerWindow.postMessage({ action: "message", message: data }, config.frostUrl);
      }
    },
    messageMainDisplay(_, data: { type: MessageType; content: string }): void {
      const opener: BrowserWindow = window.opener;
      if (opener) {
        opener.postMessage({ action: "message", message: data }, config.frostUrl);
      }
    },
  },
};

export default BrowserModule;
