import { Module } from "vuex";
import {
  BreakoutsState,
  Breakout,
  BreakoutPayloadAdd,
  UserModel,
  Group,
  RootState,
  BreakoutPayloadUsers,
  BreakoutPayloadUpdateGroup,
  BreakoutPayloadRemoveGroup,
  BreakoutPayloadAddGroup,
  BreakoutPayloadGroup,
  BreakoutInfo,
  BreakoutPayloadDuplicate,
  BreakoutPopup,
} from "@/types";
import { sockets } from "@/services/socket_api";
import { getInstance } from "@cruciallearning/puddle/auth";
import authorization from "@/auth/authorization";
import { Toaster, ToastType } from "@cruciallearning/puddle/toast";
import store from "@/store";

const BreakoutsModule: Module<BreakoutsState, RootState> = {
  namespaced: true,
  state(): BreakoutsState {
    return {
      editBreakout: { id: "", name: "", slide: "" },
      activeId: "",
      activeGroupId: "",
      breakouts: [],
      broadcasting: false,
      isActive: false,
      isAutoAssigning: false,
      isLoadingBreakouts: true,
      removeId: "",
      breakoutPopup: null,
    };
  },
  getters: {
    getActiveBreakout(state: BreakoutsState): Breakout | undefined {
      if (!state.isActive) return void 0;
      return state.breakouts.find((breakout) => breakout.id === state.activeId);
    },
    getActiveBreakoutSelf(_, getters, rootState): Group | undefined {
      const groups = getters.getActiveBreakout?.groups as Group[];
      if (!groups) return;
      const securityId = getInstance().authUser.id;
      const group = (getters.getActiveBreakout?.groups as Group[]).find(
        (group) => group.userIds && securityId && group.userIds.includes(securityId)
      );
      if (group) {
        group.users = (rootState as RootState).UsersModule?.users
          .filter((user) => group.userIds.includes(user.securityId))
          .map(({ firstName, lastName, securityId }) => {
            return { firstName, lastName, securityId };
          });
      }
      return group;
    },
    getActiveBreakoutNonGroupUsers(state: BreakoutsState, getters, rootState): Partial<Group> | undefined {
      if (getters.getActiveBreakoutSelf) return void 0;
      const userIds: string[] | undefined = getters.getActiveBreakout?.groups?.flatMap((group: Group) =>
        group.userIds !== null ? group.userIds : []
      );
      if (userIds) {
        const group: Partial<Group> = {};
        group.userIds =
          (rootState as RootState).UsersModule?.users
            .filter((user) => !userIds.includes(user.securityId))
            .map(({ securityId }) => securityId) || [];
        group.users = (rootState as RootState).UsersModule?.users
          .filter((user) => !userIds.includes(user.securityId))
          .map(({ securityId }) => ({ securityId }));
        return group;
      }
      return void 0;
    },
    getAvailableUsers(state: BreakoutsState, getters, rootState): Partial<UserModel>[] | undefined {
      if (!state.editBreakout?.name) return;
      const groups: Group[] = getters.getEditBreakoutGroups;
      const breakoutUsers = groups.flatMap((item) => item.users?.map((item) => item.securityId));
      return (rootState as RootState).UsersModule?.users
        .filter((user) => !breakoutUsers.some((bUser) => bUser === user.securityId))
        .map((user) => ({
          firstName: user.firstName,
          lastName: user.lastName,
          securityId: user.securityId,
          away: user.away,
        }));
    },
    getEditBreakoutGroups(state: BreakoutsState, getters, _rootState, rootGetters): Group[] {
      const breakout = state.breakouts.find((breakout) => breakout.id === state.editBreakout?.id);
      if (!breakout) return [];

      const groups: Group[] = JSON.parse(JSON.stringify(breakout.groups));
      groups.forEach((group) => {
        const users: Partial<UserModel>[] = [];
        if (group.userIds) {
          group.userIds.forEach((id) => {
            const user = rootGetters["UsersModule/getById"](id);
            if (user)
              users.push({
                firstName: user.firstName,
                lastName: user.lastName,
                securityId: user.securityId,
                away: user.away,
              });
          });
        }
        group.users = users;
      });
      return groups;
    },
    getEditBreakoutOrigin(state: BreakoutsState): BreakoutsState["editBreakout"] {
      const breakout = state.breakouts.find((breakout) => breakout.id === state.editBreakout?.id);
      if (breakout) return { id: breakout.id, name: breakout.name, slide: breakout.slide };
    },
    getUserInGroupById(state: BreakoutsState, getters): (securityId: string) => boolean {
      const breakout: Breakout | undefined = getters.getActiveBreakout;
      return (securityId: string): boolean => {
        return breakout?.groups.find((group) => group.userIds?.includes(securityId)) !== undefined;
      };
    },
  },
  mutations: {
    resetEditBreakout(state: BreakoutsState): void {
      state.editBreakout = { id: "", name: "", slide: "" };
    },
    setEditBreakout(state: BreakoutsState, editBreakoutOptions: Partial<BreakoutsState["editBreakout"]>): void {
      if (state.editBreakout) {
        Object.assign(state.editBreakout, editBreakoutOptions);
      }
    },
    setBreakout(state: BreakoutsState, breakout: Breakout): void {
      const currentBreakout = state.breakouts.find((item) => item.id === breakout.id);
      if (currentBreakout)
        Object.assign(currentBreakout, {
          id: breakout.id,
          name: breakout.name,
          slide: breakout.slide,
          groups: JSON.parse(JSON.stringify(breakout.groups)),
        });
      else state.breakouts.push(JSON.parse(JSON.stringify(breakout)));
    },
    setBreakouts(state: BreakoutsState, breakouts?: Breakout[]): void {
      if (breakouts) {
        state.breakouts = JSON.parse(JSON.stringify(breakouts));
      }
    },
    setBreakoutsState(state: BreakoutsState, breakoutStateOptions: Partial<BreakoutsState>): void {
      Object.assign(state, breakoutStateOptions);
    },
    setBreakoutPopup(state: BreakoutsState, popup: BreakoutPopup): void {
      state.breakoutPopup = popup;
    },
    setGroup(state: BreakoutsState, breakoutGroupOptions: BreakoutPayloadUpdateGroup): void {
      const breakout = state.breakouts.find((breakout) => breakout.id === breakoutGroupOptions.breakoutId);
      const breakoutGroup = breakout?.groups.find((group) => group.id === breakoutGroupOptions.groupId);
      const group = {
        id: breakoutGroupOptions.groupId || "",
        name: breakoutGroupOptions.groupName || "",
        slide: breakoutGroupOptions.slide || "",
        userIds: breakoutGroupOptions.userIds || [],
      };
      if (breakoutGroup) Object.assign(breakoutGroup, group);
      else breakout?.groups.push(JSON.parse(JSON.stringify(group)));
    },
  },
  actions: {
    addBreakout(_, payload: BreakoutPayloadAdd): void {
      if (!payload.slide) payload.slide = "";
      sockets.breakout.add(payload);
    },
    addBreakoutReceived({ commit, state }, breakout: Breakout): void {
      commit("setBreakouts", [...state.breakouts, breakout]);
      commit("setEditBreakout", { id: breakout.id, name: breakout.name, slide: breakout.slide });
    },
    addGroup(_, payload: BreakoutPayloadAddGroup): void {
      sockets.breakout.addGroup(payload);
    },
    addUsers(_, payload: BreakoutPayloadUsers): void {
      sockets.breakout.addUsers(payload);
    },
    duplicateBreakout(_, payload: BreakoutPayloadDuplicate): void {
      sockets.breakout.duplicate(payload);
    },
    broadcastMessage(_, message: string): void {
      let toast = message;
      if (message.length > 19) {
        toast = message.slice(0, 19);
        toast = toast + " ...";
      }
      Toaster.add(ToastType.BROADCAST_MESSAGE_SENT, toast);
      sockets.general.send(message, "breakout.broadcast");
    },
    broadcastReceived({ getters, commit, rootGetters }, data: { message: string; senderId: string }): void {
      if (getters.getActiveBreakoutSelf) {
        const sender = rootGetters["UsersModule/getById"](data.senderId);
        const popup: BreakoutPopup = {
          iconValue: "message-square",
          headerText: `From ${sender.firstName} ${sender.lastName}`,
          bodyText: data.message,
          secondaryButton: {
            buttonText: "CLOSE",
            callback: () => console.log("Closing broadcast breakout"),
          },
        };
        commit("setBreakoutPopup", popup);
      }
    },
    createBreakouts({ commit }, breakouts: Breakout[]): void {
      commit("setBreakouts", breakouts);
      sockets.breakout.breakouts();
    },
    leaveBreakout(): void {
      sockets.breakout.leaveBreakout();
    },
    populateBreakoutInfo({ commit }, model: BreakoutInfo): void {
      commit("setBreakoutsState", {
        isActive: model.isActive,
        activeId: model.isActive ? model.activeId : "",
        isLoadingBreakouts: false,
      });
      commit("setBreakouts", model.breakouts);
    },
    removeBreakout({ commit }, id: string): void {
      commit("setBreakoutsState", { removeId: "" });
      sockets.breakout.remove(id);
    },
    removeBreakoutReceived({ commit, state }, payload: Partial<Breakout>[]): void {
      const ids = payload.flatMap((breakout) => breakout.id);
      const breakouts = state.breakouts.filter((breakout) => ids.includes(breakout.id));
      commit("setBreakouts", breakouts);
    },
    removeGroup(_, payload: BreakoutPayloadRemoveGroup): void {
      sockets.breakout.removeGroup(payload);
    },
    removeUsers(_, payload: BreakoutPayloadUsers): void {
      sockets.breakout.removeUsers(payload);
    },
    startBreakout(_, { payload }): void {
      sockets.breakout.startBreakout(payload);
    },
    startBreakoutReceived({ commit, state }, payload: Breakout): void {
      if (state.activeId) return;
      if (payload.groups) {
        commit("setBreakout", payload);
        commit("setBreakoutsState", { activeId: payload.id });
        const popup: BreakoutPopup = {
          iconValue: "join-breakout",
          headerText: "Starting Breakouts in",
          timer: {
            increment: false,
            interval: 3,
            callback: () => store.commit("BreakoutsModule/setBreakoutsState", { isActive: true }),
          },
        };
        commit("setBreakoutPopup", popup);
      }
    },
    stopBreakout({ state }): void {
      const securityId = getInstance().authUser.id;
      if (!authorization.isManager(securityId) && !authorization.isObserver(securityId)) return;
      if (state.isActive) {
        sockets.breakout.stopBreakout();
      }
    },
    stopBreakoutReceived({ commit }): void {
      const popup: BreakoutPopup = {
        iconValue: "leave-breakout",
        headerText: "Stopping Breakouts in",
        timer: {
          increment: false,
          interval: 10,
          callback: () => store.commit("BreakoutsModule/setBreakoutsState", { isActive: false, activeId: "" }),
        },
      };
      commit("setBreakoutPopup", popup);
      setTimeout(() => {
        commit("setBreakoutsState", { activeId: "", isActive: false, slide: "", isStopping: false });
      }, 10000);
    },
    updateEditBreakout({ commit }, editBreakoutOptions: Partial<BreakoutsState["editBreakout"]>): void {
      commit("setEditBreakout", editBreakoutOptions);
    },
    updateBreakout(_, payload: Partial<Breakout>): void {
      sockets.breakout.updateBreakout(payload);
    },
    updateBreakoutReceived({ commit }, payload: Breakout): void {
      if (payload.groups) commit("setBreakout", payload);
    },
    updateBreakoutsState({ commit }, breakoutStateOptions: Partial<BreakoutsState>): void {
      commit("setBreakoutsState", breakoutStateOptions);
    },
    updateGroup(_, payload: BreakoutPayloadGroup): void {
      sockets.breakout.updateGroup(payload);
    },
    updateGroupReceived({ commit }, breakoutGroupOptions: BreakoutPayloadUpdateGroup): void {
      commit("setGroup", breakoutGroupOptions);
    },
    breakoutUserRemoved({ dispatch, rootGetters }, payload: Breakout): void {
      const adminCheck = (id: string | undefined) => authorization.isManager(id) || authorization.isObserver(id);
      const auth = getInstance();
      const selfSecurityId = auth.authUser.id;
      const userLeaving = rootGetters["UsersModule/getById"](payload.senderId);
      const name = `${userLeaving.firstName} ${userLeaving.lastName}`;
      if (!adminCheck(payload.senderId) && adminCheck(selfSecurityId)) {
        Toaster.add(ToastType.USER_LEFT_BREAKOUT, name);
      }
      dispatch("updateBreakoutReceived", payload);
    },
  },
};
export default BreakoutsModule;
