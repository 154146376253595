<template lang="pug">
.attendee-group(v-if="users.length > 0 && $auth.ADMIN")
  .group-header(@click="showUsers = !showUsers")
    p {{ title }} ({{ users.length }})
    i(:class="showUsers ? `icon-chevron-down` : `icon-chevron-up`")
  .group-content
    ul.attendee-list(v-show="showUsers")
      li.listClass(v-for="(user, index) in users")
        .name
          p {{ user.firstName }} {{ user.lastName }}
        .attendee-icon(:data-name="`${dataName}-overlay-${index}`")
          .span.icon-button(
            :id="`${dataName}${index}`",
            @click="onMenuClick(`#${dataName}${index}`, user)",
            :data-name="`${dataName}-menu-${index}`"
          )
            i.icon-dots-three-outline
</template>

<script lang="ts">
import { UserModel } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  methods: { ...mapActions(["admitToClassroom"]) },
})
export default class AttendeeLobby extends Vue {
  @Prop({ required: true }) readonly dataName!: string;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly users!: UserModel[];

  private showUsers = true;

  onMenuClick(id: string, user: UserModel): void {
    this.$emit("clickMenu", { id, user: user });
  }
}
</script>
