import { http } from "@/http";
import Routes from "@/services/routes";
import { ReportPayload } from "@/types/vonage";

const RtcReport = {
  async postRTCReport(payload: ReportPayload): Promise<void> {
    const url = `${Routes.AUDITRON}/rtc_report`;
    await http.post<void>(url, payload);
  },
};
export default RtcReport;
