<template lang="pug">
Layout(:publisher="publisher")
</template>

<script lang="ts">
import Layout from "@/components/Layout/BaseLayout.vue";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: { Layout },
})
export default class Content extends Vue {
  @Prop() publisher!: OT.Publisher | null;
}
</script>
