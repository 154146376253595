<template lang="pug">
.contents-passthrough
  .inner-trainer-container
    .close-item(@click="openSideView(sideViewType.BREAKOUT)")
      i.icon-x
    .new-breakout-group.no-shrink
      p.back-stage-header
        i.icon-user.align-middle
        | Add Users
      .settings-set
        TextBox(
          label="Search",
          small,
          backgroundColor="white",
          placeholder="Search Available Users",
          valueChangeEvent="input",
          @valueChanged="searchKey = $event"
        )
    ul.list-unstyled.select-participants.no-label-height.overflow
      li(v-for="(user, index) in availableUsers", :key="user.securityId")
        input(type="checkbox", :id="index", @change="onUserSelected($event.target.checked, user.securityId)")
        label(:for="index")
          span.name.capitalize
            p {{ user.firstName }} {{ user.lastName }}
              span.isAway(v-if="user.away") &nbsp;(Away)
              span.isTrainer(v-if="isTrainer(user)") &nbsp;(Trainer)
              span.isModerator(v-if="isModerator(user)") &nbsp;(Moderator)
          span.faux-checkbox
  .panel-actions.no-bg
    button.button.full(@click="onAddToGroupClick") Add To Group
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import { AddUsers, Breakout, GetModerators, GetTrainers, OpenSideView, SideViewType, UserModel } from "@/types";
import { BaseTextBox as TextBox } from "@cruciallearning/puddle";

@Component({
  components: { TextBox },
  computed: {
    ...mapState("BreakoutsModule", ["editBreakout", "activeGroupId"]),
    ...mapGetters("BreakoutsModule", ["getAvailableUsers"]),
  },
  methods: {
    ...mapActions("BreakoutsModule", ["addUsers"]),
    ...mapActions("SidebarModule", ["openSideView"]),
    ...mapGetters("UsersModule", ["getTrainers", "getModerators"]),
  },
})
export default class SelectParticipants extends Vue {
  private readonly editBreakout!: Breakout;
  private readonly activeGroupId!: string;
  private readonly getAvailableUsers!: Partial<UserModel>[] | undefined;
  private readonly getTrainers!: GetTrainers;
  private readonly getModerators!: GetModerators;
  private readonly addUsers!: AddUsers;
  private readonly openSideView!: OpenSideView;

  private searchKey = "";
  private selectedUserIds: string[] = [];
  private sideViewType: unknown = SideViewType;

  get availableUsers(): Partial<UserModel>[] | undefined {
    if (!this.searchKey) return this.getAvailableUsers;
    return this.getAvailableUsers?.filter((user) => {
      const name = `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`;
      return name.indexOf(this.searchKey.toLowerCase()) !== -1;
    });
  }

  isTrainer(user: Partial<UserModel>): boolean {
    return this.getTrainers().find((e) => e.securityId == user.securityId) != undefined;
  }

  isModerator(user: Partial<UserModel>): boolean {
    return this.getModerators().find((e) => e.securityId == user.securityId) != undefined;
  }

  onAddToGroupClick(): void {
    this.addUsers({
      breakoutId: this.editBreakout.id,
      groupId: this.activeGroupId,
      userIds: [...this.selectedUserIds],
    });
    this.openSideView(SideViewType.BREAKOUT);
  }

  onUserSelected(checked: boolean, userId: string): void {
    const userIndex = this.selectedUserIds.findIndex((id) => id === userId);
    if (userIndex !== -1) this.selectedUserIds.splice(userIndex, 1);
    if (checked) this.selectedUserIds.push(userId);
  }
}
</script>
<style lang="scss" scoped>
::v-deep .capitalize {
  text-transform: capitalize;
}
</style>
