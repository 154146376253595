<template lang="pug">
.presentation-header
  .logo
    img(src="@/assets/images/logos/cl-mark-white.svg", data-name="logo")
  .title
    p.title-text(data-name="titleText") {{ title }}
    p.reference-number(data-name="event-id") {{ referenceNumber }}
  Toolbar
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import Toolbar from "@/components/Header/Toolbar/BaseToolbar.vue";
import { EventContentModel } from "@cruciallearning/puddle";

@Component({
  components: { Toolbar },
  computed: {
    ...mapState("CourseModule", ["title"]),
    ...mapState("EventModule", ["event"]),
  },
})
export default class BaseHeader extends Vue {
  private readonly event!: EventContentModel;

  get referenceNumber(): string {
    if (this.event && this.event.referenceNumber) {
      return `Reference: ${this.event.referenceNumber}`;
    }
    return "";
  }
}
</script>
<style lang="scss" scoped>
.reference-number {
  text-transform: none !important;
}
</style>
