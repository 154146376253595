import { sockets } from "../socket_api";
import socket from "../socket";
import { getInstance } from "@cruciallearning/puddle/auth";

class Pulse {
  private debouncer?: NodeJS.Timeout;
  private idle = true;
  private idleTimeout = 5000;
  private interval = 300000;
  private intervals = 0;
  private intervalLimit = 12;
  private timer?: NodeJS.Timeout;

  runtime = 0;

  reconnect(): void {
    this.stop();
    console.log("Getting New Socket Session");
    const token = getInstance().authUser.token;
    if (token) {
      socket.connect(token);
    }
  }

  start(interval?: number, intervaleLimit?: number): void {
    interval && (this.interval = interval);
    intervaleLimit && (this.intervalLimit = intervaleLimit);
    if (!this.timer) this.timer = setInterval(this.onInterval.bind(this), this.interval);
  }

  stop(): void {
    if (this.timer) clearInterval(this.timer);
    if (this.debouncer) clearTimeout(this.debouncer);
    this.timer = void 0;
    this.debouncer = void 0;
    this.intervals = 0;
  }

  update() {
    this.idle = false;
    if (this.debouncer) clearTimeout(this.debouncer);
    this.debouncer = setTimeout(() => {
      this.idle = true;
      if (this.intervals >= this.intervalLimit) this.reconnect();
    }, this.idleTimeout);
  }

  private onInterval(): void {
    this.intervals += 1;
    console.log("Ping: " + this.intervals);
    if (this.idle) sockets.ping.send();
  }
}
export const pulse = new Pulse();
