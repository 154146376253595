<template lang="pug">
.breakout-container
  .breakout-view
    .breakout-title
      .breakout-button-panel 
        BreakoutLeave
        TimerOverlay(fixed)
      p.title-3.breakout-name Breakout {{ getActiveBreakoutSelf.name }}
      p.group-participants
        i.icon-user-circle.align-middle
        | {{ breakoutAwareUserCount }} Users
    .breakout-group-container
      #breakout-group-list
        #breakout-users.flex
</template>

<script lang="ts">
import { Group, SetActiveId, SetGroup } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
import BreakoutLeave from "./BreakoutLeave.vue";
import TimerOverlay from "../Overlay/TimerOverlay.vue";
@Component({
  components: { BreakoutLeave, TimerOverlay },
  computed: {
    ...mapState("ChatModule", ["editMessage"]),
    ...mapState(["breakoutActiveSpeakerId"]),
    ...mapState("UsersModule", ["users"]),
    ...mapState("VonageModule", ["publisher", "subscribers"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapGetters("UsersModule", ["breakoutAwareUserCount"]),
  },
  methods: { ...mapMutations("ChatModule", ["setGroup", "setActiveId"]) },
})
export default class BaseBreakout extends Vue {
  private readonly getActiveBreakoutSelf!: Group | undefined;
  private readonly setGroup!: SetGroup;
  private readonly setActiveId!: SetActiveId;
  private readonly breakoutActiveSpeakerId!: string;

  private readonly publisher!: OT.Publisher | null;
  private readonly subscribers!: OT.Subscriber[];

  private readonly editMessage!: string;

  private groupId = "";
  private groupName = "";

  beforeDestroy(): void {
    this.removeGroup();
  }

  created(): void {
    this.createGroup();
  }

  createGroup(): void {
    if (!this.getActiveBreakoutSelf) return;
    this.groupId = this.getActiveBreakoutSelf.chatId as string;
    this.groupName = this.getActiveBreakoutSelf.name;
    const participants =
      this.getActiveBreakoutSelf.users?.map((user) => {
        return {
          id: user.securityId as string,
          firstName: user.firstName as string,
          lastName: user.lastName as string,
        };
      }) || [];
    this.setGroup({
      groupId: this.groupId,
      groupName: this.groupName,
      participants: [...participants],
      isNew: false,
      messages: [],
      breakout: true,
    });
    if (this.editMessage.length == 0) {
      this.setActiveId(this.groupId);
    }
  }

  removeGroup(): void {
    this.setGroup({ groupId: this.groupId, groupName: this.groupName });
  }

  @Watch("breakoutActiveSpeakerId")
  activeSpeakerUpdated(): void {
    if (this.publisher && this.publisher.stream) {
      const publisherId = JSON.parse(this.publisher.stream?.connection.data).securityId;
      if (this.breakoutActiveSpeakerId === publisherId) {
        this.publisher.element?.classList.add("active-speaker-video");
      } else {
        this.publisher.element?.classList.remove("active-speaker-video");
      }
    }
    if (this.subscribers) {
      this.subscribers.forEach((sub) => {
        if (sub.stream) {
          const subId = JSON.parse(sub.stream.connection.data).securityId;
          if (subId == this.breakoutActiveSpeakerId) {
            sub.element?.classList.add("active-speaker-video");
          } else {
            sub.element?.classList.remove("active-speaker-video");
          }
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  justify-content: center;
  flex-wrap: wrap;
}
::v-deep .OT_root {
  margin: 1rem !important;
}
#breakout-subscribers {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.breakout-view {
  overflow-y: scroll;
}

.breakout-name {
  width: fit-content;
  margin: 0 auto;
}

.breakout-button-panel {
  float: right;
  position: relative;
}
.breakout-button-panel div {
  position: relative !important;
}
::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--info-20);
}

::v-deep .OT_audio-only .video-off-initials {
  z-index: 5 !important;
}
</style>
