import { sockets } from "@/services/socket_api";
import store from "@/store";
import { OTError } from "@opentok/client";

const publishErrors = (error?: OTError) => {
  if (error) {
    let errorMessage = "";
    switch (error.name) {
      case "OT_NOT_CONNECTED":
        errorMessage = "Publishing your video failed. You are not connected to the internet.";
        break;
      case "OT_CREATE_PEER_CONNECTION_FAILED":
        errorMessage = "Publishing your video failed. This could be due to a restrictive firewall.";
        break;
      case "OT_INVALID_VIDEO_SOURCE":
        errorMessage = "The videoSourceId or deviceId is invalid.";
        break;
      case "OT_SET_VIDEO_SOURCE_FAILURE":
        errorMessage = "Publisher is not using a camera input.";
        break;
      case "OT_NOT_SUPPORTED":
        errorMessage = "User's browser does not support video filters.";
        break;
      case "OT_UNEXPECTED_SERVER_RESPONSE":
        errorMessage = "An error occurred with an unexpected response during publishing.";
        break;
      default:
        errorMessage = "An unknown error occurred while trying to publish your video or audio. Please try again later.";
    }
    sockets?.remoteLogger?.logMessage(
      `${errorMessage} -- (practice:${store.state.EventModule?.event.practice}) -- (systemCheck:${store.state.EventModule?.event.systemCheck})`
    );
  }
};
const genericErrors = (error?: OTError) => {
  if (error) {
    console.error(error);
    sockets?.remoteLogger?.logMessage(JSON.stringify(error));
  }
};

export const sessionPublishHandler = (error: OTError) => {
  console.error(error);
  if (error) {
    let errorDisplay: { header: string; body: string } | null = null;
    switch (error.name) {
      case "OT_HARDWARE_UNAVAILABLE": {
        errorDisplay = {
          header: "Hardware Unavailable",
          body: "Please ensure no other applications are currently using your mic or camera",
        };
        break;
      }
    }
    if (error) {
      store.commit("setErrorState", errorDisplay);
    }
    sockets?.remoteLogger?.logMessage(
      `Session publish error: ${error.name} -- (practice:${store.state.EventModule?.event.practice}) -- (systemCheck:${store.state.EventModule?.event.systemCheck})`
    );
  }
};

export { genericErrors, publishErrors };
