<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Presentation Slides
  .system-check-card-contents
    .system-check-description
      .gallery-container
        Gallery(:dataSource="slideImages", width="650px", :slideShowDelay="2000", @initialized="galleryReady")
      span Are you able to see the slides being displayed? You should see two alternating images.
      .confirmation-buttons(v-if="galleryLoaded")
        Button(text="No", primary, small, dataName="slides-button-no", @click="failActive(message)")
        Button(text="Yes, Next", primary, small, dataName="slides-button-yes", @click="passActive")
      Status
      #warning-message(v-if="isActiveFailed")
        span(v-html="htmlMessage")
      .space
</template>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep.vue";
import { mapState } from "vuex";
import { BaseGallery as Gallery } from "@cruciallearning/puddle";
import { Course } from "@/types/course";

@Component({
  components: {
    Gallery,
  },
  computed: {
    ...mapState("CourseModule", ["course"]),
  },
})
export default class PresentationSlides extends BaseStep {
  private galleryLoaded = false;
  private readonly course!: Course;
  get slideImages(): string[] {
    console.log(
      this.course.lessons
        .flatMap((lesson) => lesson.slides)
        .filter((slide) => slide != undefined)
        .flatMap((slide) => slide.frames)
    );
    return this.course.lessons
      .flatMap((lesson) => lesson.slides)
      .filter((slide) => {
        const videoSlide = slide.frames.find((e) => e.video != undefined);
        const pollSlide = slide.frames.find((e) => e.poll != undefined);
        return !videoSlide && !pollSlide;
      })
      .flatMap((slide) => slide.frames)
      .map((frame) => frame.content)
      .map((content) => {
        if (content) {
          return content.image.url;
        }
        return "";
      })
      .filter((e) => e != "");
  }

  get htmlMessage(): string {
    return `Slides aren’t visible.`;
  }

  get message(): string {
    return this.failedMessage([this.htmlMessage]);
  }
  galleryReady(): void {
    this.galleryLoaded = true;
  }
}
</script>
<style lang="scss" scoped>
.gallery-container {
  margin: 1rem 0 2rem 0;
  height: 400px;
}
.space {
  height: 1rem;
}
::v-deep .step-status {
  margin-left: -1px;
}
</style>
