/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */
export interface BrowserState {
  learnerWindow?: BrowserWindow;
}

export interface BrowserWindow {
  postMessage: (message: BrowserMessage, targetOrigin: string) => void;
  close: () => void;
}

export interface BrowserMessage {
  action: string;
  message: BrowserMessageData;
}

export interface BrowserMessageEvent {
  origin: string;
  isTrusted: boolean;
  data: {
    message: BrowserMessageData;
  };
}
export interface BrowserMessageData {
  type?: MessageType;
  content?: string;
}

export enum MessageType {
  LEARNER_WINDOW_CLOSED,
  SLIDE_NAVIGATION,
  INITIALIZED,
  PAGE_NUMBER,
  TIMER_UPDATE,
  REMOVE_OVERLAY,
  APPLY_OVERLAY,
  PLAY,
  PAUSE,
  SCRUB,
}

/*
 * ------------------------------------------------------------------------------
 * Methods
 * ------------------------------------------------------------------------------
 */

export interface SetBrowserState {
  (changes: Partial<BrowserState>): void;
}
export interface SendWindowMessage {
  (data: { type: MessageType; content?: string }): void;
}
