import { RootState } from "@/types";
import { ErrorState } from "@/types/error";
import { Module } from "vuex";

const ErrorModule: Module<ErrorState, RootState> = {
  namespaced: true,
  state: {
    header: "",
    body: "",
  },
  mutations: {
    setErrorState(state: ErrorState, changes: Partial<ErrorState>): void {
      Object.assign(state, changes);
    },
  },
  getters: {},
  actions: {},
};

export default ErrorModule;
