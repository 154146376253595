<template lang="pug">
.timer-sound-select
  .mute-timer-icon
    Icon.toggle-mute-icon(
      :value="iconValue",
      dataName="mute-timer",
      :color="isMuted",
      height="1.3rem",
      width="1.3rem",
      @click="onMuteClick"
    )
    .sound-options.timer-set.input
      SelectBox(
        :dataSource="timerSoundsDataSource",
        :customStyle="true",
        :custom-field-style="true",
        :value="sound",
        data-name="timer-sound-select",
        @valueChanged="onSoundClick"
      )
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import { BaseSelectBox as SelectBox } from "@cruciallearning/puddle";
import { SetMute, SetTimerSound } from "@/types/timer";
import { Icon } from "@cruciallearning/puddle/components";

@Component({
  components: { Icon, SelectBox },
  computed: { ...mapState("TimerModule", ["muted", "sound"]) },
  methods: {
    ...mapActions("TimerModule", ["setMute", "setTimerSound"]),
    ...mapMutations("TimerModule", ["setTimerState"]),
  },
})
export default class TimerSoundSelect extends Vue {
  private readonly muted!: boolean;
  private readonly sound!: string;
  private readonly setTimerSound!: SetTimerSound;
  private readonly setMute!: SetMute;

  private soundOptions: string[] = [];

  get iconValue(): string {
    return this.muted ? "volume-x" : "volume-2";
  }
  mounted(): void {
    require
      .context("@/assets/audio/", true, /\.mp3$/)
      .keys()
      .forEach((e) => {
        const stripMp3 = e.replaceAll(".mp3", "");
        this.soundOptions.push(stripMp3.substring(2));
      });
  }

  get timerSoundsDataSource(): string[] {
    return this.soundOptions;
  }

  onMuteClick(): void {
    this.setMute(!this.muted);
  }

  get isMuted(): string {
    return this.muted ? "var(--error-50)" : "var(--success-50)";
  }
  onSoundClick(option: string): void {
    this.setTimerSound(option);
  }
}
</script>
<style lang="scss" scoped>
.timer-sound-select {
  margin-left: 0.5rem;
  margin-top: 1rem;
}
.mute-timer-icon {
  text-align: left;
  margin-top: 0.75rem;
  display: flex;
}
.toggle-mute-icon:hover {
  border-radius: 5px;
}
.toggle-mute-icon {
  border: 0.25rem solid white;
  border-radius: 10px;
  border-bottom: none;
  margin-right: 10px;
}
.toggle-mute-icon:hover {
  background: var(--gray-20);
  border-color: var(--gray-20);
}
.sound-options {
  height: 3.5rem;
  cursor: default;
  display: inline-block;
  width: 82%;
}
::v-deep .dx-texteditor-buttons-container {
  background-color: white;
}
::v-deep .dx-texteditor.dx-editor-outlined {
  border-radius: 4px !important;
  border-color: var(--gray-10);
  color: var(--gray-95);
}
</style>
import { SetTimerSound, SetMute } from "@/models/timer";
