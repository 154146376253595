<template lang="pug">
div(style="overflow-y: auto")
  //- video id has been made into a separate 
  //- variable since is HAS to remain constant.
  //- it is required to match the vonage element id
  AttendeeList(
    dataName="trainer",
    title="Trainers",
    :users="getBreakoutAwareTrainers",
    :videoElementId="trainerVideoId"
  )
  AttendeeList(
    dataName="moderator",
    title="Moderators",
    :users="getBreakoutAwareModerators",
    :videoElementId="moderatorVideoId"
  )
  AttendeeList(
    dataName="learner",
    title="Learners",
    :users="getLearners",
    @clickMenu="onClickMenu",
    :videoElementId="learnerVideoId"
  )
  AttendeeLobby(dataName="lobby", title="Lobby", :users="getLobby", @clickMenu="onClickMenu")

  PopoverMenu(
    :target="target",
    :visible="isAttendeeMenuVisible",
    @hiding="onHidingAttendeeMenu",
    popoverPosition="top left",
    targetPosition="bottom left",
    width="230px",
    popover-class="attendeeMenu"
  )
    AttendeeMenu(:user="targetUser || {}", @hiding="onHidingAttendeeMenu")
</template>

<script lang="ts">
import { GetById, UserModel } from "@/types";
import { VideoContainerId } from "@/types/vonage";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import AttendeeList from "./AttendeeList.vue";
import AttendeeLobby from "./AttendeeLobby.vue";
import AttendeeMenu from "@/components/Sidebar/Attendees/AttendeeMenu.vue";
import { BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter";

@Component({
  components: { AttendeeList, AttendeeLobby, AttendeeMenu, PopoverMenu },
  computed: {
    ...mapGetters("UsersModule", [
      "getBreakoutAwareTrainers",
      "getBreakoutAwareModerators",
      "getLearners",
      "getLobby",
      "getById",
    ]),
  },
})
export default class BaseAttendees extends Vue {
  private readonly getById!: GetById;

  private isAttendeeMenuVisible = false;
  private target = "";
  private targetUser?: UserModel | null = null;

  get learnerVideoId(): string {
    return VideoContainerId.LEARNERS;
  }

  get trainerVideoId(): string {
    return VideoContainerId.TRAINERS;
  }

  get moderatorVideoId(): string {
    return VideoContainerId.MODERATORS;
  }

  created(): void {
    GlobalEventEmitter.$on("videoClickMenu", (event: { divId: string; userId: string }) => {
      const user = this.getById(event.userId);
      if (user) this.onClickMenu({ id: event.divId, user: user });
    });
  }

  beforeDestroy(): void {
    GlobalEventEmitter.$off("videoClickMenu");
  }

  onClickMenu({ id, user }: { id: string; user: UserModel }): void {
    if (id === this.target && this.isAttendeeMenuVisible) {
      this.onHidingAttendeeMenu();
      return;
    }
    this.target = id;
    this.targetUser = user;
    this.isAttendeeMenuVisible = true;
  }

  onHidingAttendeeMenu(): void {
    this.isAttendeeMenuVisible = false;
  }
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 10px;
}
</style>
