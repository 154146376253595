/* eslint-disable @typescript-eslint/no-explicit-any */
export const removeReportFluff = (input: any[]): string => {
  const report: any[] = JSON.parse(JSON.stringify(input));
  for (let i = report.length - 1; i >= 0; i--) {
    const fields: string[] = REPORT_BLUE_PRINT[report[i].type];
    if (!fields) {
      report.splice(i, 1);
    } else {
      for (const field in report[i]) {
        const fieldIdx = fields.findIndex((e) => field === e || field === "type");
        if (fieldIdx == -1) {
          delete report[i][field];
        }
      }
    }
  }
  return JSON.stringify(report);
};

const REPORT_BLUE_PRINT: { [index: string]: string[] } = {
  "candidate-pair": ["avaiableOutgoingBitrate", "localCandidateId", "nominated", "remoteCandidateId"],
  "inbound-rtp": [
    "kind",
    "packetsLost",
    "jitterBufferDelay",
    "jitterBufferEmittedCount",
    "jitterBufferMinimumDelay",
    "jitterBufferTargetDelay",
  ],
  "remote-candidate": ["ip", "port", "protocol"],
  "local-candidate": ["ip", "networkType", "port", "protocol", "relatedPort", "url"],
  "outbound-rtp": [
    "kind",
    "bytesSent",
    "packetsSent",
    "nackCount",
    "targetBitrate",
    "totalPacketsSentDelay",
    "frameHeight",
    "frameWidth",
    "framesPerSecond",
    "framesSent",
    "pliCount",
    "qualityLimitDurations",
    "qualityLimitationReason",
    "qualityLimitationResolutionChanges",
    "targetBitrate",
    "retransmittedByteSent",
    "retransmittedPacketsSent",
  ],
  "remote-inbound-rtp": ["kind", "jitter", "packetsLost", "fractionLost"],
  "media-source": [
    "kind",
    "audioLevel",
    "echoReturnLoss",
    "echoReturnLossEnhancement",
    "frames",
    "framesPerSecond",
    "height",
    "width",
  ],
  transport: ["selectedCandidatePairChanges", "selectedCandidatePairId"],
};
