<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Speaker
  .system-check-card-contents
    .system-check-description
      Button(@click="testSpeaker", small, secondary, text="Test Speaker")
      .audio-output-selector
        span Test your speaker. Do you hear the music? (make sure volume is turned up)
        SelectBox.select-box(
          displayExpr="label",
          placeholder="...Speaker Devices Loading",
          :value="outputDeviceId",
          valueExpr="deviceId",
          :dataSource="outputDevices",
          dataName="speaker-selector",
          width="500px",
          @valueChanged="onSpeakerDeviceChange"
        )
    .status-container 
      Status
    .description(v-if="isActiveFailed && isLoaded")
      strong You won't be able to join the session without speakers or headphones.
      BaseTip(:tip-message="htmlTip")
    .confirmation-buttons
      template
        Button(text="No", primary, small, dataName="camera-button-no", @click="failActive(message)")
        Button(text="Yes, Next", primary, small, dataName="camera-button-yes", @click="passActive")
</template>
<style scoped lang="scss"></style>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseVonageStep from "@/components/SystemCheck/steps/BaseVonageStep.vue";
import OT from "@opentok/client";
import { LocalStorageFields } from "@/types/base";
import { Icon } from "@cruciallearning/puddle/components";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";
import { BaseButton as Button } from "@cruciallearning/puddle";

@Component({
  components: { BaseTip, Icon, Button },
})
export default class Speaker extends BaseVonageStep {
  label = { visible: true, position: "top" };
  tooltip = { enabled: true, showMode: "always", position: "bottom" };
  private volume = 50;
  private audio = new Audio();
  private outputDeviceId: string | null = null;
  protected outputDevices: OT.AudioOutputDevice[] = [];
  private isLoaded = false;

  async mounted(): Promise<void> {
    setTimeout(() => this.process(), 500);
  }

  async process(): Promise<void> {
    this.audio = new Audio(await require(`@/assets/audio/apron-time.mp3`));
    this.outputDevices = await this.populateOutputDevices();
    if (this.outputDevices) {
      this.outputDeviceId = "default";
      const storedDevice = localStorage.getItem(LocalStorageFields.SPEAKER_ID);
      if (storedDevice) {
        this.outputDeviceId = storedDevice;
      }
    } else {
      this.failActive(this.message);
    }
    this.isLoaded = true;
  }
  async onSpeakerDeviceChange(selectedItem: { deviceId: string }): Promise<void> {
    await OT.setAudioOutputDevice(selectedItem.deviceId);
    this.outputDeviceId = selectedItem.deviceId;
    this.setRootState({ selectedSpeakerId: selectedItem.deviceId });
    localStorage.setItem(LocalStorageFields.SPEAKER_ID, selectedItem.deviceId);
  }
  testSpeaker(): void {
    // volume must be between 0 & 1 so divide by 100 to get the %
    this.audio.volume = this.volume / 100;
    this.audio.play();
  }
  volumeChanged(e: { value: number }): void {
    this.volume = e.value;
  }
  volumeIconClick(iconName: string): void {
    if (iconName == "volume-1") this.volume = 0;
    else if (iconName == "volume-2") this.volume = 100;
  }
  async populateOutputDevices(): Promise<OT.AudioOutputDevice[]> {
    return await OT.getAudioOutputDevices();
  }
  get passFail(): string {
    return this.isActiveFailed ? "Fail" : "Pass";
  }
  get passFailColor(): string {
    return this.isActiveFailed ? "red" : "green";
  }
  get htmlMessage(): string {
    return `We are unable to detect audio output like a speaker or headset.`;
  }
  get htmlTip(): string {
    return `Be sure you have a speaker or headset connected.`;
  }
  get message(): string {
    return this.failedMessage([this.htmlMessage, this.htmlTip]);
  }
}
</script>
<style lang="css" scoped>
::v-deep .dx-slider-handle {
  border-radius: 100%;
  height: 15px;
  width: 15px;
}
.volume-slider {
  display: flex;
  align-items: baseline;
  padding-top: 50px;
  padding-left: 20px;
  align-self: center;
}
.slider {
  width: 55%;
  padding-left: 10px;
}
.text {
  padding-right: 30px;
  align-items: center;
}
.low-volume {
  align-self: center;
  padding-left: 20px;
}
.high-volume {
  padding-left: 10px;
  align-self: center;
}
.select-box {
  width: 60%;
  display: inline-block;
}
.button {
  display: inline-block;
}
.system-check-card-contents {
  margin-top: 40px;
}
.system-check-description {
  align-items: center;
  display: flex;
}
.description {
  padding-left: 18px;
  font-size: 0.85rem;
}
.pass-fail {
  padding-left: 20px;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Flama, serif;
}
.status-container {
  margin: 1rem 0;
}
.audio-output-selector {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}
.confirmation-buttons {
  margin-top: 3rem !important;
}
</style>
