import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { TimerMuteModel, TimerPayload, TimerSoundModel } from "@/types/timer";

const timer = {
  start(seconds: number, direction: string): void {
    socket.send({ type: routes.TIMER_START, seconds, direction });
  },
  stop(): void {
    socket.send({ type: routes.TIMER_STOP });
  },
  pause(): void {
    socket.send({ type: routes.TIMER_PAUSE });
  },
  resume(): void {
    socket.send({ type: routes.TIMER_RESUME });
  },
  mute(mute: boolean): void {
    socket.send({ type: routes.TIMER_MUTE, mute });
  },
  setSound(sound: string): void {
    socket.send({ type: routes.TIMER_SOUND, sound });
  },
  receive(payload: TimerPayload): void {
    switch (payload.type) {
      case routes.TIMER_START:
        store.dispatch("TimerModule/runTimer", payload.body);
        break;
      case routes.TIMER_PAUSE:
        store.dispatch("TimerModule/pauseTimer", payload.body);
        break;
      case routes.TIMER_RESUME:
        store.dispatch("TimerModule/resumeTimer", payload.body);
        break;
      case routes.TIMER_STOP:
        store.dispatch("TimerModule/stopTimer", payload.body);
        break;
      case routes.TIMER_MUTE:
        store.commit("TimerModule/setTimerState", { muted: (payload.body as TimerMuteModel).mute });
        break;
      case routes.TIMER_SOUND:
        store.commit("TimerModule/setTimerState", { sound: (payload.body as TimerSoundModel).sound });
        break;
      default:
        console.log("Invalid timer message");
        break;
    }
  },
};
export default timer;
