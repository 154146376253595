import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { CreatePollPayload, PollAnswerPayload, PollPayload, PollResultsPayload } from "@/types";

const poll = {
  create(slideId: string): void {
    socket.send({ type: routes.POLL_CREATE, slideId });
  },
  close(slideId: string): void {
    socket.send({ type: routes.POLL_CLOSE, slideId });
  },
  answer(slideId: string, answers: string[]): void {
    socket.send({ type: routes.POLL_ANSWER, slideId, answers });
  },
  status(slideId: string): void {
    socket.send({ type: routes.POLL_STATUS, slideId });
  },
  receive(payload: PollPayload): void {
    switch (payload.type) {
      case routes.POLL_CREATE:
        store.dispatch("PollModule/onCreatePoll", payload.body as CreatePollPayload);
        break;
      case routes.POLL_CLOSE:
        store.dispatch("PollModule/onClosePoll", payload.body as PollResultsPayload);
        break;
      case routes.POLL_ANSWER:
        store.dispatch("PollModule/onAnswerPoll", payload.body as PollAnswerPayload);
        break;
      case routes.POLL_STATUS:
        store.dispatch("PollModule/onPollStatus", payload.body as PollResultsPayload);
        break;
      default:
        console.log("Invalid poll message");
        break;
    }
  },
};
export default poll;
