<template lang="pug">
.cell-1(:class="[gridClass]", @mouseup="onMouseUp", @mouseover="applyRows(idx)")
</template>
<script lang="ts">
import { ResizingOption, SetGridState } from "@/store/modules/Grid/models";
import { LocalStorageFields } from "@/types/base";
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
@Component({
  computed: {
    ...mapState("GridModule", ["adminCols", "chatCols", "usersCols", "resizingOption"]),
    ...mapGetters("GridModule", ["validAdminIndex", "validUsersIndex", "validChatIndex", "chatStart"]),
  },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
  },
})
export default class ResizeColumn extends Vue {
  @Prop({ required: true }) idx!: number;
  private readonly adminCols!: number;
  private readonly chatCols!: number;
  private readonly usersCols!: number;
  private readonly resizingOption!: ResizingOption;
  private readonly chatStart!: number;

  private readonly validAdminIndex!: (idx: number) => boolean;
  private readonly validUsersIndex!: (idx: number) => boolean;
  private readonly validChatIndex!: (idx: number) => boolean;

  private readonly setGridState!: SetGridState;

  get gridClass(): string {
    switch (this.resizingOption) {
      case ResizingOption.ADMIN:
        if (this.validAdminIndex(this.idx)) {
          return "allowed-option";
        } else {
          return "disallowed-option";
        }
      case ResizingOption.USERS:
        if (this.validUsersIndex(this.idx)) {
          return "allowed-option";
        } else {
          return "disallowed-option";
        }
      case ResizingOption.CHAT:
        if (this.validChatIndex(this.idx)) {
          return "allowed-option";
        } else {
          return "disallowed-option";
        }
      default:
        return "disallowed-option";
    }
  }

  onMouseUp(): void {
    this.$emit("clearResize");
  }
  applyRows(col: number): void {
    if (this.resizingOption === ResizingOption.ADMIN && this.validAdminIndex(this.idx)) {
      this.setGridState({ adminCols: col });
      localStorage.setItem(LocalStorageFields.ADMIN_COLS, `${col}`);
    }
    if (this.resizingOption === ResizingOption.CHAT && this.validChatIndex(this.idx)) {
      const cols = 12 - col;
      if (cols >= 2) {
        this.setGridState({ chatCols: cols });
        localStorage.setItem(LocalStorageFields.CHAT_COLS, `${cols}`);
      }
    }
    if (this.resizingOption === ResizingOption.USERS && this.validUsersIndex(this.idx)) {
      const cols = this.chatStart - col + 1;
      if (cols >= 2) {
        this.setGridState({ usersCols: cols });
        localStorage.setItem(LocalStorageFields.USERS_COLS, `${cols}`);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.disallowed-option {
  width: 100%;
}
.allowed-option {
  background: rgba(42, 255, 42, 0.05);
  border: 2px solid rgba(32, 202, 32, 0.2);
  border-top: none;
  border-bottom: none;
  width: 100%;
  column-gap: 0px;
}
</style>
