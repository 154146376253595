import MessageEncoder from "./MessageEncoder";

const chatUtil = {
  formatMessage(input: string | undefined): string {
    if (!input) return "";
    const links: string[] = [];
    let linkReg = /<linkItem>.+?<\/linkItem>/g;
    input.match(linkReg)?.forEach((item, idx) => {
      input = input?.replace(item, `#linkItem${idx}#`);
      links.push(item);
    });
    input = MessageEncoder.encode(input);
    if (links.length > 0) {
      linkReg = /#linkItem[0-9]*#/g;
      input.match(linkReg)?.forEach((item, idx) => {
        const url = links[idx].replaceAll("<linkItem>", "").replaceAll("</linkItem>", "");
        input = input?.replace(item, `<a href="${url}" target="_blank">${url}</a>`);
      });
    }
    return input;
  },
};
export default chatUtil;
