<template lang="pug">
.trainer-attendee-menu
  ul.list-unstyled.menu
    template(v-if="user.waiting")
      li(data-name="admit-participant", @click="onAdmitLearner") Admit Learner
    template(v-else)
      template(v-if="!user.self")
        template(v-if="$auth.ADMIN")
          li(data-name="clear-participant-status", v-if="hasStatus", @click="onClearParticipantStatus") Clear Status
          li(data-name="message-participant", @click="onMessageParticipant") Message Learner
          li(v-if="streamEnabled", data-name="mute-participant", @click="onMuteParticipant") Mute Learner
          li(v-if="hasVideo", data-name="stop-participant-video", @click="onTurnOffParticipantVideo") Turn Off Video
          li(data-name="remove-participant", @click.prevent="onRemoveParticipant") Remove Learner
        Popup(
          minHeight="325px",
          width="400px",
          :cancel="isRemoving ? '' : 'Cancel'",
          :cancelDisabled="isRemoving",
          :closeOnOkClick="false",
          :ok="isRemoving ? 'Removing' : 'Remove'",
          :okDisabled="isRemoving",
          :okLoading="isRemoving",
          :visible="isRemovePopupVisible",
          @hiding="isRemovePopupVisible = false",
          @ok="onRemoveOkClick"
        )
          div(data-name="remove-participant-message")
            p You are about to remove the following learner from the session:
            h6.name {{ user.firstName }} {{ user.lastName }}
</template>

<script lang="ts">
import {
  AdmitToClassroom,
  ClearReaction,
  MediaUpdateType,
  MessageParticipant,
  Mute,
  RemoveFromClassroom,
  Shutter,
  UserModel,
} from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import { BasePopup as Popup } from "@cruciallearning/puddle";
import { ReactionUserModel } from "@/types/reaction";
import { ReactionType } from "@/types/status";
import VonageUtil from "@/components/Vonage/vonageUtil";

@Component({
  components: { Popup },
  computed: {
    ...mapState("UsersModule", ["statuses"]),
    ...mapState("VonageModule", ["subscribers"]),
    ...mapGetters("EventModule", ["streamEnabled"]),
  },
  methods: {
    ...mapActions(["removeFromClassroom"]),
    ...mapActions("ChatModule", ["messageParticipant"]),
    ...mapActions(["admitToClassroom"]),
    ...mapActions("UsersModule", ["mute", "shutter", "clearReaction"]),
  },
})
export default class AttendeeMenu extends Vue {
  @Prop({ required: true }) readonly user!: UserModel;

  private readonly statuses!: ReactionUserModel[];
  private readonly messageParticipant!: MessageParticipant;
  private readonly removeFromClassroom!: RemoveFromClassroom;
  private readonly admitToClassroom!: AdmitToClassroom;
  private readonly mute!: Mute;
  private readonly shutter!: Shutter;
  private readonly clearReaction!: ClearReaction;

  private readonly subscribers!: OT.Subscriber[];

  private isRemovePopupVisible = false;
  private isRemoving = false;

  get hasStatus(): boolean {
    const reactions = this.statuses.find(
      (e) => e.securityId === this.user.securityId && e.status != ReactionType.STATUS && e.status != ReactionType.CLEAR
    );
    return reactions != undefined;
  }
  get hasVideo(): boolean {
    const user = this.subscribers.find((e) => VonageUtil.getSecurityId(e.stream) === this.user.securityId);
    return user?.stream?.hasVideo || false;
  }
  onAdmitLearner() {
    this.admitToClassroom(this.user.securityId);
    this.$emit("hiding");
  }

  onMessageParticipant(): void {
    this.messageParticipant(this.user.securityId);
    this.$emit("hiding");
  }

  onClearParticipantStatus(): void {
    this.clearReaction(this.user.securityId);
    this.$emit("hiding");
  }

  onRemoveParticipant(): void {
    this.isRemovePopupVisible = true;
    this.$emit("hiding");
  }
  onRemoveOkClick(): void {
    this.isRemoving = true;
    this.removeFromClassroom(this.user.securityId);
    this.isRemoving = false;
    this.isRemovePopupVisible = false;
  }
  onMuteParticipant(): void {
    this.mute({ type: MediaUpdateType.USER, securityId: this.user.securityId });
    this.$emit("hiding");
  }
  onTurnOffParticipantVideo(): void {
    this.shutter({ type: MediaUpdateType.USER, securityId: this.user.securityId });
    this.$emit("hiding");
  }
}
</script>

<style lang="css" scoped>
.name {
  font-family: Flama;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}
.menu {
  background-color: var(--gray-80);
  text-align: left;
}
</style>
