<template lang="pug">
.presentation-container.dark-mode
  .presentation.layout-grid
    .cell-12.dark.layout-grid
      .waiting-room
        .waiting-room-user-settings
          p.subtitle.title-3 {{ myName }},
          .flex.flex-justify-center.pt-8.pb-8
            div
              p.subtitle.pt-8.pb-8 You have signed in to an event for which you are not currently registered.
              p.subtitle.pt-8.pb-8 We apologize for the inconvenience.
              p.subtitle.pt-8.pb-16 Please click below to register for this event.
              .button.full(@click="onRedeemCode") Redeem Event Code
</template>

<script lang="ts">
import { Vue } from "vue-property-decorator";
import config from "@/config";
import authorization from "@/auth/authorization";

export default class AppUnauthorized extends Vue {
  onRedeemCode(): void {
    const url = config.vaporUrl;
    console.log("Redirecting browser to plaform: " + url);
    window.location.assign(url);
  }

  get myName(): string | undefined {
    return authorization.myName;
  }
}
</script>
