<template lang="pug">
.error-display-container(v-if="hasError")
  .error-display-close(@mousedown="onClose")
    Icon(value="x", height="16px", width="16px")
  .error-icon
    Icon(value="alert-triangle", color="var(--error-50)", height="24px", width="24px")
  .error-display-content
    .error-header
      span {{ header }}
    .error-body 
      span {{ body }}
</template>
<script lang="ts">
import { SetErrorState } from "@/types/error";
import { Icon } from "@cruciallearning/puddle/components";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
@Component({
  components: {
    Icon,
  },
  computed: {
    ...mapState("ErrorModule", ["header", "body"]),
  },
  methods: {
    ...mapMutations("ErrorModule", ["setErrorState"]),
  },
})
export default class ErrorDisplay extends Vue {
  private readonly body!: string;
  private readonly setErrorState!: SetErrorState;
  get hasError(): boolean {
    return !!this.body;
  }
  onClose(): void {
    this.setErrorState({
      header: "",
      body: "",
    });
  }
}
</script>
<style lang="scss" scoped>
.error-display-container {
  display: flex;
  width: 580px;
  flex-shrink: 0;
  padding: 16px 8px;
  border-radius: 3px;
  background: var(--white, #fff);
  border-radius: 3px;
  border-left: var(--error-50, #ed1b2f) solid 4px;
  margin-bottom: 8px;

  .error-display-close {
    position: absolute;
    right: 8px;
    top: 8px;
    ::v-deep {
      svg {
        path {
          color: var(--gray-80);
        }
      }
      :hover {
        path {
          color: var(--gray-40);
        }
      }
    }
  }

  .error-icon {
    margin: 0 16px;
  }

  .error-display-content {
    span {
      font-size: 16px;
      font-style: normal;
      line-height: 140%;
    }
    .error-header {
      span {
        color: var(--gray-80, #313131);
        font-weight: 700;
      }
    }
    .error-body {
      width: 476px;
      span {
        color: #000;
      }
    }
  }
}
</style>
