<template lang="pug">
.timer-overlay-container(v-show="showTimer", :class="timerClass")
  .timer-overlay
    Icon(value="Timer", color="white")
    span {{ timeString }}
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import { ActiveTimer } from "@/types/timer";
import { getTimerStr } from "@cruciallearning/puddle/utils";
import { Icon } from "@cruciallearning/puddle/components";
import Authorization from "@/auth/authorization";

@Component({
  components: { Icon },
  computed: {
    ...mapState("TimerModule", ["active", "completed", "sound", "muted"]),
  },
})
export default class TimerOverlay extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) fixed!: boolean;
  @Prop({ required: false }) displayOverride?: string;
  private readonly active!: ActiveTimer;
  private readonly completed!: boolean;
  private readonly sound!: string;
  private readonly muted!: boolean;

  get showTimer(): boolean {
    return !!this.active || !!this.displayOverride;
  }

  get timeString(): string {
    if (this.displayOverride) return this.displayOverride;
    if (this.active != null) {
      return getTimerStr(Math.round(this.active.time / 1000));
    }
    return "0:00";
  }

  get timerClass(): string {
    if (this.fixed) return "fixed";
    return "scaled";
  }

  @Watch("completed")
  async onTimerTick(): Promise<void> {
    if (this.completed) {
      const audio = new Audio(await require(`@/assets/audio/${this.sound}.mp3`));
      audio.volume = 0.5;
      if (!this.muted && !Authorization.mustMuteTimer()) {
        audio.play();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timer-overlay-container {
  position: absolute;
  top: 0;
  right: 0;
  &.fixed {
    span:nth-child(2) {
      width: 100px;
      display: inline-block;
      font-size: 30px;
      text-align: center;
    }
    ::v-deep {
      span {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
  &.scaled {
    width: 15%;
    height: 10%;
    span:nth-child(2) {
      width: 70%;
      font-size: 2vw;
      text-align: center;
      float: right;
      height: 100%;
    }
    ::v-deep {
      span:nth-child(1) {
        margin: unset;
        height: fit-content;
        width: fit-content;
        display: flex;
        svg {
          height: 2vw;
          width: 2vw;
        }
      }
    }
  }
  .timer-overlay {
    display: flex;
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0.7);
    margin: 5px;
    border: 2px white solid;
    border-radius: 10px;
    padding: 10px;
    ::v-deep {
      span {
        color: white;
        svg {
          vertical-align: bottom;
        }
      }
    }
  }
}
</style>
