<template lang="pug"></template>
<script lang="ts">
import BaseStep from "./BaseStep.vue";
import { Component, Watch } from "vue-property-decorator";
import { BaseSelectBox as SelectBox } from "@cruciallearning/puddle";
import { mapActions, mapMutations, mapState } from "vuex";
import Session from "@/components/Vonage/Session.vue";
import { SetRootState, StreamDeviceModel } from "@/types";
import VonageUtil from "@/components/Vonage/vonageUtil";
import OT from "@opentok/client";
import { DxSlider } from "devextreme-vue/slider";
import { RefreshPublisherSettings } from "@/types/vonage";

@Component({
  components: { Session, SelectBox, DxSlider },
  computed: {
    ...mapState(["streamToken"]),
    ...mapState("VonageModule", ["publisher"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
    ...mapActions("VonageModule", ["refreshPublisherSettings"]),
  },
})
export default class BaseVonageStep extends BaseStep {
  protected readonly setRootState!: SetRootState;
  protected readonly refreshPublisherSettings!: RefreshPublisherSettings;
  protected loading = true;
  protected devicesSet = false;
  protected readonly publisher!: OT.Publisher | null;
  protected devices: Array<StreamDeviceModel> = [];

  protected deviceId = "";

  protected sessionRef = "session";

  get selectedDevice(): string {
    if (this.devices.length > 0) {
      return this.deviceId || this.devices[0]?.deviceId || "default";
    }
    return "";
  }
  get deviceWarning(): boolean {
    return this.devicesSet && this.devices.length == 0;
  }

  async populatedDevices(type: "videoInput" | "audioInput"): Promise<void> {
    const retDevices = await VonageUtil.getDevices(type);
    this.devices = retDevices.filter((e) => !!e.deviceId);
    if (this.devices.length == 0) {
      this.failActive(this.message);
      this.loading = false;
    }
    this.devicesSet = true;
  }
  get message(): string {
    return this.failedMessage(["Unable to detect input device. Check that your input device is connected."]);
  }
  @Watch("streamToken")
  streamReady() {
    if (this.$refs.session && !this.deviceWarning && this.$refs.session) (this.$refs.session as Session).init();
  }
}
</script>
