<template lang="pug">
.resize-drag(v-if="resizeEnabled", @mousedown="resizeStart", @mouseup="resizeEnd")
  .resize-center
    .resize-marker
    .resize-marker
</template>
<script lang="ts">
import { ResizingOption, SetGridState } from "@/store/modules/Grid/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
@Component({
  computed: {
    ...mapGetters("EventModule", ["resizeEnabled"]),
  },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
  },
})
export default class GridResizeBar extends Vue {
  @Prop({ required: true }) column!: ResizingOption;
  private readonly setGridState!: SetGridState;

  resizeStart(): void {
    this.setGridState({ resizingOption: this.column });
  }

  resizeEnd(): void {
    this.setGridState({ resizingOption: ResizingOption.NONE });
  }
}
</script>
<style lang="scss" scoped>
.resize-drag {
  height: 100%;
  width: 0.5rem;
  background-color: var(--gray-95);
}
.resize-center {
  height: 100%;
  display: flex;
}
.resize-drag:hover {
  background-color: var(--gray-90);
  cursor: ew-resize;
}
.resize-marker {
  border-radius: 10px;
  height: 2rem;
  width: 25%;
  margin: auto;
  background-color: var(--gray-30);
}
</style>
