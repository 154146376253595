<template lang="pug">
.attendee-group(v-show="users.length > 0")
  .group-header(@click="showUsers = !showUsers")
    p(:data-name="`${dataName}-header`") {{ title }} ({{ users.length }})
    i(:class="showUsers ? `icon-chevron-down` : `icon-chevron-up`", :data-name="`${dataName}-icon`")
  .group-content(v-show="showUsers")
    AttendeeVideos(:videoElementId="videoElementId", :users="users", @clickMenu="$emit('clickMenu', $event)")
    ul.attendee-list
      Attendee(
        v-for="(user, index) in sortedUsers",
        :key="index",
        :dataName="dataName",
        :index="index",
        :user="user",
        @clickMenu="$emit('clickMenu', $event)"
      )
</template>

<script lang="ts">
import { UserModel } from "@/types";
import { ReactionUserModel } from "@/types/reaction";
import { ReactionType } from "@/types/status";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import Attendee from "./Attendee.vue";
import AttendeeVideos from "./AttendeeVideos.vue";

@Component({
  components: { Attendee, AttendeeVideos },
  computed: {
    ...mapState("UsersModule", ["statuses"]),
  },
})
export default class AttendeeList extends Vue {
  @Prop({ required: true }) readonly dataName!: string;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly users!: UserModel[];
  @Prop({ required: true }) videoElementId!: string;

  private readonly statuses!: ReactionUserModel[];

  private showUsers = true;
  get sortedUsers(): UserModel[] {
    const priority: UserModel[] = [];
    const standard: UserModel[] = [];
    this.users.forEach((user) => {
      const handUp = this.statuses.find(
        (e) => e.securityId === user.securityId && e.status === ReactionType.RAISE_HAND
      );
      if (handUp) priority.push(user);
      else standard.push(user);
    });
    standard.sort((a: UserModel, b: UserModel) => {
      const res = a.firstName.localeCompare(b.firstName);
      return res !== 0 ? res : a.lastName.localeCompare(b.lastName);
    });
    priority.sort((a: UserModel, b: UserModel) => {
      const idxA = this.statuses.findIndex((e) => e.securityId === a.securityId);
      const idxB = this.statuses.findIndex((e) => e.securityId === b.securityId);
      return idxA - idxB;
    });
    priority.push(...standard);
    return priority;
  }
}
</script>
