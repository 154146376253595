/*
 * ------------------------------------------------------------------------------
 * Enums
 * ------------------------------------------------------------------------------
 */

export enum ChatType {
  MAIN = "main",
  BACKSTAGE = "backstage",
  GROUP = "group",
  LOBBY = "lobby",
}

/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */
export interface ChatState {
  activeId: string;
  activeTyper: string;
  activeTyperTimeout?: NodeJS.Timeout;
  editMessage: string;
  editBackstageMessage: string;
  backstage: ChatModel[];
  backstageTyper: string;
  backstageTyperTimeout?: NodeJS.Timeout;
  backstageIsNew: boolean;
  group: ChatModel[];
  groupList: ChatGroupModel[];
  hiddenList: string[];
  main: ChatModel[];
  lobby: ChatModel[];
  status: ChatStatusModel[];
  pinned: PinnedChatModel[];
  emojis: Array<string>;
  pendingNew: string[];
}
export interface ChatModel {
  id: string;
  groupId?: string;
  groupName?: string;
  firstName: string;
  image?: string;
  lastName: string;
  message: string;
  self: boolean;
  sent?: Date;
  time: string;
  type: ChatType;
  sessionId?: string;
  senderId?: string;
  content?: string;
}

export interface PinnedChatModel extends ChatModel {
  sourceId: string;
}

export interface ChatLogMessageModel {
  firstName: string;
  lastName: string;
  content: string;
  sent: string;
  sessionId: string;
}
export interface ChatGroupModel {
  breakout?: boolean;
  groupId: string;
  groupName: string;
  participants?: ChatGroupParticipant[];
  messages?: ChatModel[];
  isNew?: boolean;
  inGroup?: boolean;
}
export interface ChatDeleteModel {
  messageId: string;
}
export interface ChatGroupParticipant {
  id: string;
  firstName: string;
  lastName: string;
}
export interface ChatPayload {
  type: string;
  body: Partial<ChatModel> | Partial<PinnedChatModel> | ChatGroupModel | ChatDeleteModel | ChatIdModel | ChatLogPayload;
}
export interface ChatLogPayload {
  type: string;
  messages?: ChatLogMessageModel[];
}
export interface ChatStatusModel {
  type: ChatType;
  text: string;
}

export interface ChatIdModel {
  messageId: string;
}
/*
 * ------------------------------------------------------------------------------
 * Functions
 * ------------------------------------------------------------------------------
 */
export interface AddOrUpdateStatus {
  (text: string): void;
}
export interface DeleteMessage {
  (payload: { type: ChatType; messageId: string }): void;
}
export interface PinMessage {
  (payload: { type: ChatType; messageId: string }): void;
}
export interface UnpinMessage {
  (payload: { type: ChatType; messageId: string }): void;
}

export interface GetChatByType {
  (type: ChatType): ChatModel[];
}
export interface GetGroupExists {
  (groupId: string): boolean;
}
export interface GetIsBackstageNew {
  (): boolean;
}
export interface GetIsNew {
  (): boolean;
}
export interface GetStatusByType {
  (type: ChatType): ChatStatusModel;
}
export interface GroupAdd {
  (payload: { groupId: string; participantIds: string[] }): void;
}
export interface GroupRemove extends GroupStart {
  (payload: { groupName: string; participantIds: string[] }): void;
}
export interface GroupRemoveAll {
  (): void;
}

export interface GroupHide {
  (payload: { groupId: string }): void;
}
export interface GroupShow {
  (payload: { groupId: string }): void;
}
export interface GroupStart {
  (payload: { groupName: string; participantIds: Array<string> }): void;
}
export interface MessageParticipant {
  (securityId: string): void;
}
export interface GroupStop {
  (groupId: string): void;
}
export interface ResetIsBackstageNew {
  (): void;
}
export interface SetActiveId {
  (id: string): void;
}
export interface SetMessage {
  (message: ChatModel): void;
}
export interface SetStatus {
  (status: ChatStatusModel): void;
}
export interface SendMessage {
  (payload: { type: ChatType; text: string; groupId?: string }): void;
}

export interface SetEditMessage {
  (editMessage: string): void;
}
export interface SetEditBackstageMessage {
  (editBackstageMessage: string): void;
}
export interface SetGroup {
  (group: ChatGroupModel): void;
}

export interface SetChatState {
  (changes: Partial<ChatState>): void;
}
