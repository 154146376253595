<template lang="pug">
.content-pod
  #content(:style="cursorStyle", @click="$emit('click')")
    LearnerGuidePage(fixed)
    TimerOverlay(fixed)
    img#content-image.img-content(:src="imgUrl")
    canvas#annotation-canvas(
      v-if="annotatable",
      @mousemove="annotate",
      @mousedown="beginAnnotation",
      @mouseup="stopAnnotation",
      @mouseleave="stopAnnotation"
    )
</template>

<script lang="ts">
import {
  ClaimAnnotatorStatus,
  Point,
  SendClear,
  SendLineBreak,
  SendPoint,
  SetAnnotationState,
  WaiveAnnotatorStatus,
} from "@/types/Annotation";
import { debounce } from "@cruciallearning/puddle/utils";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter";
import { Content } from "@/types/course";
import LearnerGuidePage from "../Overlay/LearnerGuidePage.vue";
import TimerOverlay from "../Overlay/TimerOverlay.vue";
@Component({
  components: { LearnerGuidePage, TimerOverlay },
  computed: {
    ...mapState("CourseModule", ["showModel", "showAltModel"]),
    ...mapGetters("CourseModule", ["getModelUrl", "getAltModelUrl"]),
    ...mapState("GridModule", ["rightSidebarCols", "leftSidebarCols"]),
    ...mapState("AnnotationsModule", ["point", "lineBreak", "clear", "annotatorId", "color"]),
  },
  methods: {
    ...mapActions("AnnotationsModule", [
      "sendPoint",
      "sendClear",
      "sendLineBreak",
      "claimAnnotatorStatus",
      "waiveAnnotatorStatus",
    ]),
    ...mapMutations("AnnotationsModule", ["setAnnotationState"]),
  },
})
export default class BaseContent extends Vue {
  @Prop({ required: true }) readonly content!: Content;
  @Prop({ required: false, default: true }) readonly annotatable!: boolean;

  private sendPoint!: SendPoint;
  private sendLineBreak!: SendLineBreak;
  private sendClear!: SendClear;
  private readonly showModel!: boolean;
  private readonly showAltModel!: boolean;
  private waiveAnnotatorStatus!: WaiveAnnotatorStatus;
  private claimAnnotatorStatus!: ClaimAnnotatorStatus;
  private setAnnotationState!: SetAnnotationState;
  private getModelUrl!: string;
  private getAltModelUrl!: string;
  private annotatorId!: string;
  private canvas: CanvasRenderingContext2D | null = null;
  private canvasElement: HTMLCanvasElement | null = null;
  private annotating = false;
  private drawing = false;
  private cursorStyle = "";
  private x = -1;
  private y = -1;
  private frameCount = 0;
  private initialLoad = false;
  private color!: string;

  get imgUrl(): string {
    if (this.showModel) return this.getModelUrl;
    else if (this.showAltModel) return this.getAltModelUrl;
    else return this.content.image.url;
  }

  mounted(): void {
    const el = document.getElementById("annotation-canvas");
    const canvasElement = el as HTMLCanvasElement;
    this.canvasElement = canvasElement;
    if (canvasElement != null) {
      this.canvas = canvasElement.getContext("2d");
    }
    window.addEventListener("resize", this.windowResize);
    if (this.annotatorId === this.$auth.authUser.id && this.annotatable) {
      this.initDraw();
      this.cursorStyle = "cursor: crosshair;";
      this.annotating = true;
    }
    this.addGlobalListeners();
  }
  beforeDestroy(): void {
    this.removeGlobalListeners();
  }
  unmounted(): void {
    window.removeEventListener("resize", this.windowResize);
  }

  addGlobalListeners(): void {
    GlobalEventEmitter.$on("initDraw", () => {
      this.initDraw();
      this.cursorStyle = "cursor: crosshair;";
      this.annotating = true;
    });
    GlobalEventEmitter.$on("waiveAnnotator", () => {
      this.cursorStyle = "";
      this.annotating = false;
    });
    GlobalEventEmitter.$on("annotationClear", () => this.sendClear());
  }

  removeGlobalListeners(): void {
    GlobalEventEmitter.$off("initDraw");
    GlobalEventEmitter.$off("annotationClear");
    GlobalEventEmitter.$off("waiveAnnotator");
  }

  windowResize(): void {
    if (this.canvasElement) {
      this.canvasElement.removeAttribute("width");
      this.canvasElement.removeAttribute("height");
    }
    if (this.annotatorId === this.$auth.authUser.id) {
      this.sendClear();
      debounce(() => {
        this.initDraw();
      }, 250)();
    }
  }

  annotate(event: MouseEvent): void {
    if (this.drawing && this.$auth.ADMIN && this.annotating) {
      if (++this.frameCount % 3 == 0) {
        if (this.x != -1 || this.y != -1) {
          this.drawLine(this.x, this.y, event.offsetX, event.offsetY);
        }
        this.x = event.offsetX;
        this.y = event.offsetY;
        this.sendRatioCoordinates(event);
      }
    }
  }

  beginAnnotation(event: MouseEvent): void {
    if (event.button == 0 && this.$auth.ADMIN) {
      this.drawing = true;
    }
  }

  stopAnnotation(): void {
    if (this.annotating && this.drawing && this.$auth.ADMIN) {
      this.sendLineBreak();
      this.drawing = false;
    }
  }
  sendRatioCoordinates(event: MouseEvent) {
    if (this.$auth.ADMIN && this.annotating) {
      const rect = this.canvasElement?.getBoundingClientRect();
      if (rect) {
        const xRatio = (event.clientX - rect.left) / rect.width;
        const yRatio = (event.clientY - rect.top) / rect.height;
        this.sendPoint({ xRatio, yRatio });
      }
    }
  }

  drawLine(x1: number, y1: number, x2: number, y2: number) {
    const ctx = this.canvas;
    if (ctx) {
      if (!this.initialLoad) {
        this.initialLoad = true;
        this.initDraw();
      }
      ctx.beginPath();
      ctx.strokeStyle = this.color;
      ctx.lineWidth = 5;
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
      ctx.closePath();
    }
  }

  initDraw(): void {
    const el = document.getElementById("content-image");
    const height = el?.clientHeight;
    const width = el?.clientWidth;
    const currentWidth = this.canvasElement?.getAttribute("width");
    const currentHeight = this.canvasElement?.getAttribute("height");

    if (this.canvasElement && height != currentHeight && width != currentWidth) {
      this.canvasElement.setAttribute("width", `${width}`);
      this.canvasElement.setAttribute("height", `${height}`);
    }
  }
  resetDraw(): void {
    const el = document.getElementById("content-image");
    const height = el?.clientHeight;
    const width = el?.clientWidth;
    if (height && width) this.canvas?.clearRect(0, 0, height * 100, width * 100);
  }

  @Watch("point")
  pointReceived(point: Point) {
    if (point.senderId === this.$auth.authUser.id) return;
    this.initDraw();
    const rect = this.canvasElement?.getBoundingClientRect();
    if (rect) {
      const x = point.xRatio * rect.width;
      const y = point.yRatio * rect.height;
      if (this.x != -1 && this.y != -1) {
        this.drawLine(this.x, this.y, x, y);
      }
      this.x = x;
      this.y = y;
    }
  }

  @Watch("lineBreak")
  lineBreakReceived(lineBreak: boolean) {
    if (lineBreak) {
      this.x = -1;
      this.y = -1;
      this.setAnnotationState({ lineBreak: false });
    }
  }

  @Watch("clear")
  clearReceived(clear: boolean) {
    if (clear) {
      this.resetDraw();
      this.setAnnotationState({ clear: false });
    }
  }

  @Watch("leftSidebarCols")
  leftPanelResize(): void {
    this.windowResize();
  }

  @Watch("rightSidebarCols")
  rightPanelResize(): void {
    this.windowResize();
  }
}
</script>

<style lang="scss" scoped>
img {
  width: auto;
  max-height: 100%;
}
.img-content {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}
#annotation-canvas {
  z-index: 10;
  position: absolute;
  grid-column: span 1;
  grid-row: span 1;
}
#content {
  display: flex;
  position: relative;
}
</style>
