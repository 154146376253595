export enum ReactionType {
  AWAY = "Away",
  LOVE = "Love",
  RAISE_HAND = "Raise Hand",
  THUMBS_UP = "Thumbs Up",
  CANCEL = "No",
  CHECK = "Yes",
  CLEAR = "Clear My Status",
  CLEAR_ALL = "Clear All Status",
  STATUS = "Status",
}
export const COFFEE_STATUS: StatusModel = {
  emoji: "☕ ",
  display: ReactionType.AWAY,
  cssClass: "reaction-away",
};
export const LOVE_STATUS: StatusModel = {
  emoji: "❤️ ",
  display: ReactionType.LOVE,
  cssClass: "reaction-love",
};
export const HAND_RAISE_STATUS: StatusModel = {
  emoji: "✋ ",
  display: ReactionType.RAISE_HAND,
  cssClass: "reaction-raise-hand",
};

export const THUMBS_UP_STATUS: StatusModel = {
  emoji: "👍 ",
  display: ReactionType.THUMBS_UP,
  cssClass: "reaction-thumbs-up",
};

export const X_STATUS: StatusModel = {
  emoji: "❌ ",
  display: ReactionType.CANCEL,
  cssClass: "reaction-x-status",
};

export const CHECK_STATUS: StatusModel = {
  emoji: "✅ ",
  display: ReactionType.CHECK,
  cssClass: "reaction-check",
};

export const CLEAR: StatusModel = {
  emoji: "",
  display: ReactionType.CLEAR,
  cssClass: "",
};

export const CLEAR_ALL: StatusModel = {
  emoji: "",
  display: ReactionType.CLEAR_ALL,
  cssClass: "",
};

export const STATUS: StatusModel = {
  emoji: "",
  display: ReactionType.STATUS,
  cssClass: "",
};

export const StatusOptions: StatusModel[] = [
  HAND_RAISE_STATUS,
  COFFEE_STATUS,
  LOVE_STATUS,
  THUMBS_UP_STATUS,
  X_STATUS,
  CHECK_STATUS,
];

export interface StatusModel {
  emoji: string;
  display: ReactionType;
  cssClass: string;
}
