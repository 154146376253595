<template lang="pug">
.modal-container(data-name="internet-instability-modal", v-if="showModal")
  .close-parent
    span
      .icon-x(data-name="close-instability-modal", @click="onClose")
  .modal-parent
    .text
      .icon-alert-triangle
      .header-text Your internet connection is unstable.
        .text-parent
          span.bold TIP:
          span {{ instabilityMessage }}
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import { BasePopup as Popup } from "@cruciallearning/puddle";
import { SetVonageState } from "@/types/vonage";

@Component({
  components: { Popup },
  computed: {
    ...mapState("VonageModule", ["publisher"]),
  },
  methods: {
    ...mapMutations("VonageModule", ["setVonageState"]),
  },
})
export default class PublisherInternetStability extends Vue {
  private publisher!: OT.Publisher | null;
  private videoPacketLossRatio: number | undefined;
  private audioPacketLossRatio: number | undefined;
  private lossesOverTime: number[] = [];
  private close = false;
  private fiveMinutes = 300000;
  private setVonageState!: SetVonageState;

  created(): void {
    this.combinedPacketLoss();
  }
  combinedPacketLoss(): void {
    let audioPacketLossPercent = 0;
    let videoPacketLossPercent = 0;
    let combinedLoss = 0;
    window.setInterval(() => {
      this.publisher?.getStats((error, stats) => {
        if (error) {
          console.log(`Error getting stream for instability message and packet loss calculation.`);
          return;
        }
        if (stats) {
          const videoStats = stats[0].stats.video;
          const audioStats = stats[0].stats.audio;
          this.videoPacketLossRatio = videoStats.packetsLost / videoStats.packetsSent;
          this.audioPacketLossRatio = audioStats.packetsLost / audioStats.packetsSent;
        }
      });
      if (this.videoPacketLossRatio) {
        videoPacketLossPercent = this.videoPacketLossRatio * 100;
      }
      if (this.audioPacketLossRatio) {
        audioPacketLossPercent = this.audioPacketLossRatio * 100;
      }
      combinedLoss = videoPacketLossPercent + audioPacketLossPercent;
      this.lossesOverTime = this.lossesOverTime.slice(this.lossesOverTime.length - 2, this.lossesOverTime.length);
      this.lossesOverTime.push(combinedLoss);
    }, 5000);
  }
  get userConnectivityIssues(): boolean {
    if (this.lossesOverTime.length < 3) return false;
    return this.lossesOverTime.filter((e) => e > 3).length == this.lossesOverTime.length;
  }
  get instabilityMessage(): string {
    return ` Quit all network applications that compete for bandwidth (VPN, games, video streaming, etc.).`;
  }
  onClose(): void {
    this.close = true;
    window.setInterval(() => {
      this.close = false;
    }, this.fiveMinutes);
  }
  get showModal(): boolean {
    return this.userConnectivityIssues && !this.close;
  }
}
</script>

<style scoped lang="scss">
.modal-container {
  display: flex;
  border-left: 3px solid red;
  background-color: white;
  border-radius: 3px;
  height: 100px;
  margin-bottom: 8px;
}
.text {
  color: black;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 18px;
}
.text-parent {
  text-align: left;
  padding-top: 10px;
  padding-right: 4px;
  font-size: 18px;
  font-weight: normal;
}
.modal-container-parent {
  margin: 0 auto;
  min-width: 2rem;
  max-width: 2rem;
  padding-top: 10px;
}
.icon-alert-triangle {
  color: red;
  width: 40px;
  height: 40px;
  scale: 115%;
}
.header-text {
  color: black;
  font-weight: bold;
}
.icon-x {
  float: right;
  position: absolute;
  padding-left: 625px;
}
.close-parent {
  display: flex;
  position: relative;
}
</style>
