<template lang="pug">
.loading-bar
  .loading-bar-message
    p {{ message }}
  ProgressBar(width="65", :completed="completed")
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import ProgressBar from "./ProgressBar.vue";
@Component({
  components: { ProgressBar },
  computed: {
    ...mapState("LoaderModule", ["completed", "message"]),
  },
})
export default class Loader extends Vue {}
</script>
<style lang="scss" scoped>
.loading-bar {
  position: absolute;
  background-color: var(--gray-80);
  bottom: 0;
  width: 100%;
  height: 5rem;
  align-content: center;
  align-items: center;
  text-align: center;
}
.loading-bar-message {
  margin: 0.5rem 0 0.5rem 0;
  color: var(--text-color);
}
</style>
