import store from "@/store";
import { RootState } from "@/types";
import { EventContentModel } from "@cruciallearning/puddle";
import { getInstance } from "@cruciallearning/puddle/auth";

const Authorization = {
  get mySecurityId(): string {
    return getInstance().authUser.id || "";
  },

  get myName(): string {
    const authUser = getInstance().authUser;
    return `${authUser.given_name} ${authUser.family_name}`;
  },

  /**
   * Determine if the current user is authorized to access the event in any capacity
   * @param event event to check
   * @return true if the user is associated with the event
   */
  isAuthorized(event: EventContentModel | null): boolean {
    let authorized = false;
    if (this.mySecurityId && event) {
      if (this.mySecurityId) {
        authorized =
          this.isRedeemedLearner(this.mySecurityId, event) ||
          this.isRegisteredLearner(this.mySecurityId, event) ||
          this.isManager(this.mySecurityId, event) ||
          this.isObserver(this.mySecurityId, event);
      }
    }
    console.log("authorization " + (authorized ? "succeeded" : "failed"));
    return authorized;
  },

  /**
   * Determine if the specified user has redeemed an event access code
   * @param securityId user id
   * @param event event
   * @return true if the user has redeemed an access code
   */
  isRedeemedLearner(securityId: string, event: EventContentModel): boolean {
    if (event.redemptionCodes) {
      const redemptionCodes = event.redemptionCodes.filter((code) => code.securityId == securityId);
      return redemptionCodes.length > 0;
    }
    return false;
  },

  /**
   * Determine if the specified user has registered for the event
   * @param securityId user id
   * @param event event
   * @return true if the user has registered for the event
   */
  isRegisteredLearner(securityId?: string, event?: EventContentModel): boolean {
    securityId = securityId || this.mySecurityId;
    if (event) {
      if (event.registrationCode && event.registrationCode.registrants) {
        const registrationCodes = event.registrationCode.registrants.filter((code) => code.securityId == securityId);
        return registrationCodes.length > 0;
      }
    }
    return false;
  },
  /**
   * Determine if the specified user is an assigned event trainer or moderator
   * @param securityId user id
   * @param event event
   * @return true if the user has been assigned as an event trainer
   */
  isTrainer(securityId?: string, event?: EventContentModel): boolean {
    securityId = securityId || this.mySecurityId;
    event = event || store.state.EventModule?.event;
    if (event && event.learningSessions) {
      const sessions = event.learningSessions.filter((session) => {
        const trainers = session.trainers.filter((e) => e.securityId === securityId);
        return trainers.length > 0;
      });
      return sessions.length > 0;
    }
    return false;
  },
  isModerator(securityId?: string, event?: EventContentModel): boolean {
    securityId = securityId || this.mySecurityId;
    event = event || store.state.EventModule?.event;
    if (event && event.learningSessions) {
      const sessions = event.learningSessions.filter((session) => {
        const moderators = session.moderators.filter((e) => e.securityId === securityId);
        return moderators.length > 0;
      });
      return sessions.length > 0;
    }
    return false;
  },
  isManager(securityId?: string, event?: EventContentModel): boolean {
    return this.isTrainer(securityId, event) || this.isModerator(securityId, event);
  },
  isObserver(securityId?: string, event?: EventContentModel): boolean {
    if (this.isRegisteredLearner(securityId, event)) return false;
    securityId = securityId || this.mySecurityId;
    event = event || store.state.EventModule?.event;
    if (event && event.observerIds) {
      return event.observerIds.findIndex((id) => securityId === id) != -1;
    }
    return false;
  },
  isApprovedObserver(): boolean {
    const observerIds = store.state.UsersModule?.observerIds;
    const securityId = this.mySecurityId;
    if (observerIds && securityId) {
      return observerIds.findIndex((id) => id === securityId) != -1;
    }
    return false;
  },
  isInPerson(): boolean {
    return (store.state as RootState).EventModule?.event.learningFormatType === "IN_PERSON";
  },
  mustMuteTimer(): boolean {
    return this.isInPerson() && this.isRegisteredLearner();
  },
  mustMuteVideo(): boolean {
    return (
      this.isInPerson() && (this.isRegisteredLearner() || !!(store.state as RootState).BrowserModule?.learnerWindow)
    );
  },
};

export default Authorization;
