export const emojis = [
  "🙂",
  "😀",
  "😁",
  "🤣",
  "😯",
  "😥",
  "😎",
  "😬",
  "🙄",
  "✋",
  "👏",
  "🙌",
  "🍕",
  "🌮",
  "🍦",
  "🎂",
  "🐶",
  "🐱",
  "☝️",
  "💯",
  "🌈",
  "☀️",
  "🦄",
  "🎉",
];
