const videoListUtil = {
  clearAllUserReactions(element?: HTMLElement): void {
    if (element) {
      element.classList.forEach((c: string) => {
        if (c.startsWith("reaction-")) {
          element.classList.remove(c);
        }
      });
      element?.classList.remove("reaction");
      this.removeOrderStyle(element);
    }
  },
  resetOrderStyle(order: number, element?: HTMLElement): void {
    if (element) {
      const style = element.getAttribute("style");
      if (style) {
        const match = style.includes(`order: ${order}`);
        if (!match) {
          this.removeOrderStyle(element);
          this.addOrderStyle(order, element);
        }
      }
    }
  },
  removeOrderStyle(element?: HTMLElement): void {
    if (element) {
      let style = element.getAttribute("style");
      if (style) {
        style = style.replaceAll(/\s*order:\s*-{0,1}[0-9]*;*/g, "");
        element.setAttribute("style", style);
      }
    }
  },
  addOrderStyle(order: number, element?: HTMLElement): void {
    if (element) {
      let style = element.getAttribute("style");
      style = style || "";
      style += `order: ${order};`;
      element.setAttribute("style", style);
    }
  },
};
export default videoListUtil;
