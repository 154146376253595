<script lang="ts">
import { FailIdx, PassIdx, TodoIdx } from "@/types/SystemCheck";
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import { BaseButton as Button } from "@cruciallearning/puddle";

import Status from "./Status.vue";
import TechSpecs from "./TechSpecs.vue";

@Component({
  components: {
    Status,
    Button,
    TechSpecs,
  },
  computed: {
    ...mapGetters("SystemCheckModule", ["isActiveFailed"]),
    ...mapState("SystemCheckModule", ["activeIdx"]),
  },
  methods: {
    ...mapActions("SystemCheckModule", ["failIdx", "passIdx", "todoIdx"]),
  },
})
export default class BaseStep extends Vue {
  private stepIdx = -1;
  private readonly activeIdx!: number;
  mounted(): void {
    this.stepIdx = this.activeIdx;
  }
  private readonly failIdx!: FailIdx;
  private readonly passIdx!: PassIdx;
  private readonly todoIdx!: TodoIdx;
  protected readonly isActiveFailed!: boolean;

  passActive(): void {
    this.passIdx(this.stepIdx);
  }

  failActive(error: string): void {
    this.failIdx({ error, idx: this.stepIdx });
  }

  todoActive(): void {
    this.todoIdx(this.stepIdx);
  }

  failedMessage(messages: string[]): string {
    let messageString = "<ul>";
    messages.forEach((message) => {
      messageString += "<li>";
      messageString += message;
      messageString += "</li>";
    });
    messageString += "</ul>";
    return messageString;
  }
}
</script>
