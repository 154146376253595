import { ActionContext, Module } from "vuex";
import { sockets } from "@/services/socket_api";
import { Breakout, Group, MediaUpdateType, RootState, UserModel, UsersState } from "@/types";
import { ReactionType } from "@/types/status";
import Authorization from "@/auth/authorization";
import { ReactionUserModel } from "@/types/reaction";
import store from "@/store";
import { getInstance } from "@cruciallearning/puddle/auth";
import { ToastType, Toaster } from "@cruciallearning/puddle/toast";

const UsersModule: Module<UsersState, RootState> = {
  namespaced: true,
  state(): UsersState {
    return {
      users: [],
      statuses: [],
      videoUsers: [],
      trainerIds: [],
      moderatorIds: [],
      observerIds: [],
    };
  },
  getters: {
    getActive(state: UsersState): UserModel | undefined {
      return state.users.find((item) => item.active);
    },
    getById(state: UsersState): (securityId: string) => UserModel | undefined {
      return (securityId: string): UserModel | undefined => state.users.find((item) => item.securityId === securityId);
    },
    getAllLearners(state: UsersState, _getters, rootState): Array<UserModel> {
      const event = rootState.EventModule?.event;
      return state.users.filter((user) => {
        if (event) return Authorization.isRegisteredLearner(user.securityId, event) || user.test;
      });
    },
    getLearners(state: UsersState, _getters, rootState, rootGetters): Array<UserModel> {
      const groupId: string[] | undefined = rootGetters["BreakoutsModule/getActiveBreakoutSelf"]?.userIds;
      const nonGroupIds: string[] | undefined = rootGetters["BreakoutsModule/getActiveBreakoutNonGroupUsers"]?.userIds;
      const event = (rootState as RootState).EventModule?.event;
      return state.users
        .filter((user) => !user.waiting)
        .filter((user) => (groupId ? groupId.includes(user.securityId) : true))
        .filter((user) => (!groupId && nonGroupIds ? nonGroupIds.includes(user.securityId) : true))
        .filter((user) => {
          if (event) return Authorization.isRegisteredLearner(user.securityId, event) || user.test;
        });
    },
    getLobby(state: UsersState, _, rootState): Array<UserModel> {
      const event = (rootState as RootState).EventModule?.event;
      return state.users.filter(
        (user) => user.waiting && (Authorization.isRegisteredLearner(user.securityId, event) || user.test)
      );
    },
    getSelf(state: UsersState): UserModel | undefined {
      return state.users.find((item) => item.self);
    },
    getModerators(state: UsersState, _getters, rootState): Array<UserModel> {
      const event = (rootState as RootState).EventModule?.event;
      return state.users
        .filter((user) => state.moderatorIds.includes(user.securityId) || state.observerIds.includes(user.securityId))
        .filter((user) => {
          if (event)
            return (
              Authorization.isModerator(user.securityId, event) || Authorization.isObserver(user.securityId, event)
            );
        });
    },

    getTrainers(state: UsersState, _getters, rootState): Array<UserModel> {
      const event = (rootState as RootState).EventModule?.event;
      return state.users
        .filter((user) => state.trainerIds.includes(user.securityId))
        .filter((user) => {
          if (event) return Authorization.isTrainer(user.securityId, event);
        });
    },
    getBreakoutAwareModerators(_, getters, _rootState, rootGetters): Array<UserModel> {
      const activeBreakoutSelf: Group | undefined = rootGetters["BreakoutsModule/getActiveBreakoutSelf"];
      const moderators: UserModel[] = getters["getModerators"] || [];
      if (!activeBreakoutSelf) {
        const activeBreakout: Breakout | undefined = rootGetters["BreakoutsModule/getActiveBreakout"];
        if (!activeBreakout) {
          return moderators;
        }
        const allUserIds = activeBreakout.groups.flatMap((e) => e.userIds);
        return moderators.filter((user: UserModel) => !allUserIds.includes(user.securityId));
      } else {
        const breakoutUserIds: string[] = activeBreakoutSelf?.userIds || [];
        return moderators.filter((moderator) => breakoutUserIds.includes(moderator.securityId));
      }
    },

    getBreakoutAwareTrainers(_, getters, _rootState, rootGetters): Array<UserModel> {
      const activeBreakoutSelf: Group | undefined = rootGetters["BreakoutsModule/getActiveBreakoutSelf"];
      const trainers: UserModel[] = getters["getTrainers"] || [];
      if (!activeBreakoutSelf) {
        const activeBreakout: Breakout | undefined = rootGetters["BreakoutsModule/getActiveBreakout"];
        if (!activeBreakout) {
          return trainers;
        }
        const allUserIds = activeBreakout.groups.flatMap((e) => e.userIds);
        return trainers.filter((user: UserModel) => !allUserIds.includes(user.securityId));
      } else {
        const breakoutUserIds: string[] = activeBreakoutSelf?.userIds || [];
        return trainers.filter((trainer) => breakoutUserIds.includes(trainer.securityId));
      }
    },
    breakoutAwareUserCount(_, getters): number {
      return (
        getters.getBreakoutAwareTrainers.length + getters.getBreakoutAwareModerators.length + getters.getLearners.length
      );
    },
    isAway(state: UsersState): (user: UserModel) => boolean {
      return (user: UserModel) => {
        return !!state.statuses.find((e) => e.securityId === user.securityId && e.status === ReactionType.AWAY);
      };
    },
  },
  mutations: {
    setUsersState(state: UsersState, usersStateOptions: Partial<UsersState>): void {
      Object.assign(state, usersStateOptions);
    },
    setUser(state: UsersState, userOptions: Partial<UserModel>): void {
      if (!userOptions.securityId) return;
      const user = state.users.find((item) => item.securityId === userOptions.securityId);
      if (user) Object.assign(user, userOptions);
    },
    setUsers(state: UsersState, users: Array<UserModel>): void {
      const updatedUsers: Array<UserModel> = [];
      const addedUsers: Array<UserModel> = [];
      const removedUsers: Array<UserModel> = state.users.slice(0);

      users.forEach((item) => {
        const currentUser = state.users.find((user) => user.securityId === item.securityId);
        if (!currentUser) {
          // New user
          const user = { ...item };
          user.active = false;
          user.away = false;
          user.ready = true;
          user.self = user.securityId === getInstance().authUser.id;
          updatedUsers.push(user);

          if (addedUsers && !user.self && !user.waiting) addedUsers.push(user);
        } else {
          // Existing user
          if (addedUsers && currentUser.self && currentUser.waiting && !item.waiting) {
            addedUsers.push(item);
          }

          const user = { ...currentUser, ...item };
          updatedUsers.push(user);

          if (removedUsers) {
            const removalIndex = removedUsers.findIndex((removalUser) => removalUser.securityId === item.securityId);
            if (removalIndex !== -1) removedUsers.splice(removalIndex, 1);
          }
        }
      });
      state.users = updatedUsers;

      if (
        (Authorization.isObserver(Authorization.mySecurityId) || Authorization.isTrainer(Authorization.mySecurityId)) &&
        !Authorization.isRegisteredLearner(Authorization.mySecurityId)
      ) {
        if (addedUsers.length == 1) {
          const user = addedUsers[0];
          Toaster.add(ToastType.USER_JOIN, `${user.firstName} ${user.lastName}`);
        }
        if (removedUsers.length == 1) {
          const user = removedUsers[0];
          Toaster.add(ToastType.USER_LEFT, `${user.firstName} ${user.lastName}`);
        }
      }
    },
    setReadyState(state: UsersState, users?: string[]): void {
      if (!users) {
        state.users.forEach((item) => {
          if (!item.self) item.ready = false;
        });
      } else {
        users.forEach((item) => {
          const user = state.users.find((user) => user.securityId === item);
          if (user) user.ready = true;
        });
      }
    },
    setReactionStatus(state: UsersState, body: { status: ReactionType; senderId: string }): void {
      const idx = state.statuses.findIndex((e) => e.securityId === body.senderId);
      if (idx != -1) state.statuses.splice(idx, 1);
      state.statuses.push({ securityId: body.senderId, status: body.status });
      const user = state.users.find((item) => item.securityId === body.senderId);
      if (user) user.away = body.status == ReactionType.AWAY;
      if (
        user &&
        body.status == ReactionType.RAISE_HAND &&
        Authorization.isTrainer(Authorization.mySecurityId) &&
        !Authorization.isRegisteredLearner(Authorization.mySecurityId)
      ) {
        Toaster.add(ToastType.HAND_RAISED, `${user.firstName} ${user.lastName}`);
      }
    },
    clearUserStatus(state: UsersState, body: { user: ReactionUserModel }): void {
      const idx = state.statuses.findIndex((e) => e.securityId === body.user.securityId);
      if (idx != -1) state.statuses.splice(idx, 1);
      state.statuses.push({ securityId: body.user.securityId, status: body.user.status });
      const user = state.users.find((item) => item.securityId === body.user.securityId);
      if (user) user.away = body.user.status == ReactionType.AWAY;
    },
    populateStatuses(state: UsersState, statuses: ReactionUserModel[]): void {
      state.statuses = [];
      statuses.forEach((status) => {
        store.commit("UsersModule/setReactionStatus", { status: status.status, senderId: status.securityId });
      });
    },
    toggleVideoUser(state: UsersState, userId: string): void {
      const idx = state.videoUsers.findIndex((e) => e === userId);
      if (idx != -1) state.videoUsers.splice(idx, 1);
      else state.videoUsers.push(userId);
    },
    clearAllVideoUsers(state: UsersState): void {
      state.videoUsers = [];
    },
    clearStatuses(state: UsersState): void {
      state.statuses = [];
    },
  },
  actions: {
    update({ commit }, usersStateOptions: Partial<UsersState>): void {
      commit("setUsersState", usersStateOptions);
    },
    updateUser({ commit }: ActionContext<UsersState, RootState>, userChanges?: Partial<UserModel>): void {
      commit("setUser", userChanges);
    },
    mute(_, { type, securityId }: { type: MediaUpdateType; securityId?: string }): void {
      sockets.general.send("", `mute.${type}`, securityId);
    },
    shutter(_, { type, securityId }: { type: MediaUpdateType; securityId?: string }): void {
      sockets.general.send("", `video.${type}`, securityId);
    },
    clearReaction(_, securityId: string): void {
      sockets.reaction.clear(securityId);
    },
    updateReactionStatus(_, status: ReactionType): void {
      sockets.reaction.update(status);
    },
    processReactionStatus({ commit }, body: { status: ReactionType; senderId: string }) {
      commit("setReactionStatus", body);
      if (body.status === ReactionType.RAISE_HAND) {
        commit("setRootState", { userRaisedHand: true }, { root: true });
      }
    },
    clearAllReactions(): void {
      sockets.reaction.clearAll();
    },
  },
};

export default UsersModule;
