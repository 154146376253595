import { TestPayload } from "@/types";
import routes from "@/services/routes";
import socket from "@/services/socket";

const test = {
  emptyClassroom(): void {
    socket.send({ type: routes.TEST_CLASSROOM_EMPTY });
  },
  fillClassroom(count = 10): void {
    socket.send({ type: routes.TEST_CLASSROOM_FILL, count });
  },
  receive(payload: TestPayload): void {
    switch (payload.type) {
      case routes.TEST_CLASSROOM_EMPTY:
        break;
      default:
        console.log("Invalid test message");
        break;
    }
  },
};
export default test;
