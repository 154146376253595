<template lang="pug">
span(v-html="html")
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
const href = "https://cruciallearning.com/terms-policies/system-requirements/#lp";
const link = "CLP technical specifications.";
const target = "_blank";
export const htmlTechSpec = `<a href=${href} target=${target}>${link} </a>`;
@Component
export default class TechSpecs extends Vue {
  get html(): string {
    return htmlTechSpec;
  }
}
</script>
