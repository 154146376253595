import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { AnnotatorPayload } from "@/types/Annotation";

const annotator = {
  claimStatus(): void {
    socket.send({ type: routes.ANNOTATOR_CLAIM });
  },
  waiveStatus(): void {
    socket.send({ type: routes.ANNOTATOR_WAIVE });
  },
  sendColor(color: string): void {
    socket.send({ type: routes.ANNOTATOR_COLOR, color });
  },
  receive(payload: AnnotatorPayload): void {
    switch (payload.type) {
      case routes.ANNOTATOR_CLAIM:
      case routes.ANNOTATOR_WAIVE:
        store.commit("AnnotationsModule/setAnnotationState", { annotatorId: payload.body.securityId });
        break;
      case routes.ANNOTATOR_COLOR:
        store.commit("AnnotationsModule/setAnnotationState", { color: payload.body.color });
        break;
      default:
        console.log("Invalid annotator message");
        break;
    }
  },
};
export default annotator;
