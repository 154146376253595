import { DirectiveOptions } from "vue/types/options";

/*
 * ------------------------------------------------------------------------------
 * Vue Directives
 * ------------------------------------------------------------------------------
 */

export const visible: DirectiveOptions = {
  bind(el, binding) {
    el.style.visibility = binding.value ? "visible" : "hidden";
  },
};
