<template lang="pug">
.chat-container
  Pinned(v-if="showPinned")
  .chat-history
    Message(
      v-for="(item, index) in channel",
      :type="type",
      :key="index",
      :content="item",
      :data-name="'message-' + index"
    )
  .chat-input(v-if="!waitingRoom")
    .input-container
      TextArea(
        ref="chatTextArea",
        placeholder="Type Chat Here",
        :value="edittableMessage",
        :id="textAreaId",
        height="55px",
        valueChangeEvent="input",
        :maxLength="120",
        @valueChanged="onEditMessage",
        @enterKey="onSendMessage",
        data-name="input-text"
      )
    ChatControls(:type="type", :message="edittableMessage", :onSubmit="onSubmit", @emojiClick="onEmojiClick")
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  ChatModel,
  ChatStatusModel,
  ChatType,
  GetChatByType,
  GetStatusByType,
  PinnedChatModel,
  SendMessage,
  SetEditBackstageMessage,
  SetEditMessage,
} from "@/types";
import { BaseChatTextArea as TextArea } from "@cruciallearning/puddle";
import Message from "./Message/Message.vue";
import { Icon } from "@cruciallearning/puddle/components";
import ChatControls from "./ChatControls.vue";
import { CHAT_TIMEOUT } from "@/store/modules/Chat/constants";
import Pinned from "./Pinned.vue";

@Component({
  components: { TextArea, Message, Icon, ChatControls, Pinned },
  computed: {
    ...mapState("ChatModule", ["editMessage", "editBackstageMessage", "activeId", "pinned"]),
    ...mapGetters("ChatModule", ["getChatByType", "getStatusByType", "getEmojis"]),
  },
  methods: {
    ...mapActions("ChatModule", ["sendMessage"]),
    ...mapMutations("ChatModule", ["setEditMessage", "setEditBackstageMessage"]),
  },
})
export default class BaseChat extends Vue {
  @Prop({ required: false, default: ChatType.MAIN }) readonly type!: ChatType;
  @Prop({ required: false, default: false }) waitingRoom!: boolean;

  private readonly activeId!: string;
  private readonly editMessage!: string;
  private readonly editBackstageMessage!: string;
  private readonly getChatByType!: GetChatByType;
  private readonly getStatusByType!: GetStatusByType;
  private readonly sendMessage!: SendMessage;
  private readonly setEditMessage!: SetEditMessage;
  private readonly setEditBackstageMessage!: SetEditBackstageMessage;
  private readonly pinned!: PinnedChatModel[];
  private typingTimeout!: NodeJS.Timeout | undefined;

  private selfTyping = false;

  private isEmojiMenuVisible = false;

  destroyed(): void {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
  }

  get textAreaId(): string {
    return `textArea-${this.type}`;
  }

  get channel(): ChatModel[] {
    return this.getChatByType(this.type);
  }

  get status(): ChatStatusModel {
    return this.getStatusByType(this.type);
  }

  get emojiPosition(): string {
    if (this.isBackstage) return "left bottom";
    else return "right bottom";
  }

  get isBackstage(): boolean {
    return this.type === ChatType.BACKSTAGE;
  }

  get showPinned(): boolean {
    return this.pinned?.length > 0 && this.type === ChatType.MAIN;
  }

  get edittableMessage(): string {
    if (this.isBackstage) return this.editBackstageMessage;
    return this.editMessage;
  }

  onEmojiClick(emoji: string): void {
    const selector = `#${this.textAreaId} .dx-texteditor-input`;
    const el: HTMLTextAreaElement | null = document.querySelector(selector);
    if (el) {
      const idx = el.selectionStart;
      let message = this.isBackstage ? this.editBackstageMessage : this.editMessage;
      message = message.slice(0, idx) + emoji + message.slice(idx);
      if (message.length > 120) return;
      if (this.isBackstage) {
        this.setEditBackstageMessage(message);
      } else {
        this.setEditMessage(message);
      }
    }
  }

  onEditMessage(text: string): void {
    if (text.length > 120) return;
    if (this.isBackstage) {
      this.setEditBackstageMessage(text);
    } else {
      this.setEditMessage(text);
    }
    if (!this.selfTyping) {
      this.selfTyping = true;
      const typingIn = this.type === ChatType.GROUP ? this.activeId : this.type;
      this.$sockets?.general.send(typingIn, "typing.start");
      this.typingTimeout = setTimeout(() => {
        this.selfTyping = false;
      }, CHAT_TIMEOUT);
    }
  }

  onSendMessage(text: string): void {
    this.onEditMessage("");
    this.sendMessage({ type: this.type, text });
  }
  onSubmit(): void {
    (this.$refs.chatTextArea as TextArea).asEnterKey();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .input-container textarea {
  height: 55px;
}

.chat-input {
  margin-top: 0.5rem;
}

::v-deep .chat-history {
  flex-direction: column-reverse;
}
::v-deep textarea {
  overflow-y: scroll !important;
}
</style>
