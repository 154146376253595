import { ActionContext, Module } from "vuex";
import { Course, CourseGetters, CourseState, Frame, Lesson, LessonSelect, Slides } from "@/types/course";
import { debounce, debouncing } from "@cruciallearning/puddle/utils";
import store from "@/store";
import { sockets } from "@/services/socket_api";

const CourseModule: Module<CourseState, unknown> = {
  namespaced: true,
  state(): CourseState {
    return {
      activeContentId: "",
      activeLessonId: "",
      currentLessonId: "",
      course: undefined,
      title: "",
      showModel: false,
      showAltModel: false,
    };
  },
  getters: {
    getActiveContent(state: CourseState, getters: CourseGetters): Partial<Frame>[] | undefined {
      const slides = getters.getActiveContentSlides;
      if (slides) return slides?.frames;
    },
    getActiveContentSlides(state: CourseState): Slides | undefined {
      for (let i = 0, iN = state.course?.lessons.length || 0; i < iN; i++) {
        const content = state.course?.lessons[i].slides.find(
          (item) => `${state.course?.lessons[i].title} ${item.index}` === state.activeContentId
        );
        if (content) return content;
      }
    },
    getLesson(state: CourseState): Lesson | undefined {
      return state.course?.lessons.find((item) => item.title === state.currentLessonId) || undefined;
    },
    getLessonByTitle(state: CourseState) {
      return (lessonTitle: string): Lesson | undefined => {
        const t = state.course?.lessons.find((item) => item.title === lessonTitle) || undefined;
        return t;
      };
    },
    getLessonIndex(state: CourseState): number | undefined {
      return state.course?.lessons.findIndex((item) => item.title === state.currentLessonId);
    },
    getLessons(state: CourseState): LessonSelect[] | undefined {
      return state.course?.lessons.map((item) => {
        return { title: item.title, name: item.name, slideCount: item.slides.length } as LessonSelect;
      });
    },
    getNextLessonContentId(state: CourseState, getters: CourseGetters): string {
      const lessonIndex = getters.getLessonIndex;
      if (state.course && lessonIndex != -1 && (lessonIndex as number) < state.course?.lessons.length - 1) {
        const lesson = state.course.lessons[(lessonIndex as number) + 1];
        const slide = lesson.slides[0];
        return `${lesson.title} ${slide.index}`;
      }
      return "";
    },
    getPrevLessonContentId(state: CourseState, getters: CourseGetters): string {
      const lessonIndex = getters.getLessonIndex;
      if (state.course && lessonIndex != -1 && (lessonIndex as number) > 0) {
        const lesson = state.course.lessons[(lessonIndex as number) - 1];
        const slide = lesson.slides[lesson.slides.length - 1];
        return `${lesson.title} ${slide.index}`;
      }
      return "";
    },
    getSlideByTitle(state: CourseState) {
      return (slideTitle: string): Slides | undefined => {
        const lessonTitle = slideTitle.split(" ").slice(0, 2).join(" ");
        return state.course?.lessons
          .find((lesson) => lesson.title === lessonTitle)
          ?.slides.find((slide) => slide.title === slideTitle);
      };
    },
    getSlideIndex(state: CourseState, getters: CourseGetters): number | undefined {
      const lessonIndex = getters.getLessonIndex;
      if (lessonIndex !== undefined) {
        return state.course?.lessons[lessonIndex].slides.findIndex(
          (item) => item.index === Number(state.activeContentId.split(" ")[2])
        );
      }
    },
    getLearnerPageNumber(state: CourseState, getters: CourseGetters): number | undefined {
      return getters.getActiveContentSlides?.learnerPage;
    },
    getModelUrl(state: CourseState): string {
      return state.course ? state.course.modelUrl : "";
    },
    getAltModelUrl(state: CourseState): string {
      return state.course ? state.course.altModelUrl : "";
    },
  },
  mutations: {
    setCourseState(state: CourseState, courseStateOptions: Partial<CourseState>): void {
      Object.assign(state, courseStateOptions);
    },
    setCourse(state: CourseState, course: Course): void {
      state.course = JSON.parse(JSON.stringify(course));
      state.title = course.title;
      const lesson = state.course?.lessons[0];
      if (!state.activeContentId && !state.activeLessonId) {
        state.activeContentId = `${lesson?.title} ${lesson?.slides[0].index}`;
        state.activeLessonId = `${lesson?.title}`;
        state.currentLessonId = `${lesson?.title}`;
      }
    },
  },
  actions: {
    navigate({ commit, state }: ActionContext<CourseState, unknown>, activeContentId: string): void {
      if (state.activeContentId === activeContentId) return;
      const slide = activeContentId.split(" ");
      const activeLessonId = `${slide[0]} ${slide[1]}`;
      commit("setCourseState", { activeContentId, activeLessonId } as Partial<CourseState>);
      debounce(() => {
        store.commit("UsersModule/setReadyState", null, { root: true });
        sockets.slide.goto(activeContentId);
      }, 1000)();
    },
    navigateReceived({ commit, state }: ActionContext<CourseState, unknown>, activeContentId: string): void {
      if (state.activeContentId === activeContentId || debouncing()) return;
      const slide = activeContentId.split(" ");
      const activeLessonId = `${slide[0]} ${slide[1]}`;
      commit("setCourseState", {
        activeContentId,
        activeLessonId,
        currentLessonId: activeLessonId,
      } as Partial<CourseState>);
    },
    onNavigate({ state, getters, dispatch }, slideIndex: number): void {
      if (state.showModel) {
        dispatch("toggleShowModel");
      }
      if (state.showAltModel) {
        dispatch("toggleShowAltModel");
      }
      const slideCount = getters.getLesson?.slides.length || 0;
      if (slideIndex < 0 && getters.getPrevLessonContentId) {
        dispatch("navigate", getters.getPrevLessonContentId);
      } else if (slideIndex > slideCount - 1 && getters.getNextLessonContentId) {
        dispatch("navigate", getters.getNextLessonContentId);
      } else {
        const index = getters.getLesson?.slides[slideIndex].index;
        dispatch("navigate", `${getters.getLesson?.title} ${index}`);
      }
    },
    toggleShowModel({ state }): void {
      sockets.slide.setShowModel(!state.showModel);
    },
    toggleShowAltModel({ state }): void {
      sockets.slide.setShowAltModel(!state.showAltModel);
    },
  },
};
export default CourseModule;
