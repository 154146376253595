<template lang="pug">
.contents-passthrough
  .inner-trainer-container
    Timers
    Breakouts(v-if="breakoutsEnabled")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Breakouts from "@/components/Sidebar/Breakouts/BaseBreakouts.vue";
import Timers from "@/components/Timers/Timers.vue";
import { mapGetters } from "vuex";

@Component({
  components: { Breakouts, Timers },
  computed: {
    ...mapGetters("EventModule", ["breakoutsEnabled"]),
  },
})
export default class BaseTools extends Vue {}
</script>
