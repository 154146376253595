//Store
export interface VonageState {
  publisher: OT.Publisher | null;
  subscribers: OT.Subscriber[];
  session: OT.Session | null;
}

//METHODS
export interface SetPublisher {
  (publisher: OT.Publisher | null): void;
}
export interface SetVonageState {
  (changes: Partial<VonageState>): void;
}
export interface SetSession {
  (session: OT.Session | null): void;
}
export interface ClearSubscribers {
  (): void;
}
export interface AddSubscriber {
  (subscriber: OT.Subscriber): void;
}
export interface RemoveSubscriber {
  (subscriber?: OT.Stream): void;
}

export interface RefreshPublisherSettings {
  (): void;
}
export interface ApplyUserVideoFilter {
  (publisher?: OT.Publisher | null): void;
}
// ENUMS
export enum VideoContainerId {
  TRAINERS = "trainers",
  LEARNERS = "learners",
  MODERATORS = "moderators",
}

export enum VideoStatusClass {
  HAS_MENU_ICON = "user-video-has-menu",
  HAS_CHAT_ICON = "user-video-has-chat",
}

export interface StatsReport {
  publisher: UserRTCStats;
  subscribers: UserRTCStats[];
}
interface UserBase {
  firstName: string;
  lastName: string;
  securityId: string;
  streamId: string;
}
export interface UserRTCStats extends UserBase {
  reports: unknown[];
}

export interface UserReportPayload extends UserBase {
  data: string;
}

export interface ReportPayload {
  learningEventId: string;
  sessionId: string;
  sessionNumber: string;
  sessionTime: string;
  clientId: string;
  clientName: string;
  publisher: UserReportPayload;
  subscribers: UserReportPayload[];
}
