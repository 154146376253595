import { render, staticRenderFns } from "./BaseAttendees.vue?vue&type=template&id=255ee61e&scoped=true&lang=pug"
import script from "./BaseAttendees.vue?vue&type=script&lang=ts"
export * from "./BaseAttendees.vue?vue&type=script&lang=ts"
import style0 from "./BaseAttendees.vue?vue&type=style&index=0&id=255ee61e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255ee61e",
  null
  
)

export default component.exports