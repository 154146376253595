import { render, staticRenderFns } from "./Status.vue?vue&type=template&id=453d2223&scoped=true&lang=pug"
import script from "./Status.vue?vue&type=script&lang=ts"
export * from "./Status.vue?vue&type=script&lang=ts"
import style0 from "./Status.vue?vue&type=style&index=0&id=453d2223&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453d2223",
  null
  
)

export default component.exports