import { sockets } from "@/services/socket_api";
import { RootState } from "@/types";
import { AnnotationState, Point } from "@/types/Annotation";
import { getInstance } from "@cruciallearning/puddle/auth";
import { Module } from "vuex";

const AnnotationsModule: Module<AnnotationState, RootState> = {
  namespaced: true,
  state: {
    point: {
      xRatio: 0,
      yRatio: 0,
    },
    lineBreak: false,
    clear: false,
    annotatorId: "",
    color: "",
  },
  mutations: {
    setAnnotationState(state: AnnotationState, annotationOptions: Partial<AnnotationState>) {
      Object.assign(state, annotationOptions);
    },
    addPoint(state: AnnotationState, point: Point) {
      state.point = point;
    },
  },
  getters: {
    isAnnotating(state: AnnotationState): boolean {
      return getInstance().authUser.id === state.annotatorId;
    },
    isPenAvailable(state: AnnotationState): boolean {
      return state.annotatorId === "UNSET";
    },
  },
  actions: {
    sendPoint(_, coordinates: { xRatio: number; yRatio: number }): void {
      const body = JSON.stringify(coordinates);
      sockets.general.send(body, "annotation.point");
    },
    sendColor(_, color: string): void {
      sockets.annotator.sendColor(color);
    },
    sendLineBreak(): void {
      sockets.general.send("", "annotation.break");
    },
    sendClear(): void {
      sockets.general.send("", "annotation.clear");
    },
    claimAnnotatorStatus(): void {
      sockets.annotator.claimStatus();
    },
    waiveAnnotatorStatus(): void {
      sockets.annotator.waiveStatus();
    },
  },
};

export default AnnotationsModule;
