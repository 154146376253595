import socket from "@/services/socket";
import { RemoteLogRequest } from "@/types/RemoteLogRequest";
import RetryUtil, { AbortError } from "@/utils/RetryUtil";
import CommonUtil from "@/utils/CommonUtil";
import { AckPayload } from "@/types/ack";
import routes from "@/services/routes";

class RemoteLogger {
  /** submit message as remote-logging request, possibly expecting a response */
  public logMessage(message: string): Promise<unknown> {
    const payload: RemoteLogRequest = RemoteLogRequest.from(message);
    if (RemoteLogger.USE_RETRY) {
      return this.sendWithRetry(payload);
    } else {
      return this.sendPayload(payload);
    }
  }

  private sendPayload(payload: object): Promise<unknown> {
    socket.send(payload);
    return Promise.resolve(RemoteLogger.MOCK_SUCCESS_ACK_PAYLOAD);
  }

  private sendPayloadExpectingAck(payload: object, options?: object): Promise<unknown> {
    return socket.sendRequest(payload, options);
  }

  private sendWithRetry(request: RemoteLogRequest): Promise<unknown> {
    return RetryUtil.retry(this.makeRetryableOperation(request), RemoteLogger.RETRY_OPTIONS);
  }

  private makeRetryableOperation(request: RemoteLogRequest): (attemptNum: number) => Promise<unknown> {
    const baseRequestId: string = CommonUtil.makeRequestId();
    return async (attemptNum: number): Promise<unknown> => {
      if (this.recentlyAckdIdsContains(baseRequestId)) {
        // if we've received an ack for this baseRequestId, abort processing.
        // (this handles the case when a previous attempt timed-out, triggering
        // a retry, but later came thru.)
        return Promise.resolve(RemoteLogger.MOCK_SUCCESS_ACK_PAYLOAD);
      } else {
        const requestId = CommonUtil.makeRequestId(baseRequestId, attemptNum);
        const payload = request.toTypedPayload(attemptNum);
        const options = { requestId: requestId };
        if (attemptNum == 0 || (await socket.healthCheck())) {
          // if this is the first attempt, or the socket is (now) healthy, attempt to [re]submit
          return this._executeForRetry(payload, options);
        } else {
          // else just defer for a later retry, as the socket isn't healthy anyway
          return Promise.reject(new Error("Not healthy yet; may retry"));
        }
      }
    };
  }

  private _executeForRetry(payload: object, options?: object): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.sendPayloadExpectingAck(payload, options)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.success) {
            resolve(response);
          } else if (400 <= response.statusCode && response.statusCode <= 499) {
            reject(new AbortError("Abort retries due to client error"));
          } else {
            reject(new Error("Other error; may retry"));
          }
        })
        .catch((err: Error) => {
          if (err && err.name === "TimeoutError") {
            // timeout waiting for response;may retry
            reject(err);
          } else {
            // other unspecified error; may retry?
            reject(err);
          }
        });
    });
  }

  /**
   * if a timed-out remote logging request is later ack'd, make a note of it
   * so we can abort pending retries if appropriate.
   */
  public receiveAck(payload: AckPayload): void {
    if (payload && payload.success && payload.id && payload.requestType === routes.REMOTE_LOGGER) {
      this.pushRecentlyAckdId(payload.id.split(":")[0]);
    }
  }

  // the following implement a simple rolling list of the N most recently ack'd base requestIds
  private recentlyAckdIds: (string | null)[] = new Array(5);
  private recentlyAckdIdsNextIndex = 0;
  private pushRecentlyAckdId(baseRequestId: string | null): void {
    if (!this.recentlyAckdIdsContains(baseRequestId)) {
      this.recentlyAckdIds[this.recentlyAckdIdsNextIndex] = baseRequestId;
      this.recentlyAckdIdsNextIndex = (this.recentlyAckdIdsNextIndex + 1) % this.recentlyAckdIds.length;
    }
  }
  private recentlyAckdIdsContains(baseRequestId: string | null): boolean {
    return baseRequestId != null && this.recentlyAckdIds.indexOf(baseRequestId) >= 0;
  }

  private static readonly MOCK_SUCCESS_ACK_PAYLOAD = { success: true, statusCode: 201, type: "ack" };

  private static readonly USE_RETRY = true;

  private static readonly RETRY_OPTIONS = {
    retries: 5,
    minTimeout: 5 * 1000,
  };
}

export default new RemoteLogger();
