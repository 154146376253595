import routes from "@/services/routes";
import { KeyUnknownModel } from "@/types";

/** A single-message remote logging request payload */
class RemoteLogRequest {
  /** the log message (required) */
  public readonly message: string;
  /** id of the associated user (optional) */
  public readonly userId?: string | null;
  /** ISO-8601 format representation of the (local) time the message was created (optional, defaults to "now") */
  public readonly createdAtIso8601?: string | null;

  /** all args constructor */
  constructor(message: string, userId?: string | null, createdAt?: string | Date | null) {
    this.message = message;
    this.userId = userId;
    if (typeof createdAt === "string") {
      this.createdAtIso8601 = createdAt;
    } else if (createdAt instanceof Date) {
      this.createdAtIso8601 = createdAt.toISOString();
    } else {
      this.createdAtIso8601 = new Date().toISOString();
    }
  }

  /** make a RemoteLogRequest from the given `message` */
  static from(message: string): RemoteLogRequest {
    return new RemoteLogRequest(message);
  }

  /** extract a generic js object type containing all my non-null properties */
  toPayload(retryAttempt?: number): KeyUnknownModel {
    const payload: KeyUnknownModel = {};
    if (this.message != null && this.message != undefined) {
      payload.message = this.message;
    }
    if (this.userId != null && this.userId != undefined) {
      payload.userId = this.userId;
    }
    if (this.createdAtIso8601 != null && this.createdAtIso8601 != undefined) {
      payload.createdAtIso8601 = this.createdAtIso8601;
    }
    if (retryAttempt != null && retryAttempt != undefined && retryAttempt > 0) {
      payload.retryAttempt = retryAttempt;
      payload.retryAtIso8601 = new Date().toISOString();
    }
    return payload;
  }

  /** same as `toPayload()` with an additional `type: routes.REMOTE_LOGGER` property */
  toTypedPayload(retryAttempt?: number): KeyUnknownModel {
    const payload: KeyUnknownModel = this.toPayload(retryAttempt);
    payload.type = routes.REMOTE_LOGGER;
    return payload;
  }
}

export { RemoteLogRequest };
