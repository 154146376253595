import { RoutesType } from "@/types";
import auditron from "@cruciallearning/puddle/api/auditron";
import routes from "@/services/routes";
import Download from "@/utils/download";
import registration from "@/services/api/requests/registration";
import event from "@/services/api/requests/event";
import course from "./requests/course";
import icon from "./requests/icon";
import rtcReport from "./requests/rtcReport";
class Api {
  public event = event;
  public download = Download;
  public registration = registration;
  public auditron = auditron;
  public course = course;
  public icon = icon;
  public rtcReport = rtcReport;

  get routes(): RoutesType {
    return routes;
  }
}
export default new Api();
