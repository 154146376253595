<template lang="pug">
.presentation-sidebar.layout-grid.row-min-content.dark-mode(:class="[columnClass]")
  ResizeBar(:column="resizeOption")
  .no-padding
    HandsRaised
    Attendees
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import Attendees from "./Attendees/BaseAttendees.vue";
import ResizeBar from "../GridResize/GridResizeBar.vue";
import { ResizingOption, SetGridState } from "@/store/modules/Grid/models";
import { LocalStorageFields } from "@/types/base";
import HandsRaised from "@/components/Header/HandsRaised.vue";

@Component({
  components: { HandsRaised, Attendees, ResizeBar },
  computed: {
    ...mapState("GridModule", ["usersCols"]),
  },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
  },
})
export default class UsersColumn extends Vue {
  private readonly usersCols!: number;
  private readonly setGridState!: SetGridState;
  created(): void {
    const usersSidebarCols = localStorage.getItem(LocalStorageFields.USERS_COLS);
    const cols = usersSidebarCols ? Number(usersSidebarCols) : 2;
    this.setGridState({ usersCols: cols });
  }

  get columnClass(): string {
    return `cell-${this.usersCols}`;
  }
  get resizeOption(): ResizingOption {
    return ResizingOption.USERS;
  }
}
</script>
