export default class MessageEncoder {
  private static entities: string[][] = [
    ["<", "&lt;"],
    [">", "&gt;"],
    ['"', "&quot;"],
    ["'", "&apos;"],
    ["(", "&#40;"],
    [")", "&#41;"],
    // ["[", "&#91;"],
    // ["]", "&#93;"],
    ["`", "&#96;"],
    ["{", "&#123;"],
    ["}", "&#125;"],
    ["%", "&#37;"],
    ["=", "&#61;"],
    ["/", "&#47;"],
    // ["\\", "&#92;"],
  ];

  private static keys(): string[] {
    return this.entities.map((s: string[]) => s[0]);
  }

  private static values(): string[] {
    return this.entities.map((s: string[]) => s[1]);
  }

  private static get(key: string): string {
    const row = this.entities.find((e) => e[0] === key);
    if (row) return row[1];
    return "";
  }
  private static revGet(key: string): string {
    const row = this.entities.find((e) => e[1] === key);
    if (row) return row[0];
    return "";
  }

  private static keyString(): string {
    return `([${this.keys().join("")}])`;
  }
  private static valueString(): string {
    return `(${this.values().join("|")})`;
  }

  static encode(input: string | undefined): string {
    if (!input) return "";
    return input.replace(new RegExp(this.keyString(), "g"), (match) => this.get(match));
  }
  static decode(input: string | undefined): string {
    if (!input) return "";
    return input.replace(new RegExp(this.valueString(), "g"), (match) => this.revGet(match));
  }
}
