import {
  Breakout,
  BreakoutPayload,
  BreakoutPayloadAdd,
  BreakoutPayloadAddGroup,
  BreakoutPayloadDuplicate,
  BreakoutPayloadGroup,
  BreakoutPayloadRemoveGroup,
  BreakoutPayloadUsers,
} from "@/types";
import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { Toaster, ToastType } from "@cruciallearning/puddle/toast";

const breakout = {
  add(payload: BreakoutPayloadAdd): void {
    socket.send({ type: routes.BREAKOUT_ADD, ...payload });
  },
  addGroup(payload: BreakoutPayloadAddGroup): void {
    socket.send({ type: routes.BREAKOUT_GROUP_ADD, ...payload });
  },
  addUsers(payload: BreakoutPayloadUsers): void {
    socket.send({ type: routes.BREAKOUT_USER_ADD, ...payload });
  },
  breakouts(): void {
    socket.send({ type: routes.BREAKOUT });
  },
  details(): void {
    socket.send({ type: routes.BREAKOUT_DETAILS });
  },
  duplicate(payload: BreakoutPayloadDuplicate): void {
    socket.send({ type: routes.BREAKOUT_DUPLICATE, ...payload });
  },
  remove(id: string): void {
    socket.send({ type: routes.BREAKOUT_REMOVE, id });
  },
  removeGroup(payload: BreakoutPayloadRemoveGroup): void {
    socket.send({ type: routes.BREAKOUT_GROUP_REMOVE, ...payload });
  },
  removeUsers(payload: BreakoutPayloadUsers): void {
    socket.send({ type: routes.BREAKOUT_USER_REMOVE, ...payload });
  },
  startBreakout(payload: Partial<Breakout>): void {
    socket.send({ type: routes.BREAKOUT_START, ...payload });
  },
  stopBreakout(): void {
    socket.send({ type: routes.BREAKOUT_STOP, id: 1 });
  },
  updateBreakout(payload: Partial<Breakout>): void {
    socket.send({ type: routes.BREAKOUT_UPDATE, ...payload });
  },
  updateGroup(payload: BreakoutPayloadGroup): void {
    socket.send({ type: routes.BREAKOUT_GROUP_UPDATE, ...payload });
  },
  leaveBreakout(): void {
    socket.send({ type: routes.BREAKOUT_LEAVE });
  },
  receive(payload: BreakoutPayload): void {
    switch (payload.type) {
      case routes.BREAKOUT:
        store.dispatch("BreakoutsModule/updateBreakoutsState", {
          isActive: payload.body.isActive,
          activeId: payload.body.isActive ? payload.body.activeId : "",
          isLoadingBreakouts: false,
        });
        break;
      case routes.BREAKOUT_DETAILS:
        store.dispatch("BreakoutsModule/createBreakouts", payload.body.breakouts);
        break;
      case routes.BREAKOUT_ADD:
        store.dispatch("BreakoutsModule/addBreakoutReceived", payload.body);
        break;
      case routes.BREAKOUT_REMOVE:
        store.dispatch("BreakoutsModule/removeBreakoutReceived", payload.body.breakouts);
        break;
      case routes.BREAKOUT_UPDATE:
      case routes.BREAKOUT_GROUP_REMOVE:
      case routes.BREAKOUT_GROUP_UPDATE:
      case routes.BREAKOUT_GROUP_ADD:
      case routes.BREAKOUT_USER_ADD:
        store.dispatch("BreakoutsModule/updateBreakoutReceived", payload.body);
        break;
      case routes.BREAKOUT_USER_REMOVE:
        store.dispatch("BreakoutsModule/breakoutUserRemoved", payload.body);
        break;
      case routes.BREAKOUT_START:
        store.dispatch("BreakoutsModule/startBreakoutReceived", payload.body);
        break;
      case routes.BREAKOUT_STOP:
        store.dispatch("BreakoutsModule/stopBreakoutReceived", payload.body);
        break;
      case routes.BREAKOUT_TOKEN:
        store.commit("setRootState", {
          breakoutSessionId: payload.body.sessionId,
          breakoutVonageToken: payload.body.token,
          streamSessionId: "",
          streamToken: "",
        });
        break;
      default:
        console.log("Invalid breakout message");
        break;
    }
  },
};
export default breakout;
