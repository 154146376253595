import {
  ChatDeleteModel,
  ChatGroupModel,
  ChatIdModel,
  ChatLogPayload,
  ChatModel,
  ChatPayload,
  ChatType,
  PinnedChatModel,
} from "@/types";
import { format } from "date-fns";
import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import Download from "@/utils/download";
import authorization from "@/auth/authorization";
import MessageEncoder from "@/utils/MessageEncoder";
import { getInstance } from "@cruciallearning/puddle/auth";
import chatUtil from "@/utils/chatUtil";

const chat = {
  /*
   * ------------------------------------------------------------------------------
   * SEND
   * ------------------------------------------------------------------------------
   */

  /* Main */
  send(message: string, sessionId: string): void {
    const links: string[] = [];
    if (getInstance().ADMIN) {
      const urls = new RegExp("((https):((//)|(\\\\))+[\\w\\d:#@%/;$()~_?\\+-=\\\\\\.&]*)", "g");
      message.match(urls)?.forEach((item, idx) => {
        message = message.replace(item, `#linkItem${idx}#`);
        links.push(item);
      });
    }
    message = MessageEncoder.encode(message);
    if (links.length > 0) {
      const linkReg = new RegExp("#linkItem[0-9]*#", "g");
      message.match(linkReg)?.forEach((item, idx) => {
        message = message.replace(item, `<linkItem>${links[idx]}</linkItem>`);
      });
    }
    if (message) socket.send({ type: routes.CHAT_MAIN_SEND, message, sessionId });
  },

  deleteMain(messageId: string): void {
    socket.send({ type: routes.CHAT_MAIN_DELETE, messageId });
  },

  pinMain(messageId: string): void {
    socket.send({ type: routes.CHAT_MAIN_PIN, messageId });
  },

  unpinMain(messageId: string): void {
    socket.send({ type: routes.CHAT_MAIN_UNPIN, messageId });
  },

  /* Backstage */
  sendBackstage(message: string, sessionId: string): void {
    socket.send({ type: routes.CHAT_BACKSTAGE_SEND, message, sessionId });
  },

  /* Lobby  */
  sendLobby(message: string, sessionId: string): void {
    socket.send({ type: routes.CHAT_LOBBY_SEND, message, sessionId });
  },

  deleteLobby(messageId: string): void {
    socket.send({ type: routes.CHAT_LOBBY_DELETE, messageId });
  },

  /* Group */
  sendGroup(groupId: string, message: string, sessionId: string): void {
    socket.send({ type: routes.CHAT_GROUP_SEND, groupId, message, sessionId });
  },

  groupAdd(groupId: string, participantIds: Array<string>): void {
    socket.send({ type: routes.CHAT_GROUP_ADD, groupId, participantIds });
  },

  groupRemove(groupId: string, participantIds: Array<string>): void {
    socket.send({ type: routes.CHAT_GROUP_REMOVE, groupId, participantIds });
  },
  groupStartSend(groupName: string, participantIds: Array<string>) {
    socket.send({ type: routes.CHAT_GROUP_START, groupName, participantIds });
  },

  groupStopSend(groupId: string) {
    socket.send({ type: routes.CHAT_GROUP_STOP, groupId });
  },

  downloadChatLogSend() {
    socket.send({ type: routes.CHAT_MAIN_LOG });
  },

  /*
   * ------------------------------------------------------------------------------
   * RECEIVE
   * ------------------------------------------------------------------------------
   */

  /* All Chats Receive */
  receive(payload: ChatPayload): void {
    const type = payload.type.split(".");
    switch (type[2]) {
      case "new":
        this.receiveChat(payload);
        break;
      case "delete":
        this.receiveDeleteChat(payload);
        break;
      case "pin":
        this.receivePin(payload);
        break;
      case "unpin":
        this.receiveUnpin(payload);
        break;
      case "start":
        this.receiveStartGroup(payload);
        break;
      case "stop":
        this.receiveStopGroup(payload);
        break;
      case "remove":
        this.receiveRemoveGroup(payload);
        break;
      case "log":
        this.receiveChatLog(payload);
        break;
      default:
        console.log("Unknown message type: " + payload.type);
    }
  },

  receiveChat(payload: ChatPayload): void {
    const data = payload.body as ChatModel;
    const auth = getInstance();
    const isSelf = data.senderId == auth.authUser.id;
    const type = ChatType[payload.type.split(".")[1].toUpperCase() as keyof typeof ChatType];
    const encodedMessage = chatUtil.formatMessage(data.message);
    const sessionId = data.sessionId;
    store.commit("ChatModule/setMessage", {
      ...payload.body,
      type,
      time: format(new Date(), "h:mm aa"),
      self: isSelf,
      sessionId: sessionId,
      message: encodedMessage?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
    });
  },

  receiveChatLog(payload: ChatPayload): void {
    console.log("Download full chat log");
    const body = payload.body as ChatLogPayload;
    if (body.messages) {
      const messages: Partial<ChatModel>[] = body.messages
        .map((e) => ({
          firstName: e.firstName,
          lastName: e.lastName,
          message: e.content,
          sessionId: e.sessionId,
          sent: new Date(e.sent.replace("", "'T'")),
        }))
        .sort((a, b) => {
          if (a.sent > b.sent) return 1;
          else if (a.sent < b.sent) return -1;
          else return 0;
        });
      Download.saveChatList(messages, true);
    }
  },

  receiveDeleteChat(payload: ChatPayload): void {
    const data = payload.body as ChatDeleteModel;
    const messageId = data.messageId;
    const chatKey = payload.type.split(".")[1].toUpperCase() as keyof typeof ChatType;
    const type = ChatType[chatKey];
    store.commit("ChatModule/deleteMessage", {
      messageId,
      type,
    });
  },

  receivePin(payload: ChatPayload): void {
    const body = payload.body as Partial<PinnedChatModel>;
    if (body) store.commit("ChatModule/addPinnedMessage", body);
  },

  receiveUnpin(payload: ChatPayload): void {
    const body = payload.body as ChatIdModel;
    if (body.messageId) store.commit("ChatModule/removePinnedMessage", { messageId: body.messageId });
  },

  populateChat(messages: Array<Partial<ChatModel>>, chatType: ChatType): void {
    const append = messages.map((chatModel) => {
      const name = `${chatModel.firstName} ${chatModel.lastName}`;
      const auth = getInstance();
      const authName = `${auth.authUser.given_name} ${auth.authUser.family_name}`;
      const isSelf = authName === name;
      chatModel.message = chatUtil.formatMessage(chatModel.message);
      chatModel.type = chatType;
      chatModel.self = isSelf;
      if (chatModel.sent) {
        const date = new Date(chatModel.sent);
        chatModel.time = format(date, "h:mm aa");
      }
      return chatModel;
    });
    append.sort((a, b) => {
      if (a.sent && b.sent) {
        return new Date(b.sent).getTime() - new Date(a.sent).getTime();
      }
      return 0;
    });
    let commitType: string | undefined = undefined;
    switch (chatType) {
      case ChatType.MAIN:
        commitType = "ChatModule/setMainMessages";
        break;
      case ChatType.BACKSTAGE:
        commitType = "ChatModule/setBackStageMessages";
        break;
      case ChatType.GROUP:
        commitType = "ChatModule/setGroupMessages";
        break;
      case ChatType.LOBBY:
        commitType = "ChatModule/setLobbyMessages";
        break;
    }
    if (commitType) {
      store.commit(commitType, append);
    }
  },

  receiveStartGroup(payload: ChatPayload): void {
    const model = payload.body as ChatGroupModel;
    if (model.messages && model.messages.length > 0) {
      this.populateChat(model.messages, ChatType.GROUP);
    }
    store.commit("ChatModule/setGroup", model);
  },
  receiveStopGroup(payload: ChatPayload): void {
    const model = payload.body as ChatGroupModel;
    console.log(model);
    store.commit("ChatModule/setGroup", model);
  },
  receiveRemoveGroup(payload: ChatPayload): void {
    const model = payload.body as ChatGroupModel;

    if (model.participants && model.participants?.length < 1) {
      console.log("Stopping group chat, no participants remain: " + model.groupId);
      store.dispatch("ChatModule/groupStop", model.groupId);
    } else {
      const securityId = authorization.mySecurityId;
      if (model.participants) {
        model.inGroup = model.participants.find((e) => e.id == securityId) != undefined;
      }
    }
    console.log(model);
    store.commit("ChatModule/setGroup", model);
  },
};
export default chat;
