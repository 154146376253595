import { http } from "@/http";
import { EventContentModel } from "@cruciallearning/puddle";

const registration = {
  completeRegistration(
    backendUrl: string,
    id: string,
    errors: { error: string; step: string }[]
  ): Promise<EventContentModel | null> {
    const url = `${backendUrl}/learningEvent/${id}`;
    return http
      .post<EventContentModel>(`${url}/complete_registration`, { errors })
      .then((res) => res.data)
      .catch((err) => {
        console.error("error occurred completing registration", err);
        return null;
      });
  },
};
export default registration;
