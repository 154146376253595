<template lang="pug">
.broadcast-message-container
  .broadcast-message(v-if="broadcasting", :class="broadcastTransition")
    .broadcast-message-header
      Icon.cast-icon(value="cast")
      span Broadcast Message
      Icon.close-broadcast(value="x", @click="closeBroadcast")
    .chat-input
      TextArea(
        placeholder="Type Chat Here",
        :value="broadcast",
        valueChangeEvent="input",
        :maxLength="100",
        @valueChanged="messageUpdate",
        @enterKey="onSubmit"
      )
      ChatControls(type="broadcast", :onSubmit="onSubmit", @emojiClick="onEmojiClicked")
</template>
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { SetBreakoutsState } from "@/types";
import { mapActions, mapMutations, mapState } from "vuex";
import { BaseChatTextArea as TextArea } from "@cruciallearning/puddle";
import { Icon } from "@cruciallearning/puddle/components";
import ChatControls from "@/components/Chat/ChatControls.vue";

@Component({
  components: { Icon, TextArea, ChatControls },
  computed: {
    ...mapState("BreakoutsModule", ["isActive", "broadcasting"]),
  },
  methods: {
    ...mapMutations("BreakoutsModule", ["setBreakoutsState"]),
    ...mapActions("BreakoutsModule", ["broadcastMessage"]),
  },
})
export default class BroadcastInput extends Vue {
  private readonly setBreakoutsState!: SetBreakoutsState;
  private readonly broadcastMessage!: (message: string) => void;

  private readonly broadcasting!: boolean;

  private broadcast = "";
  private broadcastTransition = "";

  onSubmit(): void {
    this.broadcastMessage(this.broadcast);
    this.broadcast = "";
    this.closeBroadcast();
  }

  closeBroadcast(): void {
    this.broadcastTransition = "broadcast-slide-out";
    setTimeout(() => {
      this.setBreakoutsState({ broadcasting: false });
    }, 300);
  }

  onEmojiClicked(emoji: string): void {
    this.broadcast += emoji;
  }

  messageUpdate(update: string): void {
    this.broadcast = update;
  }
  @Watch("broadcasting")
  broadcastingChange(broadcasting: boolean): void {
    if (broadcasting) {
      this.broadcastTransition = "broadcast-slide-up";
    }
  }
}
</script>
<style scoped lang="scss">
.broadcast-message-container {
  background-color: #808080;
}
.broadcast-message {
  width: 100%;
  z-index: 10;
  pointer-events: all;
  filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  bottom: -200px;
}
.broadcast-slide-up {
  bottom: 0px;
  animation: slideInputUp 0.3s linear;
}

.broadcast-slide-out {
  animation: slideInputOut 0.3s linear;
}
.broadcast-message-header {
  font-weight: bold;
  border-bottom: 2px solid black;
  padding-left: 16px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: white;
}
.broadcast-message-header span {
  text-transform: uppercase;
  font-size: 0.85rem;
}
.cast-icon ::v-deep svg path {
  fill: black;
}
span.close-broadcast {
  float: right;
  vertical-align: middle;
  margin: 2px;
}
.close-broadcast ::v-deep svg path {
  fill: var(--gray-50);
}
.broadcast-message-header ::v-deep span:has(svg) {
  vertical-align: middle;
}
.broadcast-message-header ::v-deep svg {
  height: 0.9rem;
  width: 0.9rem;
}
.close-broadcast:hover {
  ::v-deep svg path {
    fill: var(--gray-80);
  }
}

@keyframes slideInputUp {
  0% {
    bottom: -200px;
  }
  100% {
    bottom: 0px;
  }
}

@-webkit-keyframes slideInputUp {
  0% {
    bottom: -200px;
  }
  100% {
    bottom: 0px;
  }
}
@keyframes slideInputOut {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: -200px;
  }
}

@-webkit-keyframes slideInputOut {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: -200px;
  }
}
</style>
