import { StreamPayload } from "@/types";
import store from "@/store";
import socket from "@/services/socket";
import routes from "@/services/routes";

const stream = {
  updateStreamId(streamId: string): void {
    socket.send({ type: routes.VONAGE_STREAM_ID, streamId });
  },
  receive(message: StreamPayload): void {
    store.dispatch("update", {
      streamSessionId: message.body.sessionId,
      streamToken: message.body.token,
      breakoutVonageToken: "",
      breakoutSessionId: "",
    });
  },
};
export default stream;
