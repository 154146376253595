<template lang="pug">
.breakout-slide
  img(:src="imagePreview")
  .enlarge-slide(@click="showModal = true")
    i.icon-maximize-2
  .modal-overlay.pointer(v-show="showModal", @click="showModal = false")
    header.modal-header
      .shrink-slide
        i.icon-maximize-2
    img(:src="imagePreview")
</template>

<script lang="ts">
import { Group } from "@/types";
import { GetSlideByTitle } from "@/types/course";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";

@Component({
  computed: {
    ...mapState("CourseModule", ["activeContentId"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapGetters("CourseModule", ["getSlideByTitle"]),
  },
})
export default class BreakoutSlide extends Vue {
  private readonly activeContentId!: string;
  private readonly getActiveBreakoutSelf!: Group | undefined;
  private readonly getSlideByTitle!: GetSlideByTitle;

  private showModal = false;

  get imagePreview(): string {
    return (
      this.getSlideByTitle(this.getActiveBreakoutSelf?.slide || this.activeContentId)?.frames[0].content?.image.url ||
      ""
    );
  }
}
</script>

<style scoped>
img {
  height: 90%;
  object-fit: contain;
}

.enlarge-slide {
  bottom: 12px !important;
}

.modal-overlay {
  top: 64px;
}
</style>
