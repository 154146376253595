<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Media Playback
  .system-check-card-contents.text
    .video-container
      BaseVideo(v-if="content", :content="content")
    span Are you able to see and hear this video? (Make sure your volume is turned up)
    .confirmation-buttons
      Button(text="No", primary, small, dataName="media-playback-button-no", @click="failActive(message)")
      Button(text="Yes, Next", primary, small, dataName="media-playback-button-yes", @click="passActive")
    Status
    #warning-message(v-if="isActiveFailed")
      span(v-html="htmlMessage")
      BaseTip(:tip-message="htmlTip")
</template>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep.vue";

import { mapState } from "vuex";
import BaseVideo from "@/components/Video/BaseVideo.vue";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";
import { Course, Video } from "@/types/course";

@Component({
  components: {
    BaseTip,
    BaseVideo,
  },
  computed: {
    ...mapState("CourseModule", ["course"]),
  },
})
export default class MediaPlayback extends BaseStep {
  private readonly course!: Course;

  get content(): Video | undefined {
    return this.course.lessons
      .flatMap((lesson) => lesson.slides)
      .filter((slide) => slide != undefined)
      .flatMap((slide) => slide.frames)
      .filter((frame) => frame.video)
      .map((frame) => frame.video)[0];
  }

  get htmlMessage(): string {
    return `Video not working.`;
  }
  get htmlTip(): string {
    return `Make sure your volume is not muted (check computer controls and individual device controls).`;
  }
  get message(): string {
    return this.failedMessage([this.htmlMessage, this.htmlTip]);
  }
}
</script>
<style lang="scss" scoped>
.video-container {
  width: 500px;
  height: 350px;
  margin-bottom: 1rem;
}
::v-deep .pointer-event {
  pointer-events: auto;
}
.text {
  font-size: 0.85rem;
}
::v-deep .step-status {
  margin-left: -1px;
}
</style>
