import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { VideoOverlay, VideoPayload } from "@/types/video";

const video = {
  play(position: number): void {
    socket.send({ type: routes.VIDEO_PLAY, position: position });
  },
  overlay(videoOverlay: VideoOverlay): void {
    socket.send({ type: routes.VIDEO_OVERLAY, state: videoOverlay });
  },
  pause(): void {
    socket.send({ type: routes.VIDEO_PAUSE });
  },
  receive(payload: VideoPayload): void {
    switch (payload.type) {
      case routes.VIDEO_PLAY:
        store.dispatch("VideoModule/playVideo", payload.body);
        break;
      case routes.VIDEO_OVERLAY:
        store.dispatch("VideoModule/overlay", payload.body.state);
        break;
      case routes.VIDEO_PAUSE:
        store.dispatch("VideoModule/pauseVideo", payload.body.senderId);
        break;
      case routes.VIDEO_INFO:
        console.log("video info");
        break;
      default:
        console.log("Invalid video message");
        break;
    }
  },
};
export default video;
