<template lang="pug">
header.site-masthead.container.header-white
  .site-logo
    img(src="@/assets/images/logos/cl-mark.svg", alt="Crucial Learning Logo")
  h4 {{ headerTitle }}
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class SimpleHeader extends Vue {
  @Prop({ required: false, default: "" }) headerTitle!: string;
}
</script>
<style lang="scss" scoped>
h4 {
  font-family: "HCo Chronicle SSm";
}
</style>
