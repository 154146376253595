<template lang="pug">
Popup.confirm-end.mb-20(
  height="380px",
  width="550px",
  closeButton,
  cancelDisabled=false,
  ok="End Session Now",
  cancel="I'm Still Here",
  :closeOnOkClick="true",
  :closeOnCancelClick="true",
  onTitleRendered="false",
  :visible="showStaleSession",
  @cancel="extendSession",
  @ok="stopSession",
  @hiding="hideStaleSession"
)
  p.large <strong>Are you still there?<br>We've noticed your session has been idle for a while.</strong>
  p.large.mt-36 <strong>Session will end in {{ interval }} seconds.</strong>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { BaseButton as Button, BasePopup as Popup } from "@cruciallearning/puddle";
import { mapActions, mapMutations, mapState } from "vuex";
import { RemoveAllFromClassroom, SetRootState, StopClassroom } from "@/types";

@Component({
  components: { Popup, Button },
  computed: {
    ...mapState(["isClassroomStarted"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
    ...mapActions(["stopClassroom", "extendSession", "removeAllFromClassroom"]),
  },
})
export default class StaleSession extends Vue {
  private readonly stopClassroom!: StopClassroom;
  private readonly removeAllFromClassroom!: RemoveAllFromClassroom;
  private readonly isClassroomStarted!: boolean;
  private readonly setRootState!: SetRootState;
  private interval = 60;
  private timeout?: NodeJS.Timeout;

  private stopSessionInterval = 3;
  private stopSessionTimeout?: NodeJS.Timeout;
  private showStaleSession = true;

  mounted(): void {
    this.timeout = setInterval(() => {
      this.interval--;
      if (this.interval <= 0) this.stopSession();
    }, 1000);
  }

  destroyed(): void {
    clearInterval(this.timeout as NodeJS.Timeout);
    this.timeout = void 0;
    clearInterval(this.stopSessionTimeout as NodeJS.Timeout);
    this.stopSessionTimeout = void 0;
  }

  stopSession(): void {
    if (this.isClassroomStarted) {
      this.stopClassroom();
      this.stopSessionTimeout = setInterval(() => {
        console.log("Waited for classroom to stop");
      }, 3000);
    }
    this.setRootState({ isStaleSession: false });
    this.removeAllFromClassroom();
  }

  hideStaleSession(): void {
    this.showStaleSession = false;
  }
}
</script>
<style lang="scss" scoped>
.idle-message > p {
  font-size: 1.5rem;
}
.btn-margins {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.button {
  padding: 12px 11px !important;
}
</style>
