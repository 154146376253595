import { GlobalIconModel, IconState } from "@/types/icon";
import { Module } from "vuex";

const IconModule: Module<IconState, unknown> = {
  namespaced: true,
  state() {
    return {
      icons: [],
      courseIcons: [],
    };
  },
  getters: {
    getIcon(state: IconState): (title: string) => GlobalIconModel | undefined {
      return (title: string) => state.icons.find((e) => e.title === title);
    },
  },
  mutations: {
    setIconState(state: IconState, changes: Partial<IconState>): void {
      Object.assign(state, changes);
    },
    addGlobalIcons(state: IconState, icons: GlobalIconModel[]): void {
      state.icons.push(...icons);
    },
  },
};
export default IconModule;
