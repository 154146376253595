<template lang="pug">
.system-check 
  Header(headerTitle="Pre-Registration")
  .system-check-contents
    Stepper
    Step
  Footer
</template>
<script lang="ts">
import Header from "@/components/Header/SimpleHeader.vue";
import Stepper from "@/components/SystemCheck/StepsSideBar.vue";
import Step from "@/components/SystemCheck/SelectedStep.vue";
import Footer from "@/components/SystemCheck/StepFooter.vue";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Connect, SetRootState } from "@/types";
import { mapActions, mapMutations, mapState } from "vuex";
import { LocalStorageFields } from "@/types/base";

@Component({
  components: { Header, Stepper, Step, Footer },
  computed: {
    ...mapState(["isConnected"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
    ...mapActions(["connect"]),
  },
})
export default class SystemCheck extends Vue {
  private readonly setRootState!: SetRootState;
  private readonly connect!: Connect;
  created(): void {
    window.history.replaceState({}, document.title, "/system-check");
    const eventId = localStorage.getItem(LocalStorageFields.EVENT_ID);
    if (eventId) this.setRootState({ trainingEventId: eventId });
  }
  mounted(): void {
    this.connect();
    navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  }

  @Watch("isConnected")
  joinOnConnect(isConnected: boolean) {
    if (isConnected) this.$sockets?.classroom.connect();
  }
}
</script>
<style scoped lang="scss">
.system-check-contents {
  display: flex;
  height: calc(100% - 68px - 1rem);
  overflow-y: hidden;
}
</style>
