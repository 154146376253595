<template lang="pug">
.system-check-card
  h4.intro-header System Check
  .system-check-card-contents
    p.text Review each item on the left to confirm your system is ready. If it's not, we'll give you some tips to help you know what to do to have the best experience.
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
@Component
export default class Landing extends Vue {}
</script>
<style scoped lang="scss">
.intro-header {
  font-size: 2rem;
}
h4 {
  font-family: "HCo Chronicle SSm";
}
.text {
  font-size: 0.85rem;
}
</style>
