<template lang="pug">
.presentation-sidebar.layout-grid.row-min-content.dark-mode(:class="[columnClass]")
  ResizeBar(:column="resizeOption")
  .no-padding
    BreakoutSlide(v-show="getActiveBreakoutSelf")
    ChatList
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import BreakoutSlide from "./Breakouts/BreakoutSlide.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import ResizeBar from "../GridResize/GridResizeBar.vue";
import ChatList from "./ChatList/BaseChatList.vue";
import { ResizingOption, SetGridState } from "@/store/modules/Grid/models";
import { LocalStorageFields } from "@/types/base";

@Component({
  components: { BreakoutSlide, ResizeBar, ChatList },
  computed: {
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapState("GridModule", ["chatCols"]),
  },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
  },
})
export default class ChatColumn extends Vue {
  private readonly chatCols!: number;
  private readonly setGridState!: SetGridState;

  created(): void {
    const chatSidebarCols = localStorage.getItem(LocalStorageFields.CHAT_COLS);
    const cols = chatSidebarCols ? Number(chatSidebarCols) : 2;
    this.setGridState({ chatCols: cols });
  }
  get columnClass(): string {
    return `cell-${this.chatCols}`;
  }
  get resizeOption(): ResizingOption {
    return ResizingOption.CHAT;
  }
}
</script>
