import { Module } from "vuex";
import { RootState, SidebarAdminTabs, SidebarState, SidebarUserTabs, SideViewType } from "@/types";

const SidebarModule: Module<SidebarState, RootState> = {
  namespaced: true,
  state(): SidebarState {
    return {
      adminSideView: SideViewType.NONE,
      adminTab: SidebarAdminTabs.SLIDES,
      userTab: SidebarUserTabs.ATTENDEES,
    };
  },
  getters: {
    getUserTab(state: SidebarState): SidebarUserTabs {
      return state.userTab;
    },
  },
  mutations: {
    setSidebarState(state: SidebarState, sidebarStateOptions: Partial<SidebarState>): void {
      Object.assign(state, sidebarStateOptions);
    },
  },
  actions: {
    closeSideView({ commit }): void {
      commit("setSidebarState", { adminSideView: "" });
    },
    openSideView({ commit }, sideView: SideViewType): void {
      commit("setSidebarState", { adminSideView: sideView });
    },
    selectAdminTab({ commit }, tab: SidebarAdminTabs): void {
      commit("setSidebarState", { adminTab: tab });
    },
    selectUserTab({ commit }, tab: SidebarUserTabs): void {
      commit("setSidebarState", { userTab: tab });
    },
  },
};
export default SidebarModule;
