/* various streaming-media  and WebAudio/WebRTC-standards related utilities */

import { StorageKeys, StorageUtil } from "@/utils/StorageUtil";
import CommonUtil from "@/utils/CommonUtil";

// some union types used below for convenience
type Nil = null | undefined;
type ObjBool = object | boolean;
type ObjBoolNil = ObjBool | Nil;
type ObjNil = object | Nil;

class WebMediaUtil {
  /**
   * Fetch a MediaStream matching the given and elsewhere-configured constraints.
   *
   * Currently 3 partial configurations are merge together to form the final set
   * of MediaTrackConstraints. In priority (precedence) order:
   * 1. the constraints passed to this method
   * 2. those defined the localStorage (see getLocal[Audio|Video]Constraints)
   * 3. the default "base" configuration (see getBase[Audio|Video]Constraints)
   *
   * @see resolveConstraints
   */
  static async getUserMedia(
    videoConstraints: ObjBoolNil = null,
    audioConstraints: ObjBoolNil = null
  ): Promise<MediaStream> {
    const constraints: object = this.resolveConstraints(videoConstraints, audioConstraints);
    console.log(CommonUtil.jsonppp("WebMediaUtil.getUserMedia() constraints: ", constraints));
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  /**
   * Generates a constraints object suitable for passing to MediaDevices.getUserMedia.
   * @see getUserMedia
   */
  static resolveConstraints(videoConstraints: ObjBoolNil = null, audioConstraints: ObjBoolNil = null): object {
    return {
      video: this._resolveMediaSubtypeConstraints(
        this.getBaseVideoConstraints(),
        this.getLocalVideoConstraints(),
        videoConstraints
      ),
      audio: this._resolveMediaSubtypeConstraints(
        this.getBaseAudioConstraints(),
        this.getLocalAudioConstraints(),
        audioConstraints
      ),
    };
  }

  /**
   * Generates a MediaTrackConstraints-style dictionary object suitable for
   * use in `MediaStreamTrack.applyConstraints` or (when wrapped)
   * `MediaDevices.getUserMedia`.
   *
   * @param base the default constraints for this subtype (video or audio)
   * @param given optional overriding constraints for this subtype (video or audio)
   */
  static _resolveMediaSubtypeConstraints(base: object, local: ObjNil, given: ObjBoolNil): ObjBool {
    let result: ObjBool;
    if (typeof given === "boolean") {
      result = given;
    } else {
      result = CommonUtil.merge(base, local ?? {}, given ?? {});
      result = CommonUtil.stripNullAndUndefinedValues(result);
    }
    return result;
  }

  static getBaseVideoConstraints(): object {
    return {};
  }

  static getBaseAudioConstraints(): object {
    return {};
  }

  //-------------------------------------------------------------------------//

  /*
   * localStorage container for custom MediaTrackConstraint configurations.
   *
   * When not enabled, we fall back to pre-existing logic for managing the
   * MediaDevices and OT.Publisher settings.
   *
   * NOTE: These and similar methods are meant to be invoked from the JS console.
   */

  static setLocalVideoConstraints(constraints: ObjNil): void {
    if (constraints === null || constraints === undefined) {
      this.removeLocalVideoConstraints();
    } else {
      StorageUtil.setJsonItem(localStorage, StorageKeys.X_VIDEO_CONSTRAINTS, constraints);
    }
  }

  static setLocalAudioConstraints(constraints: ObjNil): void {
    if (constraints === null || constraints === undefined) {
      this.removeLocalAudioConstraints();
    } else {
      StorageUtil.setJsonItem(localStorage, StorageKeys.X_AUDIO_CONSTRAINTS, constraints);
    }
  }

  static getLocalVideoConstraints(): ObjNil {
    return StorageUtil.getJsonItem(localStorage, StorageKeys.X_VIDEO_CONSTRAINTS);
  }

  static getLocalAudioConstraints(): ObjNil {
    return StorageUtil.getJsonItem(localStorage, StorageKeys.X_AUDIO_CONSTRAINTS);
  }

  static removeLocalVideoConstraints(): void {
    localStorage.removeItem(StorageKeys.X_VIDEO_CONSTRAINTS);
  }

  static removeLocalAudioConstraints(): void {
    localStorage.removeItem(StorageKeys.X_AUDIO_CONSTRAINTS);
  }

  static removeLocalMediaConstraints(): void {
    this.removeLocalVideoConstraints();
    this.removeLocalAudioConstraints();
  }

  //-------------------------------------------------------------------------//

  static getLocalPublisherProperties(): ObjNil {
    return StorageUtil.getJsonItem(localStorage, StorageKeys.X_OT_PUBLISHER_PROPERTIES);
  }

  static setLocalPublisherProperties(properties: ObjNil): void {
    if (properties === null || properties === undefined) {
      this.removeLocalPublisherProperties();
    } else {
      StorageUtil.setJsonItem(localStorage, StorageKeys.X_OT_PUBLISHER_PROPERTIES, properties);
    }
  }

  static removeLocalPublisherProperties(): void {
    localStorage.removeItem(StorageKeys.X_OT_PUBLISHER_PROPERTIES);
  }

  //-------------------------------------------------------------------------//

  /*
   * localStorage flag for enabling/disabling this experimental functionality.
   *
   * When not enabled, we fall back to pre-existing logic for managing the
   * MediaDevices and OT.Publisher settings.
   *
   * NOTE: These and similar methods are meant to be invoked from the JS console.
   */

  static setClexp5532Enabled(val: boolean): void {
    localStorage.setItem(StorageKeys.X_CLEXP_5532_ENABLED, `${val}`);
  }

  static getClexp5532Enabled(): boolean {
    return CommonUtil.truthyString(localStorage.getItem(StorageKeys.X_CLEXP_5532_ENABLED));
  }

  static removeClexp5532Enabled(): void {
    localStorage.removeItem(StorageKeys.X_CLEXP_5532_ENABLED);
  }

  //-------------------------------------------------------------------------//

  /**
   * Removes all local settings associated with this experiment.
   */
  static removeAllLocalClexp5532Settings(): void {
    this.removeLocalMediaConstraints();
    this.removeLocalPublisherProperties();
    this.removeClexp5532Enabled();
  }

  //-------------------------------------------------------------------------//

  static debugLogSupportedConstraints(): void {
    console.log(
      CommonUtil.jsonppp("mediaDevices.getSupportedConstraints(): ", navigator.mediaDevices.getSupportedConstraints())
    );
  }

  static debugLogMediaStreamTrackCapabilities(track: MediaStreamTrack): void {
    console.log(CommonUtil.jsonppp("mediaStreamTrack.getCapabilities(): ", track.getCapabilities()));
  }

  static debugLogMediaStreamTrackSettings(track: MediaStreamTrack): void {
    console.log(CommonUtil.jsonppp("mediaStreamTrack.getSettings(): ", track.getSettings()));
  }

  static debugLogMediaStreamTrack(track: MediaStreamTrack): void {
    const payload: Record<string, object | string | boolean> = {};
    payload.kind = track.kind;
    payload.id = track.id;
    payload.label = track.label;
    payload.enabled = track.enabled;
    payload.muted = track.muted;
    payload.contentHint = track.contentHint;
    payload.readyState = track.readyState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload.stats = (track as any).stats;
    payload.capabilities = track.getCapabilities();
    payload.settings = track.getSettings();
    console.log(CommonUtil.jsonpp(payload));
  }

  static debugLogMediaStreamTracks(stream: MediaStream): void {
    const tracks: MediaStreamTrack[] = stream.getTracks();
    for (const i in tracks) {
      console.log("Track %d of %d:", +i + 1, tracks.length);
      this.debugLogMediaStreamTrack(tracks[i]);
      console.log("");
    }
  }
}

export default WebMediaUtil;
