/* localStorage/sessionStorage related utilities */

/**
 * Keys we used to store various data items.
 * (should eventually combine with `LocalStorageFields` in @/types/base.ts
 * in one direction or the other but these are experimental anyway so maybe
 * it's reasonable to keep them split for now)
 */
enum StorageKeys {
  // these `X_……` keys are experimental and are likely to be removed or renamed
  X_CLEXP_5532_ENABLED = "CL-X-CLEXP-5532-Enabled",
  X_VIDEO_CONSTRAINTS = "CL-X-MediaTrackConstraints-Video",
  X_AUDIO_CONSTRAINTS = "CL-X-MediaTrackConstraints-Audio",
  X_OT_PUBLISHER_PROPERTIES = "CL-X-OT_Publisher-Properties",
}

class StorageUtil {
  /**
   * Writes object-based data to the store as stringified JSON.
   * @param {Storage} store data store, typically `localStorage` or `sessionStorage`
   * @param {string} key name to store the value under
   * @param {object} value value to be stored (as stringified-JSON)
   */
  static setJsonItem(store: Storage, key: string, value: object): void {
    store.setItem(key, JSON.stringify(value));
  }

  /**
   * Reads stringified JSON items back as object-valued data.
   * @param {Storage} store data store, typically `localStorage` or `sessionStorage`
   * @param {string} key name the value is stored under
   * @returns the object deserialized from a JSON string
   * @throws SyntaxError if the stored value is not well-formed JSON
   */
  static getJsonItem(store: Storage, key: string): object | null | undefined {
    const strval: string | null = store.getItem(key);
    if (strval === null || strval === undefined) {
      return strval;
    } else {
      return JSON.parse(strval);
    }
  }
}

export { StorageUtil, StorageKeys };
