const mockdata = {
  users: [
    { firstName: "Kaydon", lastName: "Hogg" },
    { firstName: "Arnold", lastName: "Haigh" },
    { firstName: "Emily-Rose", lastName: "Fuller" },
    { firstName: "Edmund", lastName: "Lindsey" },
    { firstName: "Rachelle", lastName: "Kennedy" },
    { firstName: "Stefania", lastName: "Hopper" },
    { firstName: "Kairon", lastName: "Bonilla" },
    { firstName: "Glenda", lastName: "Barnes" },
    { firstName: "Abbigail", lastName: "Crossley" },
    { firstName: "Judy", lastName: "Shepard" },
    { firstName: "Sohail", lastName: "Andrade" },
    { firstName: "Imaani", lastName: "Castaneda" },
    { firstName: "Muhammed", lastName: "Buxton" },
    { firstName: "Mylee", lastName: "Choi" },
    { firstName: "Ernie", lastName: "Seymour" },
    { firstName: "Milla", lastName: "Storey" },
    { firstName: "Giulia", lastName: "Werner" },
    { firstName: "Theia", lastName: "Tyson" },
    { firstName: "Alara", lastName: "Sims" },
    { firstName: "Raiden", lastName: "Mcmahon" },
    { firstName: "Elyas", lastName: "Dale" },
    { firstName: "Devante", lastName: "Mora" },
    { firstName: "Amira", lastName: "Churchill" },
    { firstName: "Imani", lastName: "Sweet" },
    { firstName: "Sidney", lastName: "Mcmillan" },
    { firstName: "Colton", lastName: "Edge" },
    { firstName: "Rayyan", lastName: "Taylor" },
    { firstName: "Rihanna", lastName: "Grant" },
    { firstName: "Madelaine", lastName: "Pierce" },
    { firstName: "Tye", lastName: "Dyer" },
    { firstName: "Norah", lastName: "Witt" },
    { firstName: "Davey", lastName: "Nielsen" },
    { firstName: "Ashlee", lastName: "Macleod" },
    { firstName: "Rachael", lastName: "Gilbert" },
    { firstName: "Aden", lastName: "Lee" },
    { firstName: "Byron", lastName: "Herbert" },
    { firstName: "Hafsa", lastName: "Farrell" },
    { firstName: "Lilli", lastName: "Daniel" },
    { firstName: "Petra", lastName: "Jordan" },
    { firstName: "Beauden", lastName: "Mejia" },
    { firstName: "Gus", lastName: "Sheppard" },
    { firstName: "Brent", lastName: "Ratcliffe" },
    { firstName: "Amman", lastName: "Payne" },
    { firstName: "Azra", lastName: "Bateman" },
    { firstName: "Mackenzie", lastName: "Riley" },
    { firstName: "Rivka", lastName: "Fitzgerald" },
    { firstName: "Rita", lastName: "Fitzpatrick" },
    { firstName: "Jorden", lastName: "Needham" },
    { firstName: "Cara", lastName: "Cochran" },
    { firstName: "Gavin", lastName: "Dennis" },
    { firstName: "Kenny", lastName: "Preece" },
    { firstName: "Bonnie", lastName: "Schaefer" },
    { firstName: "Benn", lastName: "Bains" },
  ],
  connectionIds: [
    "PSUJHQQhbs",
    "hcLeDglrL1",
    "JearsbTxDt",
    "uJWOisET0r",
    "DH2L2yAsHn",
    "sDFPaZUfVS",
    "uTENVHnQMM",
    "haDj2KiXFB",
    "YburYnalPX",
    "a37YoTuDns",
    "yuKKb3adMw",
    "KqPmMp6MqR",
    "raRFnF1yFu",
    "aIVXLs0upp",
    "2fl4HDxEBw",
    "1OBdp0UdWa",
    "ZMMN607NLD",
    "5hicOV0bW6",
    "EbFFtLdp33",
    "VEHPGQMa7U",
    "VeBhFAY4Mo",
    "7i1a4nFWxA",
    "8AZxAYaUSC",
    "IGGujTl216",
    "E5JnquLXNx",
    "6GvdrqRfQA",
    "pqgXxtNI7d",
    "TOOB0h7aRl",
    "d4O2vT0Dx0",
    "iNZbo0UAyo",
    "g44Op7v5l8",
    "dGQjhnuEs4",
    "SmbC5SqtHs",
    "PeHF5Wymqz",
    "dmqT8K7iY3",
    "XnbxxO7yJZ",
    "EBxlhKiZXn",
    "VdVTiJfVce",
    "RprdWEkK3A",
    "ExAqYTXeDu",
    "hB3LVF75RZ",
    "YjkR7y5gMh",
    "OAsEF0mdnY",
    "EoxvKcdrmx",
    "SpAABKyLBa",
    "Uct6zjGLW2",
    "8wahe2yZzQ",
    "MloHs1fFM1",
    "Fy9MMsWBtj",
    "RhLWhpMRv8",
    "QBw4eGRpNd",
    "MZDW6YCNLe",
    "Z79OevXbQM",
  ],
  securityIds: [
    "8ae623b2-7af0-11ec-90d6-0242ac120003",
    "8ae625a6-7af0-11ec-90d6-0242ac120003",
    "8ae626be-7af0-11ec-90d6-0242ac120003",
    "8ae627cc-7af0-11ec-90d6-0242ac120003",
    "8ae628e4-7af0-11ec-90d6-0242ac120003",
    "8ae62c2c-7af0-11ec-90d6-0242ac120003",
    "8ae62d30-7af0-11ec-90d6-0242ac120003",
    "8ae62e34-7af0-11ec-90d6-0242ac120003",
    "8ae62f2e-7af0-11ec-90d6-0242ac120003",
    "8ae63028-7af0-11ec-90d6-0242ac120003",
    "8ae63136-7af0-11ec-90d6-0242ac120003",
    "8ae6323a-7af0-11ec-90d6-0242ac120003",
    "8ae6332a-7af0-11ec-90d6-0242ac120003",
    "8ae6360e-7af0-11ec-90d6-0242ac120003",
    "8ae6371c-7af0-11ec-90d6-0242ac120003",
    "8ae63820-7af0-11ec-90d6-0242ac120003",
    "8ae63924-7af0-11ec-90d6-0242ac120003",
    "8ae63a1e-7af0-11ec-90d6-0242ac120003",
    "8ae63b18-7af0-11ec-90d6-0242ac120003",
    "8ae63c12-7af0-11ec-90d6-0242ac120003",
    "8ae63d0c-7af0-11ec-90d6-0242ac120003",
    "8ae63f82-7af0-11ec-90d6-0242ac120003",
    "8ae64086-7af0-11ec-90d6-0242ac120003",
    "8ae64180-7af0-11ec-90d6-0242ac120003",
    "8ae6427a-7af0-11ec-90d6-0242ac120003",
    "8ae645b8-7af0-11ec-90d6-0242ac120003",
    "8ae646e4-7af0-11ec-90d6-0242ac120003",
    "8ae647de-7af0-11ec-90d6-0242ac120003",
    "8ae64aa4-7af0-11ec-90d6-0242ac120003",
    "8ae64ba8-7af0-11ec-90d6-0242ac120003",
    "8ae64ca2-7af0-11ec-90d6-0242ac120003",
    "8ae64d92-7af0-11ec-90d6-0242ac120003",
    "8ae64e82-7af0-11ec-90d6-0242ac120003",
    "8ae64f68-7af0-11ec-90d6-0242ac120003",
    "8ae65058-7af0-11ec-90d6-0242ac120003",
    "8ae6527e-7af0-11ec-90d6-0242ac120003",
    "8ae6536e-7af0-11ec-90d6-0242ac120003",
    "8ae6545e-7af0-11ec-90d6-0242ac120003",
    "8ae6554e-7af0-11ec-90d6-0242ac120003",
    "8ae65634-7af0-11ec-90d6-0242ac120003",
    "8ae6572e-7af0-11ec-90d6-0242ac120003",
    "8ae65936-7af0-11ec-90d6-0242ac120003",
    "8ae65a26-7af0-11ec-90d6-0242ac120003",
    "8ae65b16-7af0-11ec-90d6-0242ac120003",
    "8ae65c06-7af0-11ec-90d6-0242ac120003",
    "8ae65cec-7af0-11ec-90d6-0242ac120003",
    "8ae65ddc-7af0-11ec-90d6-0242ac120003",
    "8ae65ed6-7af0-11ec-90d6-0242ac120003",
    "8ae6614c-7af0-11ec-90d6-0242ac120003",
    "8ae66246-7af0-11ec-90d6-0242ac120003",
    "8ae66336-7af0-11ec-90d6-0242ac120003",
    "8ae66426-7af0-11ec-90d6-0242ac120003",
    "8ae66534-7af0-11ec-90d6-0242ac120003",
  ],
};
export default mockdata;
