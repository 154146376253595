<template lang="pug">
.presentation-confirm-container
  .close-presentation-confirm-button
    Icon.fitted(value="x", @click="onClose")
  .presentation-confirm-container-spacer
  .presentation-confirm-content
    .presentation-confirm-icon
      Icon.fitted(value="check")
    .presentation-confirm-text
      span Presentation View
      span You may advance slides with arrow keys or presentation remote.
</template>
<script lang="ts">
import { SetGridState } from "@/store/modules/Grid/models";
import { Icon } from "@cruciallearning/puddle/components";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
@Component({
  components: { Icon },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
  },
})
export default class ConfirmPresentationMode extends Vue {
  private readonly setGridState!: SetGridState;
  onClose(): void {
    this.setGridState({ confirmPresentation: false });
  }
}
</script>
<style lang="scss" scoped>
.presentation-confirm-container {
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  width: 304px;
  overflow: hidden;
  margin-right: 8px;
  position: relative;
}
.presentation-confirm-icon {
  padding: 16px;
  ::v-deep {
    svg {
      background-color: var(--success-50);
      border-radius: 100%;
      height: 24px;
      width: 24px;
      padding: 4px;
      path {
        fill: white;
      }
    }
  }
}
.presentation-confirm-content {
  display: flex;
}
.presentation-confirm-text {
  padding: 16px 16px 16px 0;
  display: flex;
  flex-direction: column;
  span {
    color: #192d39;
    font-size: 16px;
  }
  span:first-child {
    font-weight: 700;
    line-height: normal;
  }
  span:last-child {
    font-style: normal;
    line-height: 20px;
  }
}
.presentation-confirm-container-spacer {
  background-color: var(--success-50);
  width: 5px;
  border-radius: 6px 0px 0px 6px;
  height: 100%;
  position: absolute;
}
.close-presentation-confirm-button {
  position: absolute;
  right: 8px;
  top: 8px;
  ::v-deep {
    svg {
      height: 24px;
      width: 24px;
      path {
        fill: var(--gray-60);
      }
      &:hover {
        path {
          fill: var(--gray-40);
        }
      }
    }
  }
}
</style>
