<template lang="pug">
.resize-drag(@mousedown="mousedown")
  .resize-blanket(v-if="isResizing", @mouseup="mouseup")
  .resize-center
    .resize-marker
    .resize-marker
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class HorizontalResize extends Vue {
  @Prop({ required: true }) private readonly target!: string;
  private isResizing = false;
  mouseMove = (e: MouseEvent) => {
    const el = this.targetElement();
    if (el) {
      const elToTop = window.scrollY + el.getBoundingClientRect().top;
      const height = e.clientY - elToTop;
      el.style.height = `${Math.round(height)}px`;
    }
  };
  mousedown(): void {
    this.isResizing = true;
    document.addEventListener("mousemove", this.mouseMove);
  }
  mouseup(): void {
    document.removeEventListener("mousemove", this.mouseMove);
    this.isResizing = false;
  }

  targetElement(): HTMLElement | null {
    return document.getElementById(this.target);
  }
}
</script>
<style lang="scss" scoped>
.resize-drag {
  width: 100%;
  height: 0.4rem;
  background-color: var(--gray-95);
}
.resize-center {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.resize-drag:hover {
  background-color: var(--gray-90);
  cursor: ns-resize;
}
.resize-marker {
  border-radius: 10px;
  width: 2rem;
  height: 25%;
  margin: auto;
  background-color: var(--gray-30);
}
.resize-blanket {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  cursor: ns-resize;
}
</style>
