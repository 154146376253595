<template lang="pug">
.cast-learner-container
  .cast-button-content
    #cast-learner-button(
      v-if="!isPresentationMode",
      :class="{ active: isMenuVisible }",
      @click="isMenuVisible = !isMenuVisible"
    )
      span Display Options
    #close-presentation-mode(
      v-else,
      @mouseover="closePresentationTooltip = true",
      @mouseleave="closePresentationTooltip = false",
      @click="onPresentationMode"
    )
      Icon.fitted(value="minus-square")
    ToolTip(target="#close-presentation-mode", display="Close Presentation View", :toggle="closePresentationTooltip")
  PopoverMenu(
    target="#cast-learner-button",
    :visible="isMenuVisible",
    popoverPosition="top left",
    targetPosition="bottom left",
    popover-class="dropdown-menu",
    width="229",
    @hiding="isMenuVisible = false"
  )
    ul
      li.display-setting-row(@click="onPresentationMode", :class="{ disabled: isCasting }")
        Icon.fitted(color="white", value="Learning Format Virtual")
        span Presentation View
      li.display-setting-row(@click="onCast", :class="{ disabled: isPresentationMode }")
        Icon.fitted(color="white", value="cast")
        span {{ learnerWindowText }}
</template>
<script lang="ts">
import config from "@/config";
import { BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";
import { BrowserMessageEvent, MessageType, SendWindowMessage } from "@/types/browser";
import { Frame } from "@/types/course";
import { ActiveTimer } from "@/types/timer";
import { Icon } from "@cruciallearning/puddle/components";
import { getTimerStr } from "@cruciallearning/puddle/utils";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { SetGridState } from "@/store/modules/Grid/models";
import { clearNavigationBindings, setNavigationBindings } from "@/utils/KeyBindingUtil";
import { BaseToolTip as ToolTip } from "@cruciallearning/puddle";

@Component({
  components: { Icon, PopoverMenu, ToolTip },
  computed: {
    ...mapState("BrowserModule", ["learnerWindow"]),
    ...mapState("TimerModule", ["active"]),
    ...mapState("VideoModule", ["overlay"]),
    ...mapState("GridModule", ["isPresentationMode"]),
    ...mapState("CourseModule", ["showModel", "showAltModel"]),
    ...mapGetters("CourseModule", ["getActiveContent", "getLearnerPageNumber", "getModelUrl", "getAltModelUrl"]),
    ...mapGetters("BrowserModule", ["isCasting"]),
  },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
    ...mapActions("BrowserModule", ["openExtendedView", "closeExtendedView", "messageSecondDisplay"]),
  },
})
export default class CastDisplay extends Vue {
  private readonly isCasting!: boolean;
  private readonly overlay!: boolean;
  private readonly showModel!: boolean;
  private readonly showAltModel!: boolean;
  private readonly isPresentationMode!: boolean;
  private getModelUrl!: string;
  private getAltModelUrl!: string;
  private readonly active!: ActiveTimer;
  private getLearnerPageNumber!: number;
  private readonly getActiveContent!: Partial<Frame[]>;
  private readonly messageSecondDisplay!: SendWindowMessage;
  private readonly setGridState!: SetGridState;
  private readonly openExtendedView!: () => void;
  private readonly closeExtendedView!: () => void;
  closePresentationTooltip = false;

  isMenuVisible = false;

  get learnerWindowText(): string {
    return `${this.isCasting ? "Close" : "Expand"} Learner Window`;
  }

  onPresentationMode(): void {
    this.isMenuVisible = false;
    this.closePresentationTooltip = false;
    this.setGridState({
      isPresentationMode: !this.isPresentationMode,
      confirmPresentation: !this.isPresentationMode,
    });
  }

  onCast(): void {
    this.isMenuVisible = false;
    const messageListener = (event: BrowserMessageEvent) => {
      if (event.origin !== config.frostUrl) return;
      if (!event.isTrusted) return;
      const message = event.data.message;
      if (message) {
        const type = message.type;
        if (type == undefined) return;
        if (type == MessageType.LEARNER_WINDOW_CLOSED) {
          this.closeExtendedView();
        }
        if (type == MessageType.INITIALIZED) {
          this.contentUpdate();
          this.setOverlay();
        }
      }
    };

    const beforeUnload = () => {
      this.closeExtendedView();
    };
    if (this.isCasting) {
      window.removeEventListener("message", messageListener);
      window.removeEventListener("beforeunload", beforeUnload);
      this.closeExtendedView();
    } else {
      window.addEventListener("message", messageListener.bind(this));
      window.addEventListener("beforeunload", beforeUnload);
      this.openExtendedView();
    }
  }

  @Watch("getActiveContent")
  contentUpdate(): void {
    const url = this.getActiveContent[0]?.content?.image.url;
    if (url) {
      this.messageSecondDisplay({ type: MessageType.SLIDE_NAVIGATION, content: url });
      this.messageSecondDisplay({ type: MessageType.PAGE_NUMBER, content: `${this.getLearnerPageNumber}` });
    }
  }

  @Watch("showModel")
  @Watch("showAltModel")
  courseModel(): void {
    let imgUrl = "";
    if (!this.showAltModel && !this.showModel) imgUrl = this.getActiveContent[0]?.content?.image.url || "";
    else if (this.showModel) imgUrl = this.getModelUrl;
    else if (this.showAltModel) imgUrl = this.getAltModelUrl;
    this.messageSecondDisplay({ type: MessageType.SLIDE_NAVIGATION, content: imgUrl });
  }

  @Watch("active")
  setTimerDisplay(): void {
    let timeDisplay = "";
    if (this.active) {
      timeDisplay = getTimerStr(Math.round(this.active.time / 1000));
    }
    this.messageSecondDisplay({ type: MessageType.TIMER_UPDATE, content: `${timeDisplay}` });
  }

  @Watch("overlay")
  setOverlay(): void {
    const message = this.overlay ? MessageType.APPLY_OVERLAY : MessageType.REMOVE_OVERLAY;
    const providerId = this.getActiveContent[1]?.video?.providerId || "";
    this.messageSecondDisplay({ type: message, content: providerId });
  }
  @Watch("isPresentationMode")
  displayModeUpdate(isPresentationMode: boolean): void {
    if (isPresentationMode) {
      setNavigationBindings();
    } else {
      clearNavigationBindings();
    }
  }
}
</script>
<style lang="scss" scoped>
#cast-learner-button {
  border-radius: 5px;
  background: #313131;
  width: 229px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    background-color: var(--gray-60);
  }
  &:hover {
    cursor: pointer;
    background-color: var(--gray-60);
  }
  span {
    color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 750;
    line-height: 130%;
    text-transform: capitalize;
  }
}
#close-presentation-mode {
  background-color: var(--gray-80);
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: var(--gray-60);
  }
  ::v-deep {
    svg {
      path {
        fill: var(--text-color);
      }
    }
  }
}
</style>
