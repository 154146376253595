import store from "@/store";

export const setNavigationBindings = () => {
  document.body.addEventListener("keydown", onKeyDown);
};

export const clearNavigationBindings = () => {
  document.body.removeEventListener("keydown", onKeyDown);
};

const onKeyDown = (event: KeyboardEvent) => {
  const forwardKeys = ["Space", "ArrowRight", "PageDown", "ArrowDown", "Enter"];
  const reverseKeys = ["ArrowLeft", "PageUp", "ArrowUp"];

  if (document.activeElement?.tagName === "BODY") {
    const slideIndex = store.getters["CourseModule/getSlideIndex"];
    if (forwardKeys.indexOf(event.code) != -1) {
      event.preventDefault();
      store.dispatch("CourseModule/onNavigate", slideIndex + 1);
    } else if (reverseKeys.indexOf(event.code) != -1) {
      event.preventDefault();
      store.dispatch("CourseModule/onNavigate", slideIndex - 1);
    }
  }
};
