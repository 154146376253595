/**
 * Various network-related utility functions
 */
export default class NetworkUtil {
  /** @returns true IFF the browser is able to connect to a LAN or router */
  public static isOnline(): boolean {
    return window.navigator.onLine;
  }

  /**
   * Callback the given `handler` (at most once) when next online.
   *
   * If already {@link #isOnline}, calls-back immediately; else adds
   * `handler` as a one-time window.onOnline listener.
   */
  public static onceOnline(handler: EventHandler): void {
    if (NetworkUtil.isOnline()) {
      handler(new Event("online"));
    } else {
      window.addEventListener("online", handler, { once: true });
    }
  }

  /** remove a previously registered window.onOnline event handler */
  public static removeOnlineListener(handler: EventHandler): void {
    window.removeEventListener("online", handler);
  }
}

type EventHandler = (event: Event) => unknown;
