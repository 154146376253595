<template lang="pug">
.square-button-container(:class="[pressedClass, disabledClass]", @mousedown="onClick")
  Icon.fitted(:value="icon", :color="color", :height="size", :width="size")
</template>
<script lang="ts">
import { Icon } from "@cruciallearning/puddle/components";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: { Icon },
})
export default class SquareButton extends Vue {
  @Prop({ required: true, type: String }) icon!: string;
  @Prop({ required: true, type: String }) color!: string;
  @Prop({ required: false, type: String, default: "20px" }) size!: string;
  @Prop({ required: false, type: Boolean, default: false }) disabled!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) isPressed!: boolean;

  get pressedClass(): string {
    return this.isPressed ? "pressed" : "";
  }

  get disabledClass(): string {
    return this.disabled ? "disabled" : "";
  }
  onClick(): void {
    this.$emit("click");
  }
}
</script>
<style scoped lang="scss">
.square-button-container {
  background-color: rgba($color: #e5eaed, $alpha: 0);
  width: fit-content;
  border-radius: 4px;
  border: 1px solid rgba($color: #d6d6d6, $alpha: 0);
  padding: 7px;
}
.square-button-container:hover {
  background-color: #e5eaed;
  border-color: #d6d6d6;
  cursor: pointer;
}
.pressed,
.pressed:hover {
  background-color: #494949;
  ::v-deep {
    svg {
      path {
        fill: white;
      }
    }
  }
}
.disabled,
.disabled:hover {
  background-color: unset;
  ::v-deep {
    svg {
      path {
        fill: var(--gray-50);
      }
    }
  }
}
</style>
