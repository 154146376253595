<template lang="pug">
.contents-passthrough
  .inner-trainer-container
    .close-item(@click="closeView")
      i.icon-x
    .new-breakout-group.no-shrink
      p.back-stage-header
        i.icon-image.align-middle
        | Select Slide
    SlideSelect(@contentSelected="slide = $event")
  .panel-actions.no-bg
    Button(full, small, :disabled="!slide", text="Select", @click="onSelectClick")
    Button(full, small, ghost, text="Cancel", @click="closeView")
</template>

<script lang="ts">
import { OpenSideView, SideViewType, UpdateEditBreakout } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";
import { BaseButton as Button } from "@cruciallearning/puddle";
import SlideSelect from "@/components/Slides/SlideSelect.vue";

@Component({
  components: { Button, SlideSelect },
  methods: {
    ...mapActions("BreakoutsModule", ["updateEditBreakout"]),
    ...mapActions("SidebarModule", ["openSideView"]),
  },
})
export default class SelectSlide extends Vue {
  private slide = "";
  private sideViewType: unknown = SideViewType;
  private readonly openSideView!: OpenSideView;
  private readonly updateEditBreakout!: UpdateEditBreakout;

  closeView(): void {
    this.openSideView(SideViewType.BREAKOUT);
  }

  onSelectClick(): void {
    this.updateEditBreakout({ slide: this.slide });
    this.closeView();
  }
}
</script>
<style lang="scss" scoped>
.button {
  padding: 12px 11px !important;
}
.small {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
