import { ReactionUserModel } from "./reaction";
import { ReactionType } from "./status";

/*
 * ------------------------------------------------------------------------------
 * Enum
 * ------------------------------------------------------------------------------
 */
export enum UserRoles {
  MASTER_TRAINER = "Master Trainer",
  TRAINER = "Trainer",
  LEARNING_MANAGER = "Learning Manager",
  MODERATOR = "Moderator",
  LEARNER = "Learner",
  DEVELOPER = "Developer",
}

export enum MediaUpdateType {
  ALL = "all",
  LEARNERS = "learners",
  USER = "user",
}
/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */
export interface UsersState {
  users: Array<UserModel>;
  statuses: Array<ReactionUserModel>;
  videoUsers: Array<string>;
  trainerIds: Array<string>;
  moderatorIds: Array<string>;
  observerIds: Array<string>;
}
export interface UserModel {
  active: boolean;
  away: boolean;
  connectionId: string;
  firstName: string;
  lastName: string;
  ready: boolean;
  securityId: string;
  self: boolean;
  test: boolean;
  trainingEventId: string;
  vonageToken: string;
  waiting: boolean;
  observer: boolean;
}

/*
 * ------------------------------------------------------------------------------
 * Functions
 * ------------------------------------------------------------------------------
 */
export interface GetById {
  (securityId: string): UserModel | undefined;
}
export interface GetAllLearners {
  (): Array<UserModel>;
}
export interface GetLearners {
  (): Array<UserModel>;
}
export interface GetLobby {
  (): Array<UserModel>;
}
export interface GetModerators {
  (): Array<UserModel>;
}
export interface GetProducers {
  (): Array<UserModel>;
}
export interface GetTrainers {
  (): Array<UserModel>;
}
export interface GetAdded {
  (): Array<UserModel>;
}
export interface GetRemoved {
  (): Array<UserModel>;
}
export interface SetUsersState {
  (state: UsersState, usersStateOptions: Partial<UsersState>): void;
}
export interface SetUsers {
  (state: UsersState, users: Array<UserModel>): void;
}
export interface Mute {
  ({ type, securityId }: { type: MediaUpdateType; securityId?: string }): void;
}
export interface Shutter {
  ({ type, securityId }: { type: MediaUpdateType; securityId?: string }): void;
}

export interface ClearReaction {
  (securityId: string): void;
}
export interface MuteAll {
  (): void;
}

export interface ClearAdded {
  (): void;
}
export interface ClearRemoved {
  (): void;
}

export interface UpdateReactionStatus {
  (status: ReactionType): void;
}

export interface ClearAllReactions {
  (): void;
}

export interface ToggleVideoUser {
  (userId: string): void;
}

export interface ClearAllVideoUsers {
  (): void;
}
export interface AdmitObserver {
  (id: string): void;
}

export interface RejectObserver {
  (id: string): void;
}
