import { sockets } from "@/services/socket_api";
import store from "@/store";
import { getInstance } from "@cruciallearning/puddle/auth";

const log = (message: string, payload: unknown) => {
  console.log(message, payload);
  if (store.state.debugEnabled) {
    const securityId = getInstance().authUser.id;
    const cache: unknown[] = [];
    const payloadString = JSON.stringify(payload, (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (cache.includes(value)) return;
        cache.push(value);
      }
      return value;
    });
    const debugMessage = `DEBUG-${securityId}-${message}-${payloadString}`;
    sockets?.remoteLogger?.logMessage(debugMessage);
  }
};
export default log;
