<template lang="pug">
.char-counter-container 
  span(v-visible="showCount", :class="spanClass") {{ cnt }}/120
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class CharCounter extends Vue {
  @Prop({ required: false }) readonly message!: string;
  // -1 to disable
  @Prop({ required: false, default: 100 }) readonly hideUntil!: number;

  get cnt(): string {
    return `${this.length || 0}`;
  }
  get spanClass(): string {
    if (this.length >= 120) return "maxed-out";
    return "";
  }
  get length(): number {
    return this.message?.length || 0;
  }
  get showCount(): boolean {
    return this.hideUntil == -1 || this.length >= this.hideUntil;
  }
}
</script>
<style scoped lang="scss">
.char-counter-container {
  font-size: 12px;
  margin-top: 3px;
  width: 45px;
  float: left;
  color: var(--gray-30);
}
.char-counter-container span {
  margin: auto 2px;
}
.char-counter-container span.maxed-out {
  color: var(--error-20);
}
</style>
