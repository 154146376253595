<template lang="pug">
.tools-breakout
  .back-stage-header
    Icon.fitted(value="join-breakout", height="16px", width="16px", color="black")
    span Breakouts
    SquareButton.add-new-breakout-icon(icon="plus", color="var(--info-80)", @click="onAddNew")
  .tools-list
    ul
      BreakoutListItem(v-for="(breakout, index) in sortedBreakouts", :breakout="breakout", :key="breakout.id")
  BroadcastButton(v-show="!broadcasting")
</template>

<script lang="ts">
import { Breakout, OpenSideView, ResetEditBreakout, SideViewType } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import BreakoutListItem from "./BreakoutListItem.vue";
import BroadcastButton from "./BroadcastButton.vue";
import SquareButton from "@/components/widgets/SquareButton.vue";
import { Icon } from "@cruciallearning/puddle/components";

@Component({
  components: { BreakoutListItem, BroadcastButton, SquareButton, Icon },
  computed: { ...mapState("BreakoutsModule", ["breakouts", "broadcasting"]) },
  methods: {
    ...mapMutations("BreakoutsModule", ["resetEditBreakout"]),
    ...mapActions("SidebarModule", ["openSideView"]),
  },
})
export default class BaseBreakouts extends Vue {
  private readonly breakouts!: Breakout[];
  private readonly openSideView!: OpenSideView;
  private readonly resetEditBreakout!: ResetEditBreakout;

  get sortedBreakouts(): Breakout[] {
    return [...this.breakouts].sort((b1: Breakout, b2: Breakout) => {
      const b1Id = b1.id || "";
      const b2Id = b2.id || "";
      return b1Id.localeCompare(b2Id);
    });
  }

  onAddNew(): void {
    this.resetEditBreakout();
    this.openSideView(SideViewType.BREAKOUT);
  }
}
</script>
<style lang="scss" scoped>
.back-stage-header {
  display: flex;
  margin-bottom: unset;
}
.back-stage-header *:nth-child(-n + 2) {
  margin: auto 0;
}
.back-stage-header *:nth-child(2) {
  margin-left: 6px;
}
.add-new-breakout-icon {
  margin-left: auto;
}
</style>
