<template lang="pug">
.contents-passthrough
  .inner-trainer-container
    .close-item(@click="closeSideView")
      i.icon-x
    p.back-stage-header 
      i.icon-trello.align-middle
      | Confirm Choice
    .settings-set
      p This will remove this breakout permanently. Please confirm below.
  .panel-actions.no-bg
    Button(full, small, text="Remove", @click="onStopClick")
    Button(full, small, ghost, text="Cancel", @click="closeSideView")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import { CloseSideView, RemoveBreakout } from "@/types";
import { BaseButton as Button } from "@cruciallearning/puddle";

@Component({
  components: { Button },
  computed: { ...mapState("BreakoutsModule", ["removeId"]) },
  methods: {
    ...mapActions("BreakoutsModule", ["removeBreakout"]),
    ...mapActions("SidebarModule", ["closeSideView"]),
  },
})
export default class ConfirmRemove extends Vue {
  private readonly removeId!: string;
  private readonly closeSideView!: CloseSideView;
  private readonly removeBreakout!: RemoveBreakout;

  onStopClick(): void {
    this.removeBreakout(this.removeId);
    this.closeSideView();
  }
}
</script>

<style lang="scss" scoped>
.settings-set p {
  color: var(--gray-80);
  font-size: 0.8rem;
}
.button {
  padding: 12px 11px !important;
}
.small {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
