<template lang="pug">
.back-stage.presentation-sidebar.layout-grid.row-min-content(:class="[columnClass, broadcastClass]")
  .no-padding(v-if="!adminSideView")
    .sidebar-tabs(data-name="admin-sidebar-tabs")
      ul.list-unstyled
        li(
          v-for="item in tabs",
          :data-name="item.name",
          :class="{ active: adminTab === item.name, 'has-new': hasNew(item.name) }",
          @click="selectAdminTab(item.name)"
        )
          i(:class="item.icon")
    div(:class="activeClass")
      component(:is="adminTab", :type="chatType.BACKSTAGE")
    BroadcastInput
  .no-padding(v-else)
    component(:is="adminSideView")
  ResizeBar(:column="resizeOption")
</template>

<script lang="ts">
import { ChatType, GetIsBackstageNew, ResetIsBackstageNew, SidebarAdminTabs } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AutoAssign from "./Breakouts/Views/AutoAssign.vue";
import Breakout from "./Breakouts/Views/AddBreakouts.vue";
import Chat from "@/components/Chat/BaseChat.vue";
import Participants from "./Breakouts/Views/SelectParticipants.vue";
import Remove from "./Breakouts/Views/ConfirmRemove.vue";
import SelectSlide from "./Breakouts/Views/SelectSlide.vue";
import Tools from "./Tools/BaseTools.vue";
import ResizeBar from "../GridResize/GridResizeBar.vue";
import { ResizingOption } from "@/store/modules/Grid/models";
import BroadcastInput from "./Breakouts/BroadcastInput.vue";
import Slides from "../Slides/Slides.vue";

@Component({
  components: {
    AutoAssign,
    Breakout,
    BroadcastInput,
    Chat,
    Participants,
    Remove,
    ResizeBar,
    SelectSlide,
    Slides,
    Tools,
  },
  computed: {
    ...mapState("SidebarModule", ["adminSideView", "adminTab"]),
    ...mapState("GridModule", ["adminCols"]),
    ...mapState("BreakoutsModule", ["broadcasting"]),
    ...mapGetters("EventModule", ["backstageChatEnabled"]),
  },
  methods: {
    ...mapActions("SidebarModule", ["selectAdminTab"]),
    ...mapMutations("ChatModule", ["resetIsBackstageNew"]),
    ...mapGetters("ChatModule", ["getIsBackstageNew"]),
    ...mapMutations("BreakoutsModule", ["setBreakoutsState"]),
  },
})
export default class BaseSidebarAdmin extends Vue {
  private readonly adminTab!: SidebarAdminTabs;
  private readonly baseTabs: SideBarOption[] = [
    { name: SidebarAdminTabs.SLIDES, icon: "icon-layout", class: "slides expandable" },
    { name: SidebarAdminTabs.CHAT, icon: "icon-message-circle", class: "backstage-chat expandable" },
    { name: SidebarAdminTabs.TOOLS, icon: "icon-lightning", class: "tools expandable" },
  ];

  private readonly getIsBackstageNew!: GetIsBackstageNew;
  private readonly resetIsBackstageNew!: ResetIsBackstageNew;
  private readonly adminCols!: number;
  private readonly broadcasting!: boolean;
  private readonly backstageChatEnabled!: boolean;

  private chatType: unknown = ChatType;
  private sideView = "";

  get tabs(): SideBarOption[] {
    return this.baseTabs.filter((tab) => this.backstageChatEnabled || tab.name !== SidebarAdminTabs.CHAT);
  }

  get activeClass(): string | undefined {
    return this.tabs.find((item) => item.name === this.adminTab)?.class;
  }
  get columnClass(): string {
    return `cell-${this.adminCols}`;
  }

  get broadcastClass(): string {
    if (this.broadcasting) return "broadcasting-disable";
    return "";
  }

  get resizeOption(): ResizingOption {
    return ResizingOption.ADMIN;
  }

  hasNew(name: string): boolean {
    if (name === SidebarAdminTabs.CHAT) {
      if (this.adminTab === SidebarAdminTabs.CHAT) {
        this.resetIsBackstageNew();
      }
      return this.getIsBackstageNew();
    }
    return false;
  }
}
interface SideBarOption {
  name: SidebarAdminTabs;
  icon: string;
  class: string;
}
</script>
<style lang="scss" scoped>
.broadcasting-disable div div:nth-child(-n + 2) {
  background: white;
  pointer-events: none;
  filter: brightness(50%);
}
</style>
