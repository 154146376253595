<template lang="pug">
.contents-passthrough
  TextBox(small, label="Group Name", backgroundColor="white", :value="group.name", @valueChanged="onUpdateGroup")

  p.label Users ({{ group.users.length }})
  .breakout-participant-list(:class="group.users.length > 0 ? `filled` : ``")
    ul.list-unstyled
      li(v-for="user in groupUsers")
        .name.selection-none.capitalize
          p {{ user.firstName }} {{ user.lastName }}
            span.isAway(v-if="isAway(user)") &nbsp;(Away)
            span.isNotJoined(v-if="!isJoined(user)") &nbsp;(NOT JOINED)
            span.isTrainer(v-if="isTrainer(user)") &nbsp;(Trainer)
            span.isModerator(v-if="isModerator(user)") &nbsp;(Moderator)
        .actions
          i.icon-x.delete(
            @click="removeUsers({ breakoutId: editBreakout.id, groupId: group.id, userIds: [user.securityId] })"
          )
  .breakout-group-control
    a.add.pointer(@click="onAddUsers") Add Users
    a.delete.pointer(v-if="!isActive", @click="removeGroup({ breakoutId: editBreakout.id, groupId: group.id })") Delete Group
  br
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Breakout,
  BreakoutsState,
  Group,
  OpenSideView,
  SetBreakoutsState,
  SideViewType,
  UpdateGroup,
  UserModel,
} from "@/types";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { BaseTextBox as TextBox, EventContentModel } from "@cruciallearning/puddle";

@Component({
  components: { TextBox },
  computed: {
    ...mapState("BreakoutsModule", ["editBreakout", "isActive", "breakouts"]),
    ...mapState("EventModule", ["event"]),
    ...mapState("UsersModule", ["users"]),
    ...mapGetters("UsersModule", ["getTrainers", "getModerators", "isAway"]),
  },
  methods: {
    ...mapMutations("BreakoutsModule", ["setBreakoutsState"]),
    ...mapActions("BreakoutsModule", ["removeGroup", "removeUsers", "updateGroup"]),
    ...mapActions("SidebarModule", ["openSideView"]),
  },
})
export default class BreakoutGroup extends Vue {
  @Prop({ required: true }) readonly group!: Group;
  @Prop({ required: true }) readonly breakout!: Partial<Breakout>;

  private readonly editBreakout!: BreakoutsState["editBreakout"];
  private readonly event!: EventContentModel;
  private readonly breakouts!: Breakout[];

  private readonly openSideView!: OpenSideView;
  private readonly setBreakoutsState!: SetBreakoutsState;
  private readonly updateGroup!: UpdateGroup;
  private readonly getTrainers!: UserModel[];
  private readonly getModerators!: UserModel[];
  private readonly users!: UserModel[];

  onAddUsers(): void {
    this.setBreakoutsState({ activeGroupId: this.group.id });
    this.openSideView(SideViewType.PARTICIPANTS);
  }

  get groupUsers(): Partial<UserModel>[] {
    // Read saved group from store since prop group changes based on connected users []
    const persistedBreakout = this.breakouts.find((e) => e.id === this.breakout.id);
    const persistedGroup = persistedBreakout?.groups?.find((e) => e.id === this.group.id);
    const registrants = this.event.registrationCode.registrants;
    const users: Partial<UserModel>[] = [];
    const learners = registrants ? registrants.map((e) => ({ ...e })) : [];
    const trainers = this.getTrainers ? this.getTrainers.map((e) => ({ ...e })) : [];
    const mods = this.getModerators ? this.getModerators.map((e) => ({ ...e })) : [];
    users.push(...learners);
    users.push(...trainers);
    users.push(...mods);
    return users.filter((e) => persistedGroup?.userIds.findIndex((userId) => userId === e.securityId) != -1);
  }

  get isJoined(): (user: UserModel) => boolean {
    return (user: UserModel) => {
      return this.users.findIndex((e) => e.securityId === user.securityId) != -1;
    };
  }

  get isTrainer(): (user: Partial<UserModel>) => boolean {
    return (user: Partial<UserModel>) => {
      return this.getTrainers.find((e) => e.securityId == user.securityId) != undefined;
    };
  }

  get isModerator(): (user: Partial<UserModel>) => boolean {
    return (user: Partial<UserModel>) => {
      return this.getModerators.find((e) => e.securityId == user.securityId) != undefined;
    };
  }

  onUpdateGroup(value: string): void {
    if (value) {
      this.updateGroup({
        breakoutId: this.editBreakout?.id as string,
        groupId: this.group.id,
        name: value,
        slide: this.group.slide,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .capitalize {
  text-transform: capitalize;
}
</style>
