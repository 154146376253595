<template lang="pug">
.learner-guide-page-container(:class="pageNumClass")
  .learner-guide-page-num
    span {{ displayPage }}
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
@Component({
  computed: {
    ...mapGetters("CourseModule", ["getLearnerPageNumber"]),
  },
})
export default class LearnerGuidePage extends Vue {
  @Prop({ required: false }) pageOverride?: string;
  @Prop({ required: false, type: Boolean, default: false }) fixed!: boolean;

  private getLearnerPageNumber!: number;

  get displayPage(): string {
    return this.pageOverride || `${this.getLearnerPageNumber}`;
  }
  get pageNumClass(): string {
    if (this.fixed) return "fixed";
    return "scaled";
  }
}
</script>
<style scoped lang="scss">
.learner-guide-page-container {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 0px 15px 0px 0px;
  bottom: 0;
  left: 0;

  &.scaled {
    width: 5%;
    height: 7%;
    bottom: 0;
    .learner-guide-page-num {
      span {
        font-size: 1.75vw;
      }
    }
  }

  &.fixed {
    width: 50px;
    height: 50px;
    .learner-guide-page-num {
      span {
        font-size: 18px;
      }
    }
  }

  .learner-guide-page-num {
    height: 100%;
    width: 100%;
    display: flex;
    span {
      color: white;
      font-weight: bold;
      margin: auto;
    }
  }
}
</style>
