<template lang="pug">
.breakout-leave-container 
  .breakout-leave-btn(@click="onLeaveClick")
    Icon(value="leave-breakout", height="0.7rem", width="0.7rem", color="white")
    span.leave-room-txt Leave Breakout Room
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { Icon } from "@cruciallearning/puddle/components";
import { BaseButton } from "@cruciallearning/puddle";
import { mapMutations } from "vuex";
import { SetBreakoutPopup } from "@/types";
import store from "@/store";

@Component({
  components: { Icon, BaseButton },
  methods: {
    ...mapMutations("BreakoutsModule", ["setBreakoutPopup"]),
  },
})
export default class BreakoutLeave extends Vue {
  private setBreakoutPopup!: SetBreakoutPopup;
  onLeaveClick(): void {
    this.setBreakoutPopup({
      iconValue: "leave-breakout",
      headerText: "Are you sure you want to leave this breakout and return to the main classroom?",
      primaryButton: {
        buttonText: "Stay",
        callback: () => console.log("Close confirmed"),
      },
      secondaryButton: {
        buttonText: "Leave",
        callback: () => store.dispatch("BreakoutsModule/leaveBreakout"),
      },
    });
  }
}
</script>
<style lang="scss" scoped>
.breakout-leave-container {
  margin: 4px 4px 0 0;
  width: fit-content;
}
.breakout-leave-btn {
  height: 36px;
  border-radius: 5px;
  width: fit-content;
  background-color: var(--info-50);
  padding: 0.2rem 0.6rem;
}
.breakout-leave-btn:hover {
  background-color: var(--info-20);
  cursor: pointer;
}
.leave-room-txt {
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}
.breakout-leave-btn ::v-deep svg {
  vertical-align: bottom;
}
</style>
