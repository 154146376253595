<template lang="pug">
.extended-view(data-name="extended-view")
  .extended-view-image-container(v-if="overlay")
    TimerOverlay(:displayOverride="timer")
    LearnerGuidePage(:pageOverride="page")
    img(:src="slideUrl")
  Video(v-else, ref="extendedDisplay", :providerId="providerId", :presentationView="true")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import config from "@/config";
import { BrowserMessageEvent, MessageType, SendWindowMessage } from "@/types/browser";
import { mapActions, mapMutations, mapState } from "vuex";
import LearnerGuidePage from "@/components/Overlay/LearnerGuidePage.vue";
import TimerOverlay from "@/components/Overlay/TimerOverlay.vue";
import { SetVideoState } from "@/types/video";
import Video from "@/components/Video/SecondaryVideo.vue";
import SecondaryVideo from "@/components/Video/SecondaryVideo.vue";

@Component({
  components: { LearnerGuidePage, TimerOverlay, Video },
  computed: {
    ...mapState("VideoModule", ["overlay"]),
  },
  methods: {
    ...mapActions("BrowserModule", ["messageMainDisplay"]),
    ...mapMutations("VideoModule", ["setVideoState"]),
  },
})
export default class Extended extends Vue {
  private setVideoState!: SetVideoState;
  private readonly messageMainDisplay!: SendWindowMessage;

  private slideUrl = "";
  private page = "";
  private timer = "";
  private providerId = "";
  mounted(): void {
    this.setVideoState({ playing: false });
    window.addEventListener(
      "message",
      (event: BrowserMessageEvent) => {
        if (event.origin !== config.frostUrl) return;
        if (!event.isTrusted) return;
        const message = event.data.message;
        if (message) {
          const type = message.type;
          if (type == undefined) return;
          console.log("Received " + MessageType[type]);
          const video = this.$refs.extendedDisplay as SecondaryVideo;
          if (type == MessageType.SLIDE_NAVIGATION) {
            const slide = event.data.message.content;
            if (slide) this.slideUrl = slide;
          }
          if (type == MessageType.PAGE_NUMBER) {
            const page = event.data.message.content;
            if (page) this.page = page;
          }
          if (type == MessageType.TIMER_UPDATE) {
            this.timer = event.data.message.content || "";
          }
          if (type == MessageType.REMOVE_OVERLAY) {
            this.providerId = event.data.message.content || "";
            this.setVideoState({
              overlay: false,
            });
          }
          if (type == MessageType.APPLY_OVERLAY) {
            this.providerId = event.data.message.content || "";
            this.setVideoState({
              playing: false,
              overlay: true,
            });
          }
          if (type == MessageType.PLAY) {
            video.play(Number(event.data.message.content));
          }
          if (type == MessageType.PAUSE) {
            video.pause();
          }
          if (type == MessageType.SCRUB) {
            video.setPosition(Number(event.data.message.content));
          }
        }
      },
      false
    );
    window.addEventListener("beforeunload", () => {
      this.messageMainDisplay({ type: MessageType.LEARNER_WINDOW_CLOSED });
    });
    this.messageMainDisplay({ type: MessageType.INITIALIZED });
  }
}
</script>

<style lang="scss" scoped>
.extended-view {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.extended-view-image-container {
  position: relative;
  height: fit-content;
  width: fit-content;
  margin: auto;
}
.extended-view img {
  object-fit: contain;
  max-height: 100vh;
  height: 100%;
  width: 100%;
}
</style>
