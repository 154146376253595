<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Microphone
  .system-check-card-contents
    .system-check-description
      .mic-check
        .sound-bar-container
          .sound-bar(v-if="!loading")
            .sound-bar-indicator(:style="indicatorWidth")
          .loader-place-holder(v-else)
            .icon-loader.spin
      .system-check-sound-options
        SelectBox(
          displayExpr="label",
          placeholder="...Audio Devices Loading",
          valueExpr="deviceId",
          :value="selectedDevice",
          :dataSource="devices",
          :returnExpr="['deviceId']",
          width="450",
          dataName="mic-selector",
          @valueChanged="onAudioDeviceChange"
        )

  Status
  Session(v-if="!deviceWarning", :ref="sessionRef", :isSysCheck="true")
  #mic-warning-msg(v-if="deviceWarning || isActiveFailed")
    span(v-html="htmlMessage")
    BaseTip(:tip-message="htmlTip")
  .required-message(v-else)
    span *A working microphone is required to redeem your license for registration.
</template>
<script lang="ts">
import { Component, Watch } from "vue-property-decorator";

import BaseVonageStep from "./BaseVonageStep.vue";
import { LocalStorageFields } from "@/types/base";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";

let audioLevelTimeout: NodeJS.Timeout | undefined;

@Component({
  components: { BaseTip },
})
export default class Microphone extends BaseVonageStep {
  private pubAudio = 0;
  private audioLevelPeak = 0;
  private passing = false;
  async mounted(): Promise<void> {
    this.deviceId = "default";
    const storedDevice = localStorage.getItem(LocalStorageFields.MIC_ID);
    if (storedDevice) this.deviceId = storedDevice;
    await this.populatedDevices("audioInput");
    if (this.deviceWarning || !this.devices) {
      this.failActive(this.message);
    }
  }

  get indicatorWidth(): string {
    return `width: ${this.audioLevels}%`;
  }

  get audioLevels(): number {
    const level = Math.floor((this.pubAudio * 100) / 10) * 10;
    if (level <= this.audioLevelPeak) {
      if (!audioLevelTimeout) {
        audioLevelTimeout = setTimeout(() => {
          if (audioLevelTimeout) {
            clearTimeout(audioLevelTimeout);
            audioLevelTimeout = undefined;
          }
          this.audioLevelPeak -= 1;
        }, 20);
      }
    } else this.audioLevelPeak = level;
    return this.audioLevelPeak;
  }

  @Watch("publisher")
  publisherChange(): void {
    this.loading = false;
    this.publisher?.publishAudio(true);
    this.publisher?.publishVideo(false);
    this.publisher?.on("audioLevelUpdated", (item): void => {
      this.pubAudio = item.audioLevel;
      if (item.audioLevel > 0 && !this.passing) {
        // prevent multiple fires
        this.passing = true;
        this.passActive();
      }
    });
  }

  get htmlMessage(): string {
    return `Be sure your microphone is connected, turned on, and enabled in your browser settings.<br/><b>You won’t be able to join the session without a microphone.</b>`;
  }
  get htmlTip(): string {
    return `Best practice is to close your browser completely and open a new browser window before logging in. If you are simultaneously using other applications (such as Zoom, Teams, etc.) that use your mic, you may experience issues.`;
  }
  get message(): string {
    return this.failedMessage([this.htmlMessage, this.htmlTip]);
  }

  async onAudioDeviceChange(selectedItem: { deviceId: string }): Promise<void> {
    if (selectedItem) {
      this.setRootState({ selectedAudioId: selectedItem.deviceId });
      localStorage.setItem(LocalStorageFields.MIC_ID, selectedItem.deviceId);
      this.refreshPublisherSettings();
    }
  }
}
</script>
<style lang="scss" scoped>
.sound-bar {
  padding: 5px;
  width: 450px;
  margin-right: 2rem;
  height: fit-content;
  box-shadow: inset 0 0 8px var(--gray-80);
}
.sound-bar-container {
  margin-top: 0.5rem;
}
.sound-bar-indicator {
  height: 20px;
  background-color: limegreen;
  vertical-align: middle;
}
.system-check-sound-options {
  width: 50%;
}
.mic-check {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
}
.loader-place-holder {
  text-align: center;
  width: 250px;
  margin-right: 2rem;
}

#mic-warning-msg {
  margin-top: 2rem;
  font-size: 0.85rem;
}
</style>
