<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span Resolution
  .system-check-card-contents
    .system-check-description
      span(v-html="warning", v-if="this.isActiveFailed")
      span(v-html="viewportMessage")
      |
      Status
      BaseTip(:tip-message="htmlTip")
</template>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseTip from "@/components/SystemCheck/BaseTip.vue";
import BaseStep from "./BaseStep.vue";
@Component({
  components: { BaseTip },
})
export default class Resolution extends BaseStep {
  private width = 0;
  private minWidth = 1200;

  mounted(): void {
    setTimeout(() => this.setWidth(), 500);
    window.addEventListener("resize", this.setWidth);
    ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange"].forEach((eventType) =>
      document.addEventListener(eventType, this.setWidth, false)
    );
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.setWidth);
    ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange"].forEach((eventType) =>
      document.removeEventListener(eventType, this.setWidth)
    );
  }
  get warning(): string {
    return (
      `Using the CLP Virtual Classroom experience within viewports less than 1200 is a downgraded experience, and` +
      `is not supported on mobile devices. For an optimal experience please be sure you're using a desktop browser which exceeds this width.`
    );
  }

  get htmlTip(): string {
    return `Try maximizing the window (most common) or changing your screen resolution.`;
  }
  get viewportMessage(): string {
    return `Your current viewport width is ${this.width} pixels, we recommend a width of at least ${this.minWidth} pixels.`;
  }
  get message(): string {
    return this.failedMessage([this.warning, this.htmlTip, this.viewportMessage]);
  }
  get fail(): boolean {
    return this.width < this.minWidth;
  }

  setWidth(): void {
    this.width = window.outerWidth;
    if (this.fail) this.failActive(this.message);
    else this.passActive();
  }
}
</script>
<style lang="css" scoped>
.system-check-description ::v-deep ul {
  list-style-type: none;
}
</style>
