<template lang="pug">
.slide-preview
  SelectBox(
    ref="lessonSelect",
    displayExpr="name",
    valueExpr="title",
    :returnExpr="['title']",
    :dataSource="getLessons",
    :value="selectedLesson",
    @valueChanged="onLessonChange"
  )
  .slides-list
    ul.list-unstyled
      li(
        v-for="slide in lesson.slides",
        :id="slide.title",
        :class="{ active: slide.title === selectedSlide }",
        @click="onContentSelected(slide.title)"
      )
        img(:src="slide.frames[0].content.image.url", style="width: 75px; height: 45px")
        p.slide-number {{ slide.index }}
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { BaseSelectBox as SelectBox } from "@cruciallearning/puddle";
import { GetLessonByTitle, Lesson, LessonSelect } from "@/types/course";

@Component({
  components: { SelectBox },
  computed: {
    ...mapState("CourseModule", ["activeContentId", "activeLessonId"]),
    ...mapGetters("CourseModule", ["getLessons", "getLessonByTitle"]),
  },
})
export default class SlideSelect extends Vue {
  private readonly activeContentId!: string;
  private readonly activeLessonId!: string;
  private readonly getLesson!: Lesson | undefined;
  private readonly getLessons!: LessonSelect[];
  private readonly getLessonByTitle!: GetLessonByTitle;

  private selectedLesson = "";
  private selectedSlide = "";

  created(): void {
    this.selectedLesson = this.activeLessonId;
  }

  get lesson(): Lesson | undefined {
    return this.getLessonByTitle(this.selectedLesson);
  }

  onContentSelected(slide: string): void {
    this.selectedSlide = slide;
    this.$emit("contentSelected", slide);
  }

  onLessonChange(selectedItem: { title: string }): void {
    this.selectedSlide = "";
    this.selectedLesson = selectedItem.title;
    this.$emit("contentSelected");
  }
}
</script>
