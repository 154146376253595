import { RootState } from "@/types";
import { LoaderState } from "@/types/loader";
import { Module } from "vuex";

const LoaderModule: Module<LoaderState, RootState> = {
  namespaced: true,
  state(): LoaderState {
    return {
      message: "",
      completed: 0,
      showLoader: false,
    };
  },
  mutations: {
    setLoaderState(state: LoaderState, options: Partial<LoaderState>): void {
      Object.assign(state, options);
    },
  },
};

export default LoaderModule;
