<template lang="pug">
.broadcast-button-container
  Button(text="broadcast", :disabled="!isActive", widest, @click="onStartBroadcast")
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { SetBreakoutsState } from "@/types";
import { mapMutations, mapState } from "vuex";
import { BaseButton as Button } from "@cruciallearning/puddle";

@Component({
  components: { Button },
  computed: {
    ...mapState("BreakoutsModule", ["isActive"]),
  },
  methods: {
    ...mapMutations("BreakoutsModule", ["setBreakoutsState"]),
  },
})
export default class BroadcastButton extends Vue {
  private readonly setBreakoutsState!: SetBreakoutsState;

  onStartBroadcast(): void {
    this.setBreakoutsState({ broadcasting: true });
  }
}
</script>
<style lang="scss" scoped>
.broadcast-button-container {
  height: fit-content;
  width: 100%;
}
.broadcast-button-container .button {
  width: 80%;
  margin: 0 auto 10px auto;
  display: block;
}
</style>
