import { BreakoutsState } from "./breakouts";
import { ChatState } from "./chat";
import { PollState } from "./poll";
import { SidebarState } from "./sidebar";
import { UsersState } from "./users";
import { EventState } from "@cruciallearning/puddle/models/event";
import { MediaState } from "./media";
import { VonageState } from "./vonage";
import { VideoState } from "./video";
import { CourseState } from "./course";
import { BrowserState } from "./browser";

export interface RoutesType {
  LOGIN: string;
  CONTENT: string;
  SOCKET: string;
}

/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */
export interface ConfigVars {
  BACKEND_URL: string;
  CDN_URL: string;
  ENV: string;
  FROST_URL: string;
  SECURITY_CLIENT_ID: string;
  SECURITY_CLIENT_REALM: string;
  SECURITY_PASSWORD: string;
  SECURITY_AUTH_SERVER_URL: string;
  SECURITY_USERNAME: string;
  SOCKET_URL: string;
  STREAM_KEY: string;
  STREAM: boolean;
  VAPOR_URL: string;
  OKTA_DOMAIN: string;
  OKTA_CLIENT_ID: string;
  OSANO_URL: string;
}
export class EnvVars {
  public readonly backendUrl!: string;
  public readonly cdnUrl!: string;
  public readonly env!: string;
  public readonly frostUrl!: string;
  public readonly securityClientId!: string;
  public readonly securityRealm!: string;
  public readonly securityPassword!: string;
  public readonly securityUsername!: string;
  public readonly socketUrl!: string;
  public readonly streamKey!: string;
  public readonly vaporUrl!: string;
  public readonly oktaDomain!: string;
  public readonly oktaClientId!: string;
  public readonly osanoUrl!: string;

  constructor(config: ConfigVars) {
    this.backendUrl = config.BACKEND_URL;
    this.cdnUrl = config.CDN_URL;
    this.env = config.ENV;
    this.frostUrl = config.FROST_URL;
    this.securityClientId = config.SECURITY_CLIENT_ID;
    this.securityPassword = config.SECURITY_PASSWORD;
    this.securityRealm = config.SECURITY_CLIENT_REALM;
    this.securityUsername = config.SECURITY_USERNAME;
    this.socketUrl = config.SOCKET_URL;
    this.streamKey = config.STREAM_KEY;
    this.vaporUrl = config.VAPOR_URL;
    this.oktaClientId = config.OKTA_CLIENT_ID;
    this.oktaDomain = config.OKTA_DOMAIN;
    this.osanoUrl = config.OSANO_URL;
  }

  /** @returns true iff this is running in the production environment */
  public isProd(): boolean {
    return /^prod.*$/i.test(this.env);
  }

  /** @returns true iff this is NOT running in the production environment */
  public isntProd(): boolean {
    return !this.isProd();
  }
}

export interface RootState {
  captions: boolean;
  courseId: string;
  debugEnabled: boolean;
  isCheckingHardware: boolean;
  isClassroomConnected: boolean;
  isClassroomStarted: boolean;
  isClassroomWaiting: boolean;
  isConnected: boolean;
  isEndingSession: boolean;
  isLeavingSession: boolean;
  isMediaAllowed: boolean;
  isInitialLoad: boolean;
  isStaleSession: boolean;
  language: string;
  selectedAudioId: string;
  selectedVideoId: string;
  selectedSpeakerId: string;
  streamSessionId: string;
  streamToken: string;
  trainingEventId: string;
  wasDisconnected: boolean;
  wasRemoved: boolean;
  breakoutVonageToken: string;
  breakoutSessionId: string;
  activeSpeakerId: string;
  breakoutActiveSpeakerId: string;
  userRaisedHand: boolean;
  downloadingChat: boolean;
  learnerAttendance: boolean;
  firstVisitPopup: boolean;
  //-- Modules -------------------
  BreakoutsModule?: BreakoutsState;
  BrowserModule?: BrowserState;
  ChatModule?: ChatState;
  CourseModule?: CourseState;
  PollModule?: PollState;
  SidebarModule?: SidebarState;
  UsersModule?: UsersState;
  VideoModule?: VideoState;
  EventModule?: EventState;
  MediaModule?: MediaState;
  VonageModule?: VonageState;
  //------------------------------
}
export interface IdNameModel {
  id: number;
  name: string;
}
export interface KeyValueModel {
  [key: string]: string | number | string[] | number[];
}
export interface KeyStringModel {
  [key: string]: string;
}
export interface KeyUnknownModel {
  [key: string]: unknown;
}
/*
 * ------------------------------------------------------------------------------
 * Functions
 * ------------------------------------------------------------------------------
 */
export interface ClassroomStateChange {
  (classroomOptions: Partial<RootState>): void;
}
export interface Connect {
  (): void;
}
export interface ConnectToClassroom {
  (): void;
}
export interface ClassroomError {
  (error: string[]): void;
}
export interface Disconnect {
  (remove: boolean): void;
}
export interface SetCheckingHardware {
  (isCheckingHardware: boolean): void;
}
export interface SetRootState {
  (rootStateOptions: Partial<RootState>): void;
}
export interface StartClassroom {
  (): void;
}
export interface RestartClassroom {
  (eventId: string): void;
}
export interface StopClassroom {
  (): void;
}
export interface AdmitToClassroom {
  (id: string): void;
}

export interface RemoveFromClassroom {
  (id: string): void;
}

export interface RemoveAllFromClassroom {
  (): void;
}

export interface UpdateRootState {
  (rootStateOptions: Partial<RootState>): void;
}
export interface ExtendSession {
  (): void;
}
export * from "./ack";
export * from "./breakouts";
export * from "./chat";
export * from "./classroom";
export * from "./disconnect";
export * from "./general";
export * from "./poll";
export * from "./ping";
export * from "./sidebar";
export * from "./stream";
export * from "./test";
export * from "./users";
