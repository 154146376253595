<template lang="pug">
.tools-timer
  p.back-stage-header
    i.icon-timer.align-middle
    | Timer
  .timer-settings
    TimerSoundSelect
    ul.presets.list-unstyled
      li(
        v-for="preset in presets",
        :title="`Start a ${preset} minute timer`",
        @click="onAutoStart(preset * 60, `DOWN`)",
        style="margin: 1px",
        :class="active != null ? `locked` : ``",
        data-name="`presetTimer-${preset}`"
      ) {{ preset }}
        span :00
    .timer-set
      .input
        input(
          type="number",
          :value="hours",
          :disabled="active != null",
          @change="onChangeHours",
          @focus="$event.target.value = trimZeros($event.target.value); $event.target.select()",
          @blur="$event.target.value = hours",
          :class="active != null ? `locked` : ``",
          data-name="timerHours"
        )
        p hrs
      .hourglass :
      .input
        input(
          type="number",
          :value="minutes",
          :disabled="active != null",
          @change="onChangeMinutes",
          @focus="$event.target.value = trimZeros($event.target.value); $event.target.select()",
          @blur="$event.target.value = minutes",
          :class="active != null ? `locked` : ``",
          data-name="timerMinutes"
        )
        p min
      .hourglass :
      .input
        input(
          type="number",
          :value="seconds",
          :disabled="active != null",
          @change="onChangeSeconds",
          @focus="$event.target.value = trimZeros($event.target.value); $event.target.select()",
          @blur="$event.target.value = seconds",
          :class="active != null ? `locked` : ``",
          data-name="timerSeconds"
        )
        p sec
      i.pointer(
        :title="`Timer will count ${dir}`",
        :class="[`large`, dir === `UP` ? `dx-icon-spinup` : `dx-icon-spindown`, active != null ? `locked` : ``]",
        @click="onToggleDirection()",
        data-name="timerDirection"
      )
      i.pointer(
        :title="`Start a timer`",
        :class="[`icon-play`, getTimerSeconds() > 0 ? `` : `locked`]",
        style="color: var(--success-20)",
        @click="onStartTimer()",
        v-show="active == null",
        data-name="timerStart"
      )
      i.pointer(
        :title="`Pause the active timer`",
        :class="`icon-pause`",
        style="color: var(--info-50)",
        @click="onPauseTimer()",
        v-show="active != null && !active.isPaused",
        data-name="timerPause"
      )
      i.pointer(
        :title="`Resume the paused timer`",
        :class="`icon-play`",
        style="color: var(--success-20)",
        @click="onResumeTimer()",
        v-show="active != null && active.isPaused",
        data-name="timerResume"
      )
      i.pointer(
        :title="`Cancel the active timer`",
        :class="`dx-icon-close`",
        style="color: var(--error-50)",
        @click="onStopTimer()",
        v-show="active != null",
        data-name="timerCancel"
      )
      i.pointer(
        :title="`Pin the timer for later use`",
        :class="[`dx-icon-pin`, getTimerSeconds() > 0 ? `` : `locked`]",
        @click="onSaveTimer()",
        v-show="active == null",
        data-name="timerSave"
      )
  .timer-list
    ul
      li(v-for="pinned in timers")
        .title
          p.name(data-name="`savedTimer-${pinned.seconds}`") {{ getTimerString(pinned.seconds) }}
        i(
          :title="`Timer will count ${pinned.dir}`",
          :class="pinned.dir === `UP` ? `dx-icon-spinup` : `dx-icon-spindown`",
          style="margin-right: 20px; font-size: 27px"
        )
        i.pointer(
          :title="`Start this pinned timer`",
          :class="[`icon-play`, active == null ? `` : `locked`]",
          style="color: var(--success-20)",
          @click="onAutoStart(pinned.seconds, pinned.dir)",
          data-name="`savedStart-${pinned.seconds}`"
        )
        i.pointer(
          :title="`Unpin this timer`",
          :class="`dx-icon-close`",
          style="color: var(--error-50)",
          @click="onDeleteTimer(pinned)",
          data-name="`savedDelete-${pinned.seconds}`"
        )
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import TimerSoundSelect from "./TimerSoundSelect.vue";
import { ActiveTimer, Timer, TimerDirection, TimerPause, TimerResume, TimerStart, TimerStop } from "@/types/timer";
import { formatTimePart, getTimerStr } from "@cruciallearning/puddle/utils";
import { ChangeEvent } from "@cruciallearning/puddle/models/common";

@Component({
  components: { TimerSoundSelect },
  computed: { ...mapState("TimerModule", ["timers", "active"]) },
  methods: {
    ...mapActions("TimerModule", ["addTimer", "deleteTimer", "timerStart", "timerStop", "timerResume", "timerPause"]),
  },
})
export default class Timers extends Vue {
  private readonly active!: ActiveTimer;
  private readonly addTimer!: (timer: Timer) => void;
  private readonly deleteTimer!: (timer: Timer) => void;

  private readonly timerStart!: TimerStart;
  private readonly timerStop!: TimerStop;
  private readonly timerResume!: TimerResume;
  private readonly timerPause!: TimerPause;

  private hours = "00";
  private minutes = "00";
  private seconds = "00";
  private dir: TimerDirection = "DOWN";

  private presets: number[] = [2, 8, 10, 15];

  @Watch("active")
  setTimerDisplay(): void {
    if (this.active) this.setTimer(this.active.initial / 1000, this.active.dir);
  }

  getTimerSeconds(): number {
    let totalSeconds: number = this.seconds != null ? parseInt(this.seconds) : 0;
    if (this.minutes != null) {
      totalSeconds += parseInt(this.minutes) * 60;
    }
    if (this.hours != null) {
      totalSeconds += parseInt(this.hours) * 3600;
    }
    return totalSeconds;
  }

  getTimerString(seconds: number): string {
    return getTimerStr(seconds, false);
  }

  setTimer(seconds: number, dir: TimerDirection): void {
    const timeStr: string = getTimerStr(seconds, true);
    const timeParts: string[] = timeStr.split(":");
    this.hours = timeParts[0];
    this.minutes = timeParts[1];
    this.seconds = timeParts[2];
    this.dir = dir;
  }

  trimZeros(str: string) {
    let result: string = str;
    while (result.length > 0 && result.startsWith("0")) {
      result = result.substr(1, result.length - 1);
    }
    return result;
  }

  onAutoStart(seconds: number, dir: TimerDirection): void {
    if (!this.active) {
      this.setTimer(seconds, dir);
      this.onStartTimer();
    }
  }

  onChangeHours(evt: ChangeEvent): void {
    this.hours = formatTimePart(evt.target.value);
  }

  onChangeMinutes(evt: ChangeEvent): void {
    this.minutes = formatTimePart(evt.target.value);
  }

  onChangeSeconds(evt: ChangeEvent): void {
    this.seconds = formatTimePart(evt.target.value);
  }

  onDeleteTimer(timer: Timer): void {
    this.deleteTimer(timer);
  }

  onPauseTimer(): void {
    if (this.active != null && !this.active.isPaused) {
      this.timerPause();
    }
  }

  onResumeTimer(): void {
    if (this.active != null && this.active.isPaused) {
      this.timerResume();
    }
  }

  onSaveTimer(): void {
    const currentSeconds: number = this.getTimerSeconds();
    if (currentSeconds > 0) {
      const timer: Timer = {
        seconds: currentSeconds,
        dir: this.dir,
      };
      this.addTimer(timer);
    }
  }

  onStartTimer(): void {
    if (this.active == null && this.getTimerSeconds() > 0) {
      this.timerStart({ at: this.getTimerSeconds(), dir: this.dir });
    }
  }

  onStopTimer(): void {
    if (this.active != null) {
      this.timerStop();
    }
  }

  onToggleDirection(): void {
    if (this.active == null) {
      this.dir = this.dir == "UP" ? "DOWN" : "UP";
    }
  }
}
</script>
