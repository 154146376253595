<template lang="pug">
.contents-passthrough
  .inner-trainer-container
    .close-item(@click="openSideView(sideViewType.BREAKOUT)")
      i.icon-x
    .new-breakout-group.no-shrink.overflow
      p.back-stage-header Auto Assign Settings
      .settings-set.mb-8
        NumberSpinner(
          small,
          light,
          label="Users Per Group (25 max)",
          error="Minimum of 2 per group",
          valueChangeEvent="input",
          :min="2",
          :max="25",
          v-model="numPerGroup"
        )
      .settings-set
        .field
          .checkbox.right-box
            input#include-trainer(type="checkbox", v-model="includeTrainers")
            label.pointer(for="include-trainer")
              span.label-container Include Trainers
        .field
          .checkbox.right-box
            input#include-producers(type="checkbox", v-model="includeModerators")
            label.pointer(for="include-producers")
              span.label-container Include Moderators

  .panel-actions.no-bg
    Button.no-side-padding(full, text="Create Groups", @click="onMakeAssignments")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import { AddBreakout, Breakout, OpenSideView, SideViewType } from "@/types";
import {
  BaseButton as Button,
  BaseTextBox as TextBox,
  BaseNumberSpinner as NumberSpinner,
} from "@cruciallearning/puddle";

@Component({
  components: { NumberSpinner, Button, TextBox },
  computed: { ...mapState("BreakoutsModule", ["editBreakout"]) },
  methods: { ...mapActions("BreakoutsModule", ["addBreakout"]), ...mapActions("SidebarModule", ["openSideView"]) },
})
export default class AutoAssignGroups extends Vue {
  private readonly editBreakout!: Breakout;
  private readonly addBreakout!: AddBreakout;
  private readonly openSideView!: OpenSideView;

  private numPerGroup = 2;
  private includeTrainers = false;
  private includeModerators = false;
  private sideViewType: unknown = SideViewType;

  onMakeAssignments() {
    this.addBreakout({
      name: this.editBreakout.name || "",
      slide: this.editBreakout.slide,
      autoAssign: true,
      groupSize: this.numPerGroup,
      assignTrainers: this.includeTrainers,
      assignModerators: this.includeModerators,
    });
    this.openSideView(SideViewType.BREAKOUT);
  }
}
</script>

<style lang="scss" scoped>
.button {
  padding: 12px 11px !important;
}
</style>
