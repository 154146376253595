<template lang="pug">
.modal(style="display: block")
  .modal-overlay(tabindex="-1")
    .modal-container(role="dialog", aria-modal="true", class=containerClass)
      header.modal-header
        .close-modal.close-modal-header(aria-label="Close modal", @click="$emit('close')", data-name="close-modal")
          i.icon-x
      .modal-content
        p.title-3 Settings
        .video-audio-settings.dark-inputs
          SelectBox(
            label="Microphone",
            labelIcon="mic",
            data-name="microphone-select",
            dataName="microphone-select-device",
            displayExpr="label",
            placeholder="...Audio Devices Loading",
            valueExpr="deviceId",
            :dataSource="audioDevices",
            :returnExpr="['deviceId']",
            :value="audioSelected",
            @valueChanged="onAudioDeviceChange"
          )

          .sound-strength-meter.mb-32
            .progress(:style="{ transform: `translateX(${audioLevels}%)` }")
            img.notches(src="@/assets/images/icons/sound-bar.svg")

          SelectBox(
            label="Camera",
            labelIcon="video",
            dataName="camera-select-device",
            data-name="camera-select",
            displayExpr="label",
            placeholder="...Video Devices Loading",
            valueExpr="deviceId",
            :dataSource="videoDevices",
            :returnExpr="['deviceId']",
            :value="videoSelected",
            @valueChanged="onVideoDeviceChange"
          )
          .test-preview
            #videoPreview

          .filter-settings(v-if="browserSupported")
            .icon-button(title="Blur Video", :class="blur", data-name="toggle-blur", @click="onToggleBlur")
              i(data-name="toggle-blur-icon")
            span Blur Background
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { SetRootState, StreamDeviceModel } from "@/types";
import { mapActions, mapMutations, mapState } from "vuex";
import OT from "@opentok/client";
import { BaseSelectBox as SelectBox } from "@cruciallearning/puddle";
import { SetMediaState } from "@/types/media";
import VonageUtil from "../Vonage/vonageUtil";
import { LocalStorageFields } from "@/types/base";
import { ApplyUserVideoFilter, RefreshPublisherSettings } from "@/types/vonage";
import { genericErrors } from "@/components/Vonage/ErrorHandler";

let audioLevelTimeout: NodeJS.Timeout | undefined;

@Component({
  components: { SelectBox },
  computed: {
    ...mapState(["selectedAudioId", "selectedVideoId"]),
    ...mapState("MediaModule", ["isBlurred"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
    ...mapMutations("MediaModule", ["setMediaState"]),
    ...mapActions("VonageModule", ["refreshPublisherSettings", "applyUserVideoFilter"]),
  },
})
export default class Settings extends Vue {
  private readonly selectedAudioId!: string;
  private readonly selectedVideoId!: string;
  private readonly isBlurred!: boolean;

  private readonly setRootState!: SetRootState;
  private readonly setMediaState!: SetMediaState;
  private readonly refreshPublisherSettings!: RefreshPublisherSettings;
  private readonly applyUserVideoFilter!: ApplyUserVideoFilter;

  private audioDevices: Array<StreamDeviceModel> = [];
  private audioLevel = 0;
  private audioLevelPeak = 0;
  private settingsPublisher?: OT.Publisher | null;
  private videoDevices: Array<StreamDeviceModel> = [];

  get blur(): string {
    return this.isBlurred ? "icon-eye-off on" : "icon-eye off";
  }

  async mounted(): Promise<void> {
    await this.setDevices();
  }

  async destroyed(): Promise<void> {
    if (this.settingsPublisher) {
      this.settingsPublisher.off();
      await this.settingsPublisher.destroy();
    }
    this.refreshPublisherSettings();
  }

  get audioLevels(): number {
    const level = Math.floor((this.audioLevel * 100) / 10) * 10;
    if (level <= this.audioLevelPeak) {
      if (!audioLevelTimeout) {
        audioLevelTimeout = setTimeout(() => {
          if (audioLevelTimeout) {
            clearTimeout(audioLevelTimeout);
            audioLevelTimeout = undefined;
          }
          this.audioLevelPeak -= 10;
        }, 300);
      }
    } else this.audioLevelPeak = level;
    return this.audioLevelPeak;
  }

  get audioSelected(): string {
    if (this.audioDevices) {
      return this.selectedAudioId || this.audioDevices[0]?.deviceId;
    }
    return "";
  }

  get videoSelected(): string {
    if (this.videoDevices) {
      return this.selectedVideoId || this.videoDevices[0]?.deviceId;
    }
    return "";
  }

  async setDevices(): Promise<void> {
    this.settingsPublisher = OT.initPublisher(
      "videoPreview",
      {
        showControls: false,
        audioSource: this.selectedAudioId || undefined,
        videoSource: this.selectedVideoId || undefined,
      },
      (error): void => {
        if (error) console.error("setDevices failed to init publisher videoPreview", error);
      }
    );

    this.settingsPublisher.on("audioLevelUpdated", (item): void => {
      this.audioLevel = item.audioLevel;
    });

    this.settingsPublisher.on("accessDenied", (): void => {
      this.setRootState({ isMediaAllowed: false });
      this.$emit("close");
    });

    this.settingsPublisher.on("accessAllowed", async (): Promise<void> => {
      this.setRootState({ isMediaAllowed: true });
      this.audioDevices = await VonageUtil.getDevices("audioInput");
      this.videoDevices = await VonageUtil.getDevices("videoInput");
    });
    this.settingsPublisher.on("videoElementCreated", () => {
      if (this.isBlurred) {
        this.applyUserVideoFilter(this.settingsPublisher);
      }
    });
  }

  onToggleBlur(): void {
    if (this.isBlurred) {
      this.settingsPublisher?.clearVideoFilter().catch(genericErrors);
    } else {
      this.applyUserVideoFilter(this.settingsPublisher);
    }
    this.setMediaState({ isBlurred: !this.isBlurred });
    localStorage.setItem(LocalStorageFields.BACKGROUND_BLUR, `${this.isBlurred}`);
  }

  async onAudioDeviceChange(selectedItem: { deviceId: string }): Promise<void> {
    await this.settingsPublisher?.setAudioSource(selectedItem.deviceId);
    localStorage.setItem(LocalStorageFields.MIC_ID, selectedItem.deviceId);
    this.setRootState({ selectedAudioId: selectedItem.deviceId });
  }

  async onVideoDeviceChange(selectedItem: { deviceId: string }): Promise<void> {
    this.setRootState({ selectedVideoId: selectedItem.deviceId });
    localStorage.setItem(LocalStorageFields.CAMERA_ID, selectedItem.deviceId);
  }
  browserSupported(): boolean {
    return OT.hasMediaProcessorSupport();
  }
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: 99 !important;
}
.modal-overlay {
  top: 64px !important;
}
.field {
  margin-bottom: 20px !important;
}
.filter-settings {
  display: flex;
  margin-top: 1rem;
}
.filter-settings > span {
  font-size: 0.75rem;
  color: var(--gray-10);
  text-transform: none;
  margin: 0.4rem;
}
.filter-settings > .on {
  color: var(--success-10);
}
.filter-settings > .off {
  color: var(--error-20);
}
</style>
