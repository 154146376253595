<template lang="pug">
.breakout-list-actions
  ul.unstyled
    li(@click="onEdit", data-name="editBreakout") Edit
    li(@click="onDuplicate", data-name="duplicateBreakout") Duplicate
    li(:class="{ disabled: active }", @click="onShuffle", data-name="shuffleBreakout") Duplicate and Shuffle
    li(:class="{ disabled: active }", @click="onDelete", data-name="deleteBreakout") Delete
</template>
<script lang="ts">
import { Breakout, DuplicateBreakout, OpenSideView, SetBreakoutsState, SetEditBreakout, SideViewType } from "@/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
@Component({
  computed: {
    ...mapState("BreakoutsModule", ["breakouts", "isActive"]),
  },
  methods: {
    ...mapMutations("BreakoutsModule", ["setEditBreakout", "setBreakoutsState"]),
    ...mapActions("SidebarModule", ["openSideView"]),
    ...mapActions("BreakoutsModule", ["duplicateBreakout"]),
  },
})
export default class BreakoutListActions extends Vue {
  @Prop({ required: true }) readonly breakout!: Breakout;
  @Prop({ required: true }) readonly active!: boolean;

  private readonly setEditBreakout!: SetEditBreakout;
  private readonly setBreakoutsState!: SetBreakoutsState;
  private readonly openSideView!: OpenSideView;
  private readonly duplicateBreakout!: DuplicateBreakout;
  private readonly breakouts!: Breakout[];
  private readonly isActive!: boolean;

  onEdit(): void {
    this.$emit("closed");
    this.setEditBreakout({ id: this.breakout.id, name: this.breakout.name, slide: this.breakout.slide });
    this.openSideView(SideViewType.BREAKOUT);
  }

  onDuplicate(): void {
    this.duplicateBreakout({ id: this.breakout.id, shuffle: false });
    this.$emit("closed");
  }
  onShuffle(): void {
    if (!this.isActive) this.duplicateBreakout({ id: this.breakout.id, shuffle: true });
    this.$emit("closed");
  }
  onDelete(): void {
    this.$emit("closed");
    this.setBreakoutsState({ removeId: this.breakout.id });
    this.openSideView(SideViewType.REMOVE);
  }
}
</script>
<style lang="scss" scoped>
.breakout-list-actions {
  pointer-events: auto;
}
.breakout-list-actions li {
  color: #020202;
  background: white;
  text-align: left;
  font-family: Flama;
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
  padding: 8px 16px;
  cursor: pointer;
}
.breakout-list-actions li:hover {
  background-color: #e5eaed;
}
.breakout-list-actions li.disabled,
.breakout-list-actions li.disabled:hover {
  cursor: unset;
  color: var(--gray-50);
}
</style>
