/** Various generic utility functions */
class CommonUtil {
  /** @return a random UUID value */
  public static makeUuid(): string {
    return crypto.randomUUID();
  }

  /**
   * Assemble a `requestId` string from a base identifier and
   * retry-attempt number.
   *
   * @param baseRequestId when missing, a new UUID value will be generated;
   *                      when a `:<ATTEMPT_NUM>` suffix is found, it will be trimmed;
   *                      otherwise used as-is
   * @param attemptNum when provided, a `:<ATTEMPT_NUM>` suffix will be
   *                   appended to the baseRequestId
   * @returns a requestId value of the form `<UUID>[:<ATTEMPT_NUM>]`
   */
  public static makeRequestId(baseRequestId?: string | null, attemptNum?: number | null) {
    let requestId: string = baseRequestId ?? CommonUtil.makeUuid();
    requestId = requestId.split(":")[0];
    if (attemptNum != null && attemptNum != undefined) {
      requestId = requestId + ":" + attemptNum;
    }
    return requestId;
  }

  /** @return `true` IFF `val` is `T`, `true`, `yes`, `1`, etc. */
  public static truthyString(val: unknown): boolean {
    return /^((T(rue)?)|(Y(es)?)|([1-9]\d*))$/i.test(`${val}`);
  }

  /**
   * Generates a new object that combines the top-level attributes of the given
   * list such that an attribute defined in the N+1th argument takes precedence
   * over an attribute with the same name defined in the Nth argument.
   *
   * E.g.:
   *  - `merge({a:1}, {a:2,b:2})` yields `{a:2,b2}`
   *  - `merge({a:2,b:2}, {a:1})` yields `{a:1,b2}`
   *
   * `undefined` values are ignored; `null` values are not; e.g.:
   *  - `merge({a:1,b:2}, {a:null,b:undefined})` yields `{a:null,b:2}`
   */
  public static merge(...objs: object[]): object {
    let result = {};
    for (const obj of objs) {
      result = Object.assign(result, CommonUtil.stripUndefinedValues(obj ?? {}));
    }
    return result;
  }

  /** @return a shallow copy of `obj` with top-level `undefined`-valued attributes removed */
  public static stripUndefinedValues(obj: object): object {
    return CommonUtil.filterByValue(obj, CommonUtil._isntUndefined);
  }

  /** @return a shallow copy of `obj` with top-level `undefined`- or `null`-valued attributes removed */
  public static stripNullAndUndefinedValues(obj: object): object {
    return CommonUtil.filterByValue(obj, CommonUtil._isntNullOrUndefined);
  }

  /** @return a shallow copy of `obj` with top-level attributes not matching the given predicate removed. */
  public static filterByValue(obj: object, predicate: (value: unknown) => boolean): object {
    const result: object = {};
    for (const key of Object.keys(obj)) {
      const value = obj[key as keyof typeof obj];
      if (predicate(value)) {
        result[key as keyof typeof result] = value;
      }
    }
    return result;
  }

  private static _isntUndefined(value: unknown): boolean {
    return value !== undefined;
  }

  private static _isntNullOrUndefined(value: unknown): boolean {
    return value !== null && value !== undefined;
  }

  //-------------------------------------------------------------------------//

  /** Pretty-print JSON to a String. */
  public static jsonpp(obj: object): string {
    return JSON.stringify(obj, null, 2);
  }

  /** Pretty-print JSON to a String, with a prefix. */
  public static jsonppp(prefix: string, obj: object): string {
    return prefix + CommonUtil.jsonpp(obj);
  }
}

export default CommonUtil;
