import pRetry, { AbortError } from "p-retry";
import CommonUtil from "@/utils/CommonUtil";

/**
 * Supports automatic retries of async or Promise-valued functions.
 *
 * E.g.:
 *   result = await RetryUtil.retry(
 *       (attemptCount: number) => {
 *         return fetch("https://www.example.com/");
 *       },
 *       { retries: 5 }
 *   );
 *
 * The retryable operation is a method accepting an `attemptCount`
 * parameter (strictly informational) and returning a `Promise`.
 * If the Promise is rejected with any error other than `AbortError`
 * the operation may be re-attempted after some delay.
 *
 * See the comment at the bottom of this file for a complete example.
 */
class RetryUtil {
  // ------------------------------------------------------------- CONFIG -- //

  /** Enable or disable debug log messages. */
  private static readonly DEBUG = true;

  /** Default number of attempts before giving up. See {@link #DEFAULT_OPTIONS.retries}. */
  private static readonly DEFAULT_ATTEMPTS = 10;

  /**
   * Default node-retry retry.operation options, which may be overridden in\
   * individual calls to {@link RetryUtil#retry} or {@link RetryUtil#makeRetryable}.
   *
   * Note the current settings (factor=2, minTimeout=3s, retries=9) yields the
   * following retry intervals: 3s, 6s, 12s, 24s, 48s, 96s (1m36s), 192s (3m12s), 384s (6m24s), 768s (12m48s)
   * See https://github.com/tim-kos/node-retry?tab=readme-ov-file#retryoperationoptions
   */
  private static readonly DEFAULT_OPTIONS = {
    /** Number of retry attempts before giving up. (Note p-retry makes retries+1 attempts in total; it doesn't count first attempt as a "retry".) */
    retries: RetryUtil.DEFAULT_ATTEMPTS - 1,
    /** Exponential scaling factor for delay between attempts. Creates `factor^(N-1) * minTimeout` delay between the (N-1)th and Nth retry. */
    factor: 2,
    /** Baseline delay between retry attempts, in milliseconds. */
    minTimeout: 3 * 1000,
    /** Max delay between retry attempts, in milliseconds. */
    maxTimeout: 15 * 60 * 1000,
    /** When true, fuzz the delay between attempts by a random factor between 1 and 2. */
    randomize: false,
    /** Whether to retry indefinitely (ignoring `retries`). */
    forever: false,
    /** Whether to `unref` the setTimeout delay (allowing the process to exit if only retries remain). */
    unref: false,
    /** Max time (in milliseconds) before an operation fails dues to a time-out. */
    maxRetryTime: Infinity,
  };

  // --------------------------------------------------- PUBLIC INTERFACE -- //

  /**
   * Invoke the given `action`, with retry semantics.
   *
   * E.g.:
   *     const action = (retryCount: number) => { return fetch("https://www.example.com/"); };
   *     const result = await RetryUtil.retry(action, { retries: 5 });
   * will attempt `action` up to 6 times (original attempt + 5 retries) before failing.
   *
   * @param {UnaryPromiseValuedFunction} action - the (non-null) operation to potentially retry
   * @param [options] - custom node-retry options to apply; merged with {@link #DEFAULT_OPTIONS}
   * @param {ErrorConsumingFunction} [onFailure] - custom error handler (defaults to {@link #defaultOnFailure})
   * @returns {Promise<unknown>} the underlying promise returned by `action`
   */
  public static async retry(
    action: UnaryPromiseValuedFunction,
    options?: unknown | null,
    onFailure?: ErrorConsumingFunction | null
  ): Promise<unknown> {
    return await RetryUtil.makeRetryable(action, options, onFailure ?? RetryUtil.defaultOnFailure)();
  }

  /**
   * Wraps the given `action` with retry logic.
   *
   * E.g.:
   *   const retryable = RetryUtil.makeRetryable(
   *     (retryCount: number) => {
   *       return fetch("https://www.example.com/");
   *     }
   *   );
   * yields a function that will automatically retry the specified action up to 6 times
   * (the original attempt + 5 retries) before failing.
   *
   * @param {UnaryPromiseValuedFunction} action - the (non-null) operation to wrap
   * @param [options] - custom node-retry options to apply; merged with {@link #DEFAULT_OPTIONS}
   * @param {ErrorConsumingFunction} [onFailure] - custom error handler (defaults to {@link #defaultOnFailure})
   * @returns {PromiseValuedFunction} a version of `action` that will automatically retry as appropriate
   */
  public static makeRetryable(
    action: UnaryPromiseValuedFunction,
    options?: unknown | null,
    onFailure?: ErrorConsumingFunction | null
  ): NullaryPromiseValuedFunction {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolvedOptions: any = RetryUtil.resolveOptions(options);
    onFailure = onFailure ?? RetryUtil.defaultOnFailure;
    let abortPending = false;
    return async (): Promise<unknown> => {
      const run = (attemptCount: number): Promise<unknown> => {
        if (abortPending) {
          return Promise.reject(new AbortError("Pending invocations aborted due to resolution of previous attempt."));
        } else {
          const retryCount = attemptCount - 1;
          RetryUtil.debugLogRetryAttempt(
            retryCount,
            resolvedOptions.retries ?? RetryUtil.DEFAULT_OPTIONS.retries ?? RetryUtil.DEFAULT_ATTEMPTS - 1
          );
          return action(retryCount);
        }
      };
      return pRetry(run, resolvedOptions)
        .catch(onFailure)
        .finally(() => {
          abortPending = true;
        });
    };
  }

  // ------------------------------------------------------ PRIVATE UTILS -- //

  /** Merge the given `options`, if any, with my {@link #DEFAULT_OPTIONS} */
  private static resolveOptions(options: unknown | null): object {
    if (options != null && options != undefined) {
      return CommonUtil.merge(RetryUtil.DEFAULT_OPTIONS, options);
    } else {
      return RetryUtil.DEFAULT_OPTIONS;
    }
  }

  /**
   * Default `.catch()` handler for the retryable operation's Promise. Used to
   * prevent otherwise uncaught exceptions from bubbling off the top of the
   * stack. (Writes to the local `console.error` log.)
   */
  private static defaultOnFailure(err: Error): void {
    console.error("Retryable operation aborted due to AbortError or max attempts exceeded; underlying error:", err);
  }

  /** Logs retry attempts to the local `console.debug` log, if appropriate. */
  private static debugLogRetryAttempt(retryCount: number, maxRetries: number): void {
    if (RetryUtil.DEBUG && retryCount > 0) {
      console.debug("Retryable operation failed; attempting retry #" + retryCount + " of " + maxRetries + ".");
    }
  }
}

// -------------------------------------- SOME HANDY TYPE-DEFS USED ABOVE -- //

type NullaryPromiseValuedFunction = () => Promise<unknown>;
type UnaryPromiseValuedFunction = (retryCount: number) => Promise<unknown>;
type ErrorConsumingFunction = (err: Error) => void;

// -------------------------------------------------------------- EXPORTS -- //

export { RetryUtil as default, AbortError };

// ------------------------------------------------------ A DEMONSTRATION -- //
// To run, uncomment the following block and invoke:
//   npx tsc src/utils/RetryUtil.ts
//   node src/utils/RetryUtil.js
/* ------------------------------------------------------------------------- //
import fetch, { Response } from "node-fetch";
let failureChance = 1.0;
const theAction = async function (retryCount: number): Promise<string> {
  console.log("Attempt #" + retryCount);
  if (Math.random() <= failureChance) {
    console.log("Mocked failure, so we can see the retry logic.");
    failureChance = failureChance / 2;
    return Promise.reject(new Error("Fake error, as if the call failed."));
  } else {
    return new Promise((resolve, reject) => {
      fetch("http://www.example.com/")
        .then((response: Response) => {
          if (400 <= response.status && response.status <= 499) {
            reject(new AbortError("Abort retries due to client error"));
          } else {
            resolve(response.text());
          }
        })
        .catch((err: Error) => reject(err));
    });
  }
};
RetryUtil.retry(theAction, { retries: 3 })
  .then((result) => console.log("Fetched:", result))
  .catch((err) => console.error("Error:", err))
  .finally(() => console.log("finally"));
// ------------------------------------------------------------------------- */
