import { Module } from "vuex";
import { CreatePollPayload, PollAnswerPayload, PollResultsPayload, PollState, RootState } from "@/types";
import { sockets } from "@/services/socket_api";

const PollModule: Module<PollState, RootState> = {
  namespaced: true,
  state(): PollState {
    return {
      pollAnswers: [],
      activePollSlide: "",
    };
  },
  mutations: {
    setPollState(state: PollState, baseStateOptions: Partial<PollState>): void {
      Object.assign(state, baseStateOptions);
    },
  },
  getters: {
    isPollActive(state, getters, rootState) {
      return (rootState as RootState).CourseModule?.activeContentId === state.activePollSlide;
    },
  },
  actions: {
    createPoll(_, slideId: string): void {
      sockets.poll.create(slideId);
    },
    closePoll(_, slideId: string): void {
      sockets.poll.close(slideId);
    },
    answerPoll(_, payload: { answers: string[]; slideId: string }): void {
      sockets.poll.answer(payload.slideId, payload.answers);
    },
    updateStatus(_, slideId: string): void {
      sockets.poll.status(slideId);
    },
    onCreatePoll({ commit }, payload: CreatePollPayload) {
      commit("setPollState", { activePollSlide: payload.slideId, pollAnswers: [] });
    },
    onClosePoll({ commit }, payload: PollResultsPayload) {
      commit("setPollState", {
        activePollSlide: "",
        pollAnswers: payload.answers,
      });
    },
    onAnswerPoll({ state, commit }, payload: PollAnswerPayload) {
      const answers = [...state.pollAnswers];
      const idx = answers.findIndex((e) => e.securityId === payload.answer.securityId);
      if (idx != -1) answers.splice(idx, 1);
      answers.push(payload.answer);
      commit("setPollState", { pollAnswers: answers });
    },
    onPollStatus({ commit }, payload: PollResultsPayload) {
      commit("setPollState", { pollAnswers: payload.answers });
    },
  },
};

export default PollModule;
