import { http } from "@/http";
import Routes from "@/services/routes";
import store from "@/store";
import { IconResponse } from "@/types/icon";

const Icon = {
  async loadGlobal(): Promise<void> {
    const url = `${Routes.ICON}`;
    await http.get<IconResponse>(url).then((res) => {
      if (res.data.entries) {
        const icons = res.data.entries.map((icon) => ({
          title: icon.title,
          url: icon.url,
        }));
        store.commit("IconModule/addGlobalIcons", icons);
      }
    });
  },
};
export default Icon;
