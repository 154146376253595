<template lang="pug">
Popup.mb-20(
  height="390px",
  width="650px",
  closeButton,
  ok="Download and End",
  cancel="Cancel",
  okAndAction="End Now",
  :visible="isEndingSession",
  :closeOnCancelClick="false",
  :closeOnOkClick="false",
  :closeOnOkAndActionClick="true",
  :closeOutside="true",
  :useCustomClose="true",
  :cancelLoading="downloadingChat",
  :cancelDisabled="downloadingChat",
  @ok="endSessionDownload",
  @okAndAction="endSession",
  @cancel="endSessionCancelled",
  @hiding="endSessionCancelled"
)
  .end-session-popup
    .end-session-header-container
      p You Are Ending {{ endingText }} for <u>All Learners!</u>
    Icon(:value="`alert-octagon`", :color="`var(--error-50)`", :height="`70px`", :width="`70px`")
    .end-session-body-container
      p {{ bodyText }}
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { ChatModel, StopClassroom, SetRootState } from "@/types";
import { EventContentModel, BasePopup as Popup } from "@cruciallearning/puddle";
import config from "@/config";
import Download from "@/utils/download";
import { Icon } from "@cruciallearning/puddle/components";
import socket from "@/services/socket";
import routes from "@/services/routes";
import { http } from "@/http";

@Component({
  components: { Popup, Icon },
  computed: {
    ...mapState(["isClassroomStarted", "isClassroomStarted", "isEndingSession", "downloadingChat"]),
    ...mapState("ChatModule", ["main"]),
    ...mapState("EventModule", ["event"]),
    ...mapGetters("EventModule", ["sessionNumber", "restartSessionNumber", "isRestarting"]),
  },

  methods: {
    ...mapMutations(["setRootState"]),
    ...mapActions("SidebarModule", ["openSideView"]),
    ...mapActions(["stopClassroom"]),
  },
})
export default class EndSessionPopup extends Vue {
  private readonly stopClassroom!: StopClassroom;
  private readonly setRootState!: SetRootState;
  private readonly downloadingChat!: boolean;

  private readonly main!: ChatModel[];
  private readonly event!: EventContentModel;
  private readonly isClassroomStarted!: boolean;
  private readonly sessionNumber!: number;
  private readonly restartSessionNumber!: number;
  private readonly isRestarting!: boolean;
  private endPending = false;

  get endingText(): string {
    if (this.isEndCourse) return "the Course";
    return `Session ${this.currentSession} of ${this.event.learningSessions.length}`;
  }

  get currentSession(): number {
    if (this.isRestarting) return this.restartSessionNumber;
    return this.sessionNumber;
  }

  get bodyText(): string {
    if (this.isEndCourse) return "This Is Your Last chance to Download the Chat Record.";
    return "Download Chat Before Ending?";
  }

  get isPractice(): boolean {
    if (this.event) return this.event.practice;
    return false;
  }

  get isEndCourse(): boolean {
    return this.sessionNumber >= this.eventSessionCount;
  }

  get eventSessionCount(): number {
    return this.event.learningSessions.length;
  }

  endSessionDownload(): void {
    this.setRootState({ downloadingChat: true });
    this.endPending = true;
    Download.saveChatList(this.main);
    if (!this.downloadingChat) {
      this.endPending = false;
      this.endSession();
    }
  }

  async endSession(): Promise<void> {
    if (!this.endPending) {
      await this.$api.auditron.add(
        config.backendUrl,
        {
          action: this.isEndCourse ? "EVENT_CLOSE" : "SESSION_END",
          targetObjectId: this.event.id,
          targetCollection: "learningEvent",
          salesforceId: this.event.salesForceId,
          tags: [
            "virtual",
            "session",
            this.isEndCourse ? "close event" : "end session",
            this.event.referenceNumber,
            `${this.$auth.authUser.given_name} ${this.$auth.authUser.family_name}`,
          ],
          changes: [
            {
              scope: this.isEndCourse ? "events.sessions.close" : "events.sessions.end",
              newValue: this.sessionNumber,
            },
          ],
        },
        http
      );
    }
    await this.$api.event.close(this.event.id, `${this.currentSession}`);
    if (this.isEndCourse) {
      socket.send({ type: routes.TRAINING_EVENT_CLOSE, trainingEventId: this.event.id, delay: 60 });
    }
    this.stopClassroom();
  }

  endSessionCancelled(): void {
    this.setRootState({ isEndingSession: false });
    this.endPending = false;
  }

  @Watch("downloadingChat")
  chatDownloaded(): void {
    if (!this.downloadingChat && this.endPending) {
      this.endSession();
    }
  }
}
</script>
<style scoped lang="scss">
.end-session-popup > div {
  margin: 1rem auto;
}
.end-session-header-container p {
  font-weight: bold;
}
</style>
