import { GeneralPayload } from "@/types/general";
import { MediaUpdateType } from "@/types";
import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { Point } from "@/types/Annotation";
import { getInstance } from "@cruciallearning/puddle/auth";

const general = {
  send(message: string, destination: string, securityId?: string): void {
    socket.send({ type: routes.GENERAL, message, destination, ...(securityId && { userId: securityId }) });
  },
  receive(payload: GeneralPayload): void {
    const route = payload.type.split(".");
    const auth = getInstance();
    switch (route[1]) {
      case "mute":
        if (route[2] === MediaUpdateType.LEARNERS && auth.ADMIN) return;
        store.dispatch("VonageModule/muteSelf", { root: true });
        break;
      case "video":
        if (route[2] === MediaUpdateType.LEARNERS && auth.ADMIN) return;
        store.dispatch("VonageModule/shutterSelf", { root: true });
        break;
      case "annotation":
        if (route[2] === "point") {
          const message = payload.body.message;
          if (message) {
            const point: Point = { ...JSON.parse(message) };
            point.senderId = payload.body.senderId;
            store.commit("AnnotationsModule/addPoint", point);
          }
        } else if (route[2] === "break") {
          store.commit("AnnotationsModule/setAnnotationState", { lineBreak: true });
        } else if (route[2] === "clear") {
          store.commit("AnnotationsModule/setAnnotationState", { clear: true });
        }
        break;
      case "session":
        if (route[2] === "continue") store.commit("setRootState", { isStaleSession: false });
        else console.log("Invalid session message");
        break;
      case "activeSpeaker":
        if (route[2] === "claim") {
          if (route.length > 2 && route[3] === "breakout") {
            store.commit("setRootState", { breakoutActiveSpeakerId: payload.body.senderId });
          } else {
            store.commit("setRootState", { activeSpeakerId: payload.body.senderId });
          }
        } else console.log("Invalid active speaker message");
        break;
      case "breakout":
        if (route[2] === "broadcast") {
          store.dispatch("BreakoutsModule/broadcastReceived", {
            message: payload.body.message,
            senderId: payload.body.senderId,
          });
        }
        break;
      case "typing":
        if (route[2] === "start") {
          store.dispatch("ChatModule/updateTypers", {
            senderId: payload.body.senderId,
            group: payload.body.message,
          });
        }
        break;
      default:
        console.log("Invalid general message");
        break;
    }
  },
};
export default general;
