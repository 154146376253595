<template lang="pug">
.slide-preview
  SelectBox(
    ref="lessonSelect",
    dataName="slide-select-button-data",
    data-name="slide=select-button",
    displayExpr="name",
    valueExpr="title",
    :returnExpr="['title']",
    :dataSource="getLessons",
    :value="activeLessonId",
    @valueChanged="onLessonChange"
  )
  .back-stage-header(data-name="slide-header", style="display: flex")
    span(style="vertical-align: middle") Slides
    ModelToggle
  .slides-list(data-name="slides-list-container")
    ul.list-unstyled(data-name="slides-list")
      li(
        :id="getTitle(slide.index)",
        v-if="getLesson",
        v-for="(slide, index) in slides",
        :class="{ active: getTitle(slide.index) === activeContentId }",
        @click="onSlideChange(index)"
      )
        img(:src="slide.frames[0].content.image.url", style="width: 75px; height: 45px")
        p.slide-number {{ slide.trainerPage }}
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import ModelToggle from "./ModelToggle.vue";
import { Lesson, Slides } from "@/types/course";
import { BaseSelectBox as SelectBox } from "@cruciallearning/puddle";

@Component({
  components: { SelectBox, ModelToggle },
  computed: {
    ...mapState("CourseModule", ["activeContentId", "activeLessonId"]),
    ...mapGetters("CourseModule", ["getLesson", "getLessons"]),
  },
})
export default class SlidePreview extends Vue {
  private readonly getLesson!: Lesson | undefined;

  get slides(): Slides[] {
    if (!this.getLesson) return [];
    return this.getLesson.slides;
  }

  getTitle(contentIndex: number): string {
    return `${this.getLesson?.title} ${contentIndex}`;
  }

  setLesson(lessonId: string): void {
    (this.$refs.lessonSelect as SelectBox).setValue(lessonId);
  }

  onLessonChange(selectedItem: { title: string }): void {
    this.$emit("lessonChange", selectedItem.title);
  }

  onSlideChange(slideIndex: number): void {
    this.$emit("slideChange", slideIndex);
  }
}
</script>
