/*
 * ------------------------------------------------------------------------------
 * Enum
 * ------------------------------------------------------------------------------
 */
export enum VideoCommand {
  NONE = "",
  PAUSE = "pause",
  PLAY = "play",
  STOP = "stop",
}

export enum VideoOverlay {
  OPEN,
  CLOSE,
}

export enum VideoStates {
  BEFOREPLAY = "beforeplay",
  PLAYING = "playing",
  PAUSED = "paused",
  ENDED = "ended",
}

/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */

// For available options, see https://github.com/wistia/wistia-open-docs/blob/master/_posts/embed-options.md
export interface VideoConfig {
  id: string;
  options: {
    autoPlay: boolean;
    controlsVisibleOnLoad: boolean;
    videoFoam: boolean;
    wmode: string;
    volumeControl: boolean;
    fullscreenButton: boolean;
    smallPlayButton: boolean;
    playbar: boolean;
    copyLinkAndThumbnailEnabled: false;
    playButton: boolean;
    muted?: boolean;
    plugin: {
      "captions-v1": {
        transcript: boolean;
        onByDefault: string | null | boolean;
      };
    };
  };
  onReady: (video: VideoControls) => void;
}
export interface VideoControls {
  bind: (s: string, cb?: (arg: never) => void) => void;
  remove: () => void;
  play: () => void;
  pause: () => void;
  state: () => VideoStates;
  time: (time?: number) => void | undefined | number;
  height: (px: number, options: { constrain: boolean }) => void;
}
export interface VideoPayload {
  type: string;
  body: {
    position: number;
    senderId: string;
    state: string;
  };
}
export interface VideoInfo {
  position: number;
  state: string;
  startTime: string;
}
export interface VideoState {
  firstActionUserId: string;
  lastActionUserId: string;
  overlay: boolean;
  playing: boolean;
  position: number;
  processingUpdate: boolean;
}

/*
 * ------------------------------------------------------------------------------
 * Methods
 * ------------------------------------------------------------------------------
 */
export interface EndVideo {
  (): void;
}
export interface SetVideoState {
  (videoStateOptions: Partial<VideoState>): void;
}

export interface PlayVideoBroadcast {
  (position: number): void;
}

export interface PauseVideoBroadcast {
  (): void;
}

export interface CloseOverlay {
  (): void;
}
export interface ResetVideo {
  (): void;
}
