export * from "./mockdata";

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export const generatedId: () => string = (): string => String(Math.random()).split(".")[1];

export function getRandomID(): string {
  return "_" + Math.random().toString(36).substring(2, 9);
}

export function getUrlParameters(parameter: string): string | null {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(parameter);
}

export function avatarStyle(first: string, last: string): string {
  const red = ((first.toUpperCase().charCodeAt(0) - 65) / 26) * 255;
  const green = ((last.toUpperCase().charCodeAt(0) - 65) / 26) * 255;
  const blue = ((last.toUpperCase().charCodeAt(last.length - 1) - 65) / 26) * 255;
  const rgb = `rgb(${red},${green},${blue})`;
  const darkText = red * 0.299 + green * 0.587 + blue * 0.114 > 125;
  const text = darkText ? "#000000" : "#ffffff";
  return `background-color:${rgb};color:${text}`;
}

export function chatGroupName(u1: string, u2: string) {
  return u1 < u2 ? `${u1}.${u2}` : `${u2}.${u1}`;
}
