<template lang="pug">
.hands-raised-container(v-if="$auth.ADMIN && this.handsRaisedCount > 0")
  .raised-hands
    .display-count-parent
      span {{ handsRaisedCount }} {{ handsRaisedText }}
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { UserModel } from "@/types";
import { ReactionUserModel } from "@/types/reaction";
import { ReactionType } from "@/types/status";

@Component({
  computed: { ...mapState("UsersModule", ["statuses", "users"]) },
})
export default class HandsRaised extends Vue {
  private usersArray: UserModel[] = [];
  private readonly users!: UserModel[];
  private readonly statuses!: ReactionUserModel[];

  get handsRaisedCount(): number {
    this.usersArray.length = 0;
    this.users.forEach((user) => {
      const handUp = this.statuses.find(
        (e) => e.securityId === user.securityId && e.status === ReactionType.RAISE_HAND
      );
      if (handUp) this.usersArray.push(user);
    });
    return this.usersArray.length;
  }
  get handsRaisedText(): string {
    if (this.handsRaisedCount == 1) return " Hand Raised";
    return " Hands Raised";
  }
}
</script>
<style scoped lang="scss">
.raised-hands {
  color: black;
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: 550;
  font-family: "Flama", "Avenir";
}
.hands-raised-container {
  display: flex;
  background-color: #ff993b;
  margin-top: 4px;
  height: 40px;
  padding-top: 3px;
  padding-left: 12px;
  margin-left: -9px;
}
.display-count-parent {
  text-align: center;
  padding-top: 8px;
  padding-right: 8px;
  font-size: 18px;
}
</style>
