/*****************************************************************************
 *             WARNING: THIS SHOULD NOT BE READ FROM SOURCE CODE             *
 *****************************************************************************
 * This is intended to support dev/debugging activities from the browser's   *
 * DevTools JavaScript console. The global container should be treated as    *
 * WRITE-ONLY from the frost source code. It should only be read from the    *
 * browser's JS console.                                                     *
 *****************************************************************************/

import { KeyUnknownModel } from "@/types";
import WebMediaUtil from "@/utils/WebMediaUtil";
import config from "@/config";

declare global {
  interface Window {
    __CLXG__: KeyUnknownModel;
  }
}

/**
 * Adds some convenience methods to globalThis for easy access from the
 * browser's JS console.
 */
class Globals {
  /** Ensures the global container is initialized */
  public static init(): void {
    if (Globals.shouldPublishGlobals()) {
      window.__CLXG__ = window.__CLXG__ || {};
    }
    Globals.addDefaultGlobals();
  }

  /** Adds a property to our global-scope container */
  public static addGlobal(key: string, value: unknown): void {
    if (value === undefined) {
      this.removeGlobal(key);
    } else {
      if (Globals.canPublishGlobals()) {
        window.__CLXG__[key] = value;
      }
    }
  }

  /** Removes a property from our global-scope container */
  public static removeGlobal(key: string): void {
    if (Globals.canPublishGlobals()) {
      delete window.__CLXG__[key];
    }
  }

  /** adds current "standard" global vars */
  private static addDefaultGlobals(): void {
    Globals.addGlobal("WebMediaUtil", WebMediaUtil);
  }

  /** @returns true IFF the global-scope container should be supported */
  private static shouldPublishGlobals(): boolean {
    return config && config.isntProd();
  }

  /** @returns true IFF the global-scope container is available */
  private static canPublishGlobals(): boolean {
    return Globals.shouldPublishGlobals() && window?.__CLXG__ != null;
  }
}

export default Globals;
