import axios, { AxiosInstance, AxiosResponse } from "axios";

const http: AxiosInstance = axios.create({
  baseURL: location.origin,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.defaults.timeout = 12000;

http.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error: Error): Promise<Error> => {
    return Promise.reject(error);
  }
);

http.interceptors.request.use((request) => {
  request.url = request.url?.replaceAll("|", "%7C");
  return request;
});

export { http };
