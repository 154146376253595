import { PingPayload } from "@/types";
import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";

const ping = {
  send(): void {
    socket.send({ type: routes.PING });
  },
  receive(payload: PingPayload): void {
    switch (payload.type) {
      case routes.PING:
        store.dispatch("processInactivity", payload.body.inactivity);
        break;
      default:
        console.log("Invalid pulse message");
        break;
    }
  },
};
export default ping;
