import store from "@/store";
import Routes from "@/services/routes";
import { http } from "@/http";
import { CourseResponse } from "@/types/course";

const Course = {
  async getCourse(eventId: string): Promise<void> {
    const url = `${Routes.COURSE}/${eventId}`;
    await http.get<CourseResponse>(url).then((res) => {
      store.commit("CourseModule/setCourse", res.data.course);
    });
  },
};

export default Course;
