<template lang="pug">
span.step-status(:style="`color: ${status.color}`") {{ status.message }}
</template>
<script lang="ts">
import { StepState, SystemCheckStep } from "@/types/SystemCheck";
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
@Component({
  computed: {
    ...mapGetters("SystemCheckModule", ["activeStep"]),
    ...mapGetters("SystemCheckModule", ["isStepRequired"]),
  },
})
export default class Status extends Vue {
  @Prop({ required: false, default: false }) forceFail!: boolean;
  private readonly isStepRequired!: (name: string) => boolean;
  private readonly activeStep!: SystemCheckStep;

  get status(): { color: string; message: string } {
    const check = this.forceFail ? StepState.FAIL : this.activeStep.state;
    switch (check) {
      case StepState.SUCCESS:
        return { color: "green", message: "pass" };
      case StepState.FAIL:
        if (this.isStepRequired(this.activeStep.name)) {
          return { color: "red", message: "fail" };
        } else {
          return { color: "#F75301", message: "caution" };
        }
      default:
        return { color: "", message: "" };
    }
  }
}
</script>
<style lang="scss" scoped>
.step-status {
  height: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.75px;
}
</style>
