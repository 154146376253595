<template lang="pug">
li.breakout-list-item
  Icon.pr-8.alert-circle(
    v-if="isUserAssignmentInvalid",
    value="alert-circle",
    color="var(--warn-20)",
    height=".8rem",
    width=".8rem",
    @mouseover="onMouseOver",
    @mouseleave="onMouseLeave"
  )
  .dx-icon-isnotblank.pr-8(v-else, :class="active ? 'activeColor' : 'inactiveColor'")
  .title
    p#invalidBreakout.name(
      v-if="isUserAssignmentInvalid",
      :data-name="breakout.id",
      @mouseover="onMouseOver",
      @mouseleave="onMouseLeave"
    ) {{ breakout.name }}
    p.name(v-else, :data-name="breakout.id") {{ breakout.name }}
  .breakout-actions
    SquareButton(
      v-if="active",
      data-name="stopBreakout",
      icon="stop-circle",
      color="var(--error-50)",
      @click="stopBreakout"
    )
    SquareButton(
      v-else,
      data-name="startBreakout",
      icon="play",
      color="var(--success-50)",
      :disabled="!isClassroomStarted || notActive",
      @click="startBreakout({ payload: { id: breakout.id, slide: breakout.slide } })"
    )
    SquareButton(
      icon="more-vertical",
      color="black",
      :id="listId",
      :isPressed="isActionMenuOpen",
      @click="isActionMenuOpen = !isActionMenuOpen"
    )
    PopoverMenu(
      v-if="isActionMenuOpen",
      :target="`#${listId}`",
      :visible="isActionMenuOpen",
      popover-class="breakout-list-menu",
      width="165px",
      @hiding="isActionMenuOpen = false"
    )
      BreakoutListActions(:breakout="breakout", :active="active", @closed="isActionMenuOpen = false")
  ToolTip(
    target="#invalidBreakout",
    data-name="invalid-group-tooltip",
    :display="invalidUsers",
    :toggle="showBreakoutToolTip"
  )
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Breakout, UserModel } from "@/types";
import { mapActions, mapGetters, mapState } from "vuex";
import { Icon } from "@cruciallearning/puddle/components";
import { EventContentModel, BaseToolTip as ToolTip, BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";
import SquareButton from "@/components/widgets/SquareButton.vue";
import BreakoutListActions from "@/components/Breakout/BreakoutListActions.vue";

@Component({
  components: { Icon, ToolTip, SquareButton, PopoverMenu, BreakoutListActions },
  computed: {
    ...mapState(["isClassroomStarted"]),
    ...mapState("BreakoutsModule", ["activeId", "isActive", "getAvailableUsers"]),
    ...mapGetters("UsersModule", ["getById"]),
    ...mapState("EventModule", ["event"]),
    ...mapGetters("UsersModule", ["getAllLearners", "getTrainers", "getModerators"]),
  },
  methods: {
    ...mapActions("BreakoutsModule", ["startBreakout", "stopBreakout"]),
  },
})
export default class BreakoutListItem extends Vue {
  @Prop({ required: true }) readonly breakout!: Breakout;

  private showBreakoutToolTip = false;
  private isActionMenuOpen = false;

  private readonly activeId!: string;
  private readonly isActive!: boolean;
  private readonly isClassroomStarted!: boolean;
  private readonly event!: EventContentModel;

  private readonly getAllLearners!: UserModel[];
  private readonly getTrainers!: UserModel[];
  private readonly getModerators!: UserModel[];

  get active(): boolean {
    return this.isActive && this.activeId === this.breakout.id;
  }

  get notActive(): boolean {
    return this.isActive && this.activeId !== this.breakout.id;
  }

  get isUserAssignmentInvalid(): boolean {
    return this.invalidUsers.length > 0;
  }

  get listId(): string {
    return `breakout-li-${this.breakout.id}`;
  }

  get invalidUsers(): string[] {
    const validLearners: string[] = [];
    const invalidLearners: string[] = [];
    for (const group of this.breakout.groups) {
      if (!group.userIds) continue;
      for (const userId of group.userIds) {
        const trainer = this.getTrainers.find((l) => l.securityId == userId);
        const moderator = this.getModerators.find((l) => l.securityId == userId);
        if (!trainer && !moderator) {
          const learner = this.getAllLearners.find((l) => l.securityId == userId);
          if (learner) {
            validLearners.push(userId);
            if (learner.away) invalidLearners.push(`${learner.firstName} ${learner.lastName}: Away`);
          } else {
            const user = this.event.registrationCode.registrants.find((e) => e.securityId == userId);
            if (user) invalidLearners.push(`${user.firstName} ${user.lastName}: Not joined`);
          }
        }
      }
    }

    for (const learner of this.getAllLearners) {
      if (!validLearners.includes(learner.securityId))
        invalidLearners.push(`${learner.firstName} ${learner.lastName}: Unassigned`);
    }
    return invalidLearners;
  }
  onMouseOver(): void {
    this.showBreakoutToolTip = true;
  }
  onMouseLeave(): void {
    this.showBreakoutToolTip = false;
  }
}
</script>
<style lang="scss" scoped>
.breakout-actions {
  display: flex;
}
.breakout-list-item {
  border-bottom: var(--gray-50) 1px solid;
}
</style>
