import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import {
  ReactionClearAllPayload,
  ReactionClearOnePayload,
  ReactionPayload,
  ReactionUpdatePayload,
} from "@/types/reaction";
import { ReactionType } from "@/types/status";

const reaction = {
  update(status: ReactionType): void {
    socket.send({ type: routes.REACTION_UPDATE, status });
  },
  clear(userId: string): void {
    socket.send({ type: routes.REACTION_CLEAR_USER, userId });
  },
  clearAll(): void {
    socket.send({ type: routes.REACTION_CLEAR });
  },
  receive(payload: ReactionPayload): void {
    switch (payload.type) {
      case routes.REACTION_UPDATE:
        store.dispatch("UsersModule/processReactionStatus", payload.body as ReactionUpdatePayload);
        break;
      case routes.REACTION_CLEAR:
        store.commit("UsersModule/populateStatuses", (payload.body as ReactionClearAllPayload).statuses);
        break;
      case routes.REACTION_CLEAR_USER:
        store.commit("UsersModule/clearUserStatus", payload.body as ReactionClearOnePayload);
        break;
      default:
        console.log("Invalid reaction message");
        break;
    }
  },
};
export default reaction;
