<template lang="pug">
.resizable-overlay.layout-grid.presentation(:class="{ 'has-back-stage': $auth.ADMIN }", @mouseleave="clearResize")
  ResizeColumn(v-for="i in 12", :idx="i", :key="i", @clearResize="clearResize")
</template>
<script lang="ts">
import { ResizingOption, SetGridState } from "@/store/modules/Grid/models";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import ResizeColumn from "./GridResizeColumn.vue";
@Component({
  components: { ResizeColumn },
  methods: {
    ...mapMutations("GridModule", ["setGridState"]),
  },
})
export default class GridResize extends Vue {
  private readonly setGridState!: SetGridState;

  clearResize(): void {
    this.setGridState({ resizingOption: ResizingOption.NONE });
  }
}
</script>
<style lang="scss" scoped>
.resizable-overlay {
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: none;
  border: none;
  cursor: ew-resize;
}
</style>
