<template lang="pug">
.toolbar
  template(v-if="isUserReady && !isLeavingSession")
    SelectBox.statusSelectBox(
      v-if="!getActiveBreakoutSelf && reactionsEnabled",
      ref="statusSelectBox",
      placeholder="Reaction",
      valueChangeEvent="input",
      valueExpr="display",
      :displayExpr="displayExpression",
      :dataSource="statusesDatasource",
      :value="selfStatus",
      width="175px",
      height="32px",
      @valueChanged="statusChanged"
    )
    Controls
    .divider

  Leave(
    :showLeave="showLeaveButton",
    :showEnd="showEndButton",
    :isAdmin="isAdmin",
    @endSession="endSession",
    @leaveSession="leaveSession"
  )
    EndSession
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Account from "./Account/Account.vue";
import Controls from "./Controls/Controls.vue";
import { BaseSelectBox as SelectBox } from "@cruciallearning/puddle";
import { CLEAR, CLEAR_ALL, ReactionType, StatusModel, StatusOptions } from "@/types/status";
import { ClearAllReactions, UpdateReactionStatus, UserModel } from "@/types/users";
import { ReactionUserModel } from "@/types/reaction";
import EndSession from "@/components/Popup/EndSession.vue";
import config from "@/config";
import { SetRootState } from "@/types";
import { default as Leave } from "@/components/Leave/LeaveButton.vue";

@Component({
  components: { Controls, Account, SelectBox, Leave, EndSession },
  computed: {
    ...mapState(["isLeavingSession", "isClassroomStarted"]),
    ...mapState("UsersModule", ["statuses"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapGetters(["isUserReady"]),
    ...mapGetters("UsersModule", ["getSelf", "getTrainers", "getModerators"]),
    ...mapGetters("EventModule", ["reactionsEnabled"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
    ...mapActions("UsersModule", ["updateReactionStatus", "clearAllReactions"]),
  },
})
export default class BaseToolbar extends Vue {
  private readonly statuses!: ReactionUserModel[];
  private readonly setRootState!: SetRootState;
  private readonly updateReactionStatus!: UpdateReactionStatus;
  private readonly clearAllReactions!: ClearAllReactions;
  private readonly getSelf!: UserModel | undefined;
  private readonly isClassroomStarted!: boolean;
  private readonly getTrainers!: UserModel[];
  private readonly getModerators!: UserModel[];

  get statusesDatasource(): StatusModel[] {
    const statuses = [...StatusOptions];
    if (this.selfStatus && this.selfStatus !== ReactionType.CLEAR && this.selfStatus !== ReactionType.STATUS) {
      statuses.push(CLEAR);
    }
    if (this.$auth.ADMIN) {
      statuses.push(CLEAR_ALL);
    }
    return statuses;
  }

  get selfStatus(): ReactionType | undefined {
    if (this.statuses) {
      const status = this.statuses.find((e) => e.securityId === this.getSelf?.securityId);
      if (status) return status.status;
    }
    return ReactionType.STATUS;
  }

  get showLeaveButton(): boolean {
    return this.multipleTrainers || !this.isClassroomStarted || !this.isAdmin;
  }

  get showEndButton(): boolean {
    return this.isClassroomStarted && this.isAdmin;
  }

  get isAdmin(): boolean {
    return (
      this.getTrainers.findIndex((e) => e.securityId === this.$auth.authUser.id) != -1 ||
      this.getModerators.findIndex((e) => e.securityId === this.$auth.authUser.id) != -1
    );
  }

  get multipleTrainers(): boolean {
    return this.getTrainers.length + this.getModerators.length > 1;
  }

  leaveSession(): void {
    window.open(config.vaporUrl, "_self");
  }

  endSession(): void {
    this.setRootState({ isEndingSession: true });
  }

  displayExpression = (status: StatusModel): string => {
    if (!status) return "Status";
    return `${status.emoji}${status.display}`;
  };
  statusChanged(status: StatusModel): void {
    if (status) {
      if (status.display == ReactionType.STATUS) {
        return;
      }
      if (status.display == ReactionType.CLEAR_ALL) {
        if (this.selfStatus && this.selfStatus === ReactionType.AWAY) {
          (this.$refs.statusSelectBox as SelectBox).setValue(ReactionType.AWAY);
        } else {
          (this.$refs.statusSelectBox as SelectBox).reset();
        }
        this.clearAllReactions();
      } else {
        this.updateReactionStatus(status.display);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.statusSelectBox {
  padding-top: 8px !important;
  margin-bottom: 10px !important;
}
.learner-page-number {
  margin-right: 100px;
}
</style>
