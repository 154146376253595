<template lang="pug">
.chat-controls-container
  CharCounter(:message="message")
  Typing(v-if="isSomeoneTyping", :type="type")
  .chat-controls(data-name="chat-controls")
    .emoji(@click="toggleEmojiMenu(!showEmojis)", :id="`${type}EmojiIcon`")
      Icon(value="smile", data-name="mainEmojiIcon")
    .submit(@click.prevent="onSubmit", data-name="submit")
      Icon(value="send", color="white")
  PopoverMenu(
    :target="`#${type}EmojiIcon`",
    :visible="showEmojis",
    @hiding="toggleEmojiMenu(false)",
    :popoverPosition="emojiPosition",
    targetPosition="center top",
    width="230px",
    popover-class="attendeeMenu",
    data-name="emojiMenu"
  )
    .emojis
      ul.list-unstyled
        li.icon-button(
          v-for="(emoji, index) in getEmojis",
          @click="onEmojiClick(emoji)",
          :data-name="'emoji-index-' + index"
        )
          span {{ emoji }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { Icon } from "@cruciallearning/puddle/components";
import { ChatType, SetChatState } from "@/types";
import { mapGetters, mapMutations, mapState } from "vuex";
import { BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";
import Typing from "@/components/Chat/Typing";
import CharCounter from "./CharCounter.vue";
@Component({
  components: { Icon, PopoverMenu, Typing, CharCounter },
  computed: {
    ...mapState("ChatModule", ["activeTyper", "backstageTyper"]),
    ...mapGetters("ChatModule", ["getEmojis"]),
  },
  methods: {
    ...mapMutations("ChatModule", ["setChatState"]),
  },
})
export default class ChatControls extends Vue {
  @Prop({ required: true }) readonly onSubmit!: () => void;
  @Prop({ required: false }) readonly message!: string;
  @Prop({ required: true })
  readonly type!: ChatType | "broadcast";
  @Prop({ required: false, default: "right bottom" }) readonly emojiPosition!: string;

  private readonly setChatState!: SetChatState;

  private readonly activeTyper!: string;
  private readonly backstageTyper!: string;
  private showEmojis = false;

  toggleEmojiMenu(show: boolean) {
    this.showEmojis = show;
  }
  onEmojiClick(emoji: string) {
    this.showEmojis = false;
    this.$emit("emojiClick", emoji);
  }

  get isBackstage(): boolean {
    return this.type === ChatType.BACKSTAGE;
  }

  get isSomeoneTyping(): boolean {
    return this.isBackstage ? this.backstageTyper != "" : this.activeTyper != "";
  }
}
</script>
