import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import { SlidePayload } from "@/types/slides";

const slide = {
  goto(slideId: string): void {
    socket.send({ type: routes.SLIDE_GOTO, slideId });
  },
  setShowModel(showModel: boolean): void {
    socket.send({ type: routes.SLIDE_MODEL, showModel });
  },
  setShowAltModel(showModel: boolean): void {
    socket.send({ type: routes.SLIDE_MODEL_ALT, showModel });
  },
  receive(payload: SlidePayload): void {
    switch (payload.type) {
      case routes.SLIDE_GOTO:
        store.dispatch("CourseModule/navigateReceived", payload.body.slideId);
        break;
      case routes.SLIDE_INFO:
        store.dispatch("CourseModule/navigateReceived", payload.body.slideId);
        break;
      case routes.SLIDE_MODEL: {
        const showModel = payload.body.showModel;
        const update: { showModel?: boolean; showAltModel?: boolean } = { showModel: showModel };
        if (showModel) {
          update.showAltModel = false;
        }
        store.commit("CourseModule/setCourseState", update);
        break;
      }
      case routes.SLIDE_MODEL_ALT: {
        const showAltModel = payload.body.showModel;
        const update: { showModel?: boolean; showAltModel?: boolean } = { showAltModel: showAltModel };
        if (showAltModel) {
          update.showModel = false;
        }
        store.commit("CourseModule/setCourseState", update);
        break;
      }
      default:
        console.log("Invalid slide message");
        break;
    }
  },
};

export default slide;
