/*
 * ------------------------------------------------------------------------------
 * Types
 * ------------------------------------------------------------------------------
 */
export enum SidebarAdminTabs {
  CHAT = "Chat",
  SLIDES = "Slides",
  TOOLS = "Tools",
}

export enum SidebarUserTabs {
  ATTENDEES = "Attendees",
  CHAT = "ChatList",
  DOCUMENTS = "Documents",
}

export enum SideViewType {
  ASSIGN = "AutoAssign",
  BREAKOUT = "Breakout",
  CONFIRM_END_SESSION = "ConfirmSession",
  CONFIRM_END_COURSE = "ConfirmCourse",
  GROUP = "Group",
  NONE = "",
  PARTICIPANTS = "Participants",
  REMOVE = "Remove",
  SLIDE = "SelectSlide",
}

/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */
export interface SidebarState {
  adminSideView: SideViewType;
  adminTab: SidebarAdminTabs;
  userTab: SidebarUserTabs;
}

/*
 * ------------------------------------------------------------------------------
 * Functions
 * ------------------------------------------------------------------------------
 */
export interface SetSidebarState {
  (sidebarStateOptions: Partial<SidebarState>): void;
}

export interface CloseSideView {
  (): void;
}

export interface OpenSideView {
  (sideView: SideViewType): void;
}

export interface SelectAdminTab {
  (tab: SidebarAdminTabs): void;
}

export interface SelectUserTab {
  (tab: SidebarUserTabs): void;
}
