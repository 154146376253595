<template lang="pug">
.tooltip(v-if="isActiveFailed")
  Icon.icon-style(value="sun")
  span.text-container
    span.tip {{ tip }}
    span {{ tipMessage }}
      ul.list
        li(v-for="tip in tipList") {{ tip }}
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Icon } from "@cruciallearning/puddle/components";
import BaseStep from "@/components/SystemCheck/steps/BaseStep.vue";

@Component({ components: { Icon } })
export default class BaseTip extends BaseStep {
  @Prop({ required: false, default: "TIP: " }) tip!: string;
  @Prop({ required: false, default: "" }) tipMessage!: string;
  @Prop({ required: false, default: () => [] }) tipList!: string[];
}
</script>

<style scoped lang="scss">
.tip {
  font-weight: bold;
  padding-right: 5px;
}
.tooltip {
  display: flex;
  padding-top: 30px;
  color: black;
}
.list {
  padding-top: 5px;
  padding-left: 40px;
  line-height: 30px;
}
.text-container {
  font-size: 0.85rem;
  padding-top: 3px;
  line-height: 1.25rem;
}
.icon-style {
  padding-top: 3px;
  padding-right: 3px;
}
</style>
