<template lang="pug">
.pinned-container
  .pinned-header 
    Icon(value="Pin a Chat 45", color="white", height="12px", width="12px")
    span Pinned
  .chat-history(:style="chatListStyle")
    Message.pinned-message(
      v-for="(item, index) in pinnedMessages",
      :type="type",
      :key="index",
      :content="item",
      :fromPinnedOverlay="true",
      :data-name="'pinned-message-' + index"
    )
  .show-more-pinned(:style="pinnedStyle", @click="showMoreClick")
    span {{ buttonText }}
    Icon(:value="showMoreIcon", color="white", height="12px", width="12px")
</template>
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import Message from "./Message/Message.vue";
import { ChatModel, ChatType, PinnedChatModel } from "@/types";
import { Icon } from "@cruciallearning/puddle/components";
import { mapState } from "vuex";
@Component({
  components: { Message, Icon },
  computed: {
    ...mapState("ChatModule", ["pinned"]),
  },
})
export default class Pinned extends Vue {
  private readonly pinned!: PinnedChatModel[];
  private isShowMore = false;
  private type = ChatType.MAIN;
  height = 0;

  mounted(): void {
    this.pinnedUpdated();
  }

  get canExpand(): boolean {
    return this.pinned.length > 1;
  }

  get showMoreIcon(): string {
    return this.isShowMore ? "chevron-up" : "chevron-down";
  }

  get buttonText(): string {
    return this.isShowMore ? "Show Less" : "Show More";
  }
  get pinnedStyle(): string {
    return this.pinned.length <= 1 ? "visibility: hidden" : "";
  }

  get chatListStyle(): string {
    const displayHeight = this.isShowMore ? this.height + 10 : this.height;
    return `height: calc(${displayHeight}px)`;
  }

  get pinnedMessages(): ChatModel[] {
    return [...this.pinned].reverse();
  }

  showMoreClick(): void {
    this.isShowMore = !this.isShowMore;
    this.pinnedUpdated();
  }

  showFirstOnly(): void {
    this.height = this.getHeightOfChild(1);
  }

  showAll(): void {
    const nodes = document.querySelectorAll(".pinned-container .chat-history .pinned-message");
    if (nodes) {
      this.height = Array.from(nodes).reduce(
        (accumulator: number, current: Element) => accumulator + current.clientHeight,
        0
      );
    } else console.log("No pinned messages found");
  }

  getHeightOfChild(n: number): number {
    const child = document.querySelector(`.pinned-container .chat-history .pinned-message:nth-child(${n})`);
    return child?.clientHeight || 0;
  }

  @Watch("pinnedMessages")
  pinnedUpdated(): void {
    setTimeout(() => {
      if (this.pinned.length == 1) {
        this.isShowMore == false;
      }
      if (this.isShowMore) {
        this.showAll();
      } else {
        this.showFirstOnly();
      }
    }, 100);
  }
}
</script>
<style scoped lang="scss">
.pinned-container {
  background-color: var(--info-50);
  border-radius: 0px 0px 0px 8px;
  z-index: 10;
  .chat-history {
    background-color: inherit;
    margin-top: unset;
    overflow: hidden;
    padding-right: 16px;
    transition: all 0.5s;
  }
}
.pinned-header {
  padding: 16px 4px 8px 4px;
  margin-left: 4px;
  font-size: 12px;
  color: white;
}
.show-more-pinned {
  border: 1px solid white;
  width: 35%;
  margin: 0 auto;
  display: flex;
  padding: 4px 4px 4px 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: -10px;
  background-color: var(--info-50);
  border-radius: 6px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  span:nth-child(1) {
    margin-right: 4px;
  }
  &:hover {
    background-color: var(--info-20);
  }
}
</style>
