<template lang="pug">
.contents-passthrough
  .inner-trainer-container
    .close-item(@click="onClose")
      i.icon-x
    .overflow
      .new-breakout-group.no-shrink
        p.back-stage-header
          i.icon-circles-three-plus.align-middle
          | Breakout
        .settings-set
          TextBox(
            small,
            label="Title",
            backgroundColor="white",
            valueChangeEvent="input",
            @valueChanged="onNameChanged",
            data-name="breakoutTitle",
            :maxLength="30",
            :value="editBreakout.name",
            :customRuleMessage="customRuleMessage",
            :customValidationCallback="() => !isDisabled"
          )
          .field
            .checkbox.right-box.mb-8
              input#show-slide.pointer(
                type="checkbox",
                :checked="useCustomSlide",
                @change="onUseCustomSlide($event.target.checked)",
                data-name="customSlide"
              )
              label.pointer(for="show-slide")
                span.label-container(data-name="chooseSlide") Choose Slide
            .breakout-slide-preview(v-show="useCustomSlide")
              img(v-show="editBreakout.slide", :src="imagePreview", style="width: 153px")
              Button(
                v-show="!editBreakout.slide",
                mini,
                text="Choose Slide",
                @click="openSideView(sideViewType.SLIDE)",
                data-name="selectSlide"
              )
            .breakout-group-control
              a.delete.mb-12(
                v-show="editBreakout.slide",
                @click.prevent="updateEditBreakout({ slide: '' })",
                data-name="removeSlide"
              ) Remove Slide
      .manage-groups.no-shrink
        p.back-stage-header Manage Groups

        .settings-set
          .button-group.side-by-side
            #addBreakoutBtns(v-if="editBreakout.id === ''")
              Button.auto-create-breakout(
                mini,
                width="100%",
                text="Auto",
                :disabled="isDisabled",
                @click="openSideView(sideViewType.ASSIGN)",
                data-name="autoAssign"
              )
              Button.manual-create-breakout(
                mini,
                width="100%",
                text="Manual",
                :disabled="isDisabled",
                @click="onAddBreakout",
                data-name="addBreakout"
              )
            Button(
              v-if="editBreakout.id !== ''",
              mini,
              full,
              width="100%",
              text="Add New Group",
              @click="onAddNewGroup",
              data-name="newGroup"
            )
            hr.settings-divider
          div(
            v-if="getEditBreakoutGroups.length > 0",
            v-for="group in getEditBreakoutGroups",
            :id="`breakout-group-${group.id}`"
          )
            BreakoutGroup(:breakout="editBreakout", :group="group", data-name="editBreakout")
            br
  .panel-actions.no-bg
    Button(full, text="Done", :disabled="isDisabled", @click="onClose", data-name="breakoutDone")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  AddBreakout,
  AddGroup,
  BreakoutsState,
  CloseSideView,
  Group,
  SideViewType,
  UpdateEditBreakout,
  UpdateBreakout,
  ResetEditBreakout,
  Breakout,
} from "@/types";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { BaseButton as Button, BaseTextBox as TextBox, BaseToolTip as ToolTip } from "@cruciallearning/puddle";
import BreakoutGroup from "@/components/Sidebar/Breakouts/BreakoutGroup.vue";
import { GetSlideByTitle } from "@/types/course";

@Component({
  components: { Button, BreakoutGroup, TextBox, ToolTip },
  computed: {
    ...mapState("BreakoutsModule", ["editBreakout", "activeGroupId", "breakouts"]),
    ...mapGetters("BreakoutsModule", ["getEditBreakoutGroups", "getEditBreakoutOrigin"]),
    ...mapGetters("CourseModule", ["getSlideByTitle"]),
  },
  methods: {
    ...mapMutations("BreakoutsModule", ["resetEditBreakout"]),
    ...mapActions("BreakoutsModule", ["addBreakout", "addGroup", "updateEditBreakout", "updateBreakout"]),
    ...mapActions("SidebarModule", ["openSideView", "closeSideView"]),
  },
})
export default class AddBreakouts extends Vue {
  private readonly editBreakout!: BreakoutsState["editBreakout"];
  private readonly activeGroupId!: string;
  private readonly getEditBreakoutGroups!: Group[];
  private readonly getEditBreakoutOrigin!: BreakoutsState["editBreakout"];
  private readonly addBreakout!: AddBreakout;
  private readonly addGroup!: AddGroup;
  private readonly closeSideView!: CloseSideView;
  private readonly getSlideByTitle!: GetSlideByTitle;
  private readonly resetEditBreakout!: ResetEditBreakout;
  private readonly updateEditBreakout!: UpdateEditBreakout;
  private readonly updateBreakout!: UpdateBreakout;
  private readonly breakouts!: Breakout[];
  private useCustomSlide = false;
  private sideViewType: unknown = SideViewType;

  mounted(): void {
    this.useCustomSlide = this.editBreakout?.slide ? true : false;
    if (this.activeGroupId) document.querySelector(`#breakout-group-${this.activeGroupId}`)?.scrollIntoView(true);
  }

  get imagePreview(): string {
    return this.getSlideByTitle(this.editBreakout?.slide as string)?.frames[0].content?.image.url || "";
  }

  get isDisabled(): boolean {
    if (!this.editBreakout?.name) return true;
    if (this.breakouts.length == 0) return false;
    return this.isDupTitle;
  }

  get customRuleMessage(): string {
    if (!this.editBreakout?.name) return "Required";
    return "Title must be unique";
  }

  get isDupTitle(): boolean {
    return (
      this.breakouts.findIndex((e) => {
        if (this.editBreakout) {
          if (this.editBreakout.name) {
            return e.name === this.editBreakout.name && e.id != this.editBreakout.id;
          }
        }
        return false;
      }) != -1
    );
  }

  onAddBreakout(): void {
    this.addBreakout({
      name: this.editBreakout?.name as string,
      slide: this.editBreakout?.slide as string,
      autoAssign: false,
      groupCount: 1,
      groupSize: 0,
    });
  }

  onAddNewGroup(): void {
    let name = "Group 1";
    if (this.getEditBreakoutGroups.length > 0) {
      name = `Group ${(Number(this.getEditBreakoutGroups[this.getEditBreakoutGroups.length - 1].id) + 1).toString()}`;
    }
    this.addGroup({
      breakoutId: this.editBreakout?.id as string,
      name,
      slide: this.editBreakout?.slide as string,
      userIds: [],
    });
  }

  onClose(): void {
    const breakoutInfo = {
      id: this.editBreakout?.id as string,
      name: this.editBreakout?.name !== "" ? this.editBreakout?.name : this.getEditBreakoutOrigin?.name,
      slide: this.editBreakout?.slide,
    };
    if (this.editBreakout?.id && JSON.stringify(breakoutInfo) !== JSON.stringify(this.getEditBreakoutOrigin)) {
      this.updateBreakout(breakoutInfo);
    }
    this.resetEditBreakout();
    this.closeSideView();
  }

  onUseCustomSlide(useCustomSlide: boolean): void {
    this.useCustomSlide = useCustomSlide;
    if (!useCustomSlide) this.updateEditBreakout({ slide: "" });
  }

  onNameChanged(event: string): void {
    this.updateEditBreakout({ name: event });
  }
}
</script>

<style lang="scss" scoped>
.button {
  padding: 12px 11px !important;
}
.auto-create-breakout {
  margin-left: 10px;
  width: 80px;
}
.manual-create-breakout {
  width: 80px;
}
</style>
